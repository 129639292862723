import React, { useEffect, useState } from "react";
import ApiClient, { IUser } from "../../../../services/apiclient";
import AlertMessage from "../../../alert.component";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";
import {validateString} from "../../../../utils/StringValidator"

export default function Informacion({
  nextStep,
  userData,
  setUserData,
  prevStep,
  productAmount,
}) {
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const [userDetails, setUserDetails] = useState<IUser>({
    email: "",
    birthDate: "",
    firstName: "",
    lastFName: "",
    lastMName: "",
    secondName: "",
    userId: "",
    phoneNumber: "",
    sex: "",
    phone: "",
  });

  const validationSchema = Yup.object().shape({
    user: Yup.object({
      curp: Yup.string()
        .trim()
        .required("Requerido")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "Ingrese CURP valido"
        ),
      rfc: Yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
      // .matches(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/, "Ingrese RFC valido"),
      nationality: Yup.string().required("Requerido"),
      countryOfBirth: Yup.string().required("Requerido"),
      stateOfBirth: Yup.string().required("Requerido"),
    }),

    personalData: Yup.object({
      //IDType: Yup.string().required("Requerido"),
      //identificationNumber: Yup.string().required("Requerido").matches(/^[0-9]{13}$/, "Ingresa un numero de identificación valido"),
      //civilStatus: Yup.string().required("Requerido"),
      //educationLevel: Yup.string().required("Requerido"),
      //economicDependents: Yup.number().required("Requerido").min(0)
    }),
  });

  const apiClient = ApiClient.getInstance();

  useEffect(() => {
    apiClient
      .getUserData()
      .then((res) => {
        setUserDetails(res);
      })
      .catch((err) => {});
      apiClient.getUserPreInfo().then((data) => {
        if (!data.error) {
          console.log(data)
          setFieldValue("rfc", data.rfc);
          setFieldValue("stateOfBirth", data.stateOfBirth);
          setFieldValue("curp", data.curp);
          setFieldValue("civilStatus", data.civilStatus);
          if(data.economicDependents !== 0){
            setFieldValue("economicDependents", data.economicDependents);
          }
          setFieldValue("educationLevel", data.educationLevel);
        }
      });
  }, []);

  const onsubmitForm = (values: any, actions: any) => {
    setUserData(values);
    //nextStep();
  };

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      nationality: "MX",
      countryOfBirth: "MÉXICO",
      stateOfBirth: "",
      curp: "",
      rfc: "",
      civilStatus: "",
      educationLevel: "",
      economicDependents: 0,
    },
    validationSchema: Yup.object({
      // user: Yup.object({
      curp: Yup.string()
        .trim()
        .required("Requerido")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "Ingrese CURP valido"
        ),
      rfc: Yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
      // .matches(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/, "Ingrese RFC valido"),
      nationality: Yup.string().required("Requerido"),
      countryOfBirth: Yup.string().required("Requerido"),
      stateOfBirth: Yup.string().required("Requerido"),
      civilStatus: Yup.string().required("Requerido"),
      educationLevel: Yup.string().required("Requerido"),
      economicDependents: Yup.number().required("Requerido").min(0),

      // }),

      //personalData: Yup.object({
      //IDType: Yup.string().required("Requerido"),
      //identificationNumber: Yup.string().required("Requerido").matches(/^[0-9]{13}$/, "Ingresa un numero de identificación valido"),
      //civilStatus: Yup.string().required("Requerido"),
      //educationLevel: Yup.string().required("Requerido"),
      //economicDependents: Yup.number().required("Requerido").min(0)
      // }),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      let user = {
        nationality: values.nationality,
        countryOfBirth: values.countryOfBirth,
        stateOfBirth: values.stateOfBirth,
        curp: values.curp,
        rfc: values.rfc,
      };
      let personal = {
        IDType: "",
        identificationNumber: "",
        civilStatus: values.civilStatus,
        educationLevel: values.educationLevel,
        economicDependents: values.economicDependents,
      };
      userData.user = user;
      userData.personalData = personal;
      setUserData(userData);
      if (localStorage.getItem("preCreditoLocal")) {
        var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
        if (
          credito.idProcessMarketing !== "" &&
          credito.typeLoanMarketing === "Titulo"
        ) {
          var dd = {
            processName: "personal-information-finished",
          };
          updatePreCredito({
            ...preCredito,
            stepMarketing: "personal-information-finished",
          });
          updateMarketing(credito.idProcessMarketing, dd);
        }
      }
      nextStep();
    },
  });

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "personal-information-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "personal-information-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "personal-information-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <AlertMessage open={openMessage} />

      {/* <Formik initialValues={userData} onSubmit={onsubmitForm} validationSchema={validationSchema}>
            {({ handleSubmit, errors, touched, }) => (
              <Form
                onSubmit={(e) => {

                  e.preventDefault();
                  handleSubmit();
                }}
              > */}

      <div className="d-none d-sm-none d-md-block d-flex justify-content-center mt-4 container">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <motion.div
            initial={{ y: "50%", opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="container"
          >
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Nombre *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.firstName}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno *
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="lastFName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.lastFName}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="secondName"
                      placeholder={userDetails.secondName}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno *
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="lastMName"
                      placeholder={userDetails.lastMName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *{" "}
                    </label>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <Stack spacing={3}>
                                          <DatePicker 
                                                                                         
                                          //label="Fecha nacimiento"
                                          openTo="day"
                                          toolbarPlaceholder="dff"
                                          toolbarFormat="//"
                                          value ={userDetails.birthDate.substring(0, 10)}
                                          views={[  'month', 'day', 'year']}
                                          onChange= {(newValue) => {
                                             
                                          }}
                                          disabled
                                          renderInput={
                                               (params) => 
                                                  <TextField 
                                                      {...params}
                                                      size="medium"
                                                      color="primary"
                                                      name="birthDate"
                                                      disabled
                                                      
                                        />}
                                          />
                                      </Stack>
                                      </LocalizationProvider> */}
                    <input
                      type="date"
                      className={"form-control"}
                      id="birthDate"
                      value={userDetails.birthDate.substring(0, 10)}
                      placeholder={userDetails.birthDate.substring(0, 10)}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-2">
                    <label htmlFor="sex" className="form-label sol1-tittle">
                      Genero *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Femenino"
                        disabled
                        checked={userDetails.sex === "femenino" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Masculino"
                        checked={userDetails.sex === "masculino" ? true : false}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Hombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="RFC" className="form-label sol1-tittle">
                      RFC*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 10 o 13 caracteres que conforman tu RFC con el formato correcto con o sin homoclove.<br></br> Ej: VECJ880326 ó VECJ8803264Y1</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.rfc ? " form-control is-invalid" : "form-control"
                      }
                      id="rfc"
                      name="rfc"
                      value={values.rfc}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 13) {
                          setFieldValue(
                            "rfc",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                      aria-invalid={errors.rfc ? "true" : "false"}
                    />
                    {errors.rfc && (
                      <div className="invalid-feedback">
                        El RFC es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="curp" className="form-label sol1-tittle">
                      CURP*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 18 caracteres que conforman tu CURP con el formato correcto.<br></br>Ej: PEGJ850315HJCRRN07<br></br>Puedes consultarlo aqui: <a href="https://www.gob.mx/curp/" target="_blank" rel="noopener noreferrer">https://www.gob.mx/curp/</a></span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.curp
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="curp"
                      name="curp"
                      value={values.curp}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 18) {
                          setFieldValue(
                            "curp",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                      aria-describedby="emailHelp"
                    />
                    {errors.curp && (
                      <div className="invalid-feedback">
                        El CURP es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado de nacimiento*
                    </label>
                    <select
                      className={
                        errors.stateOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="stateOfBirth"
                      name="stateOfBirth"
                      value={values.stateOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Estado
                      </option>
                      <option value="AGUASCALIENTES">AGUASCALIENTES</option>
                      <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
                      <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
                      <option value="CAMPECHE">CAMPECHE</option>
                      <option value="CHIAPAS">CHIAPAS</option>
                      <option value="CHIHUAHUA">CHIHUAHUA</option>
                      <option value="CIUDAD DE MÉXICO">CIUDAD DE MÉXICO</option>
                      <option value="COAHUILA DE ZARAGOZA">COAHUILA DE ZARAGOZA</option>
                      <option value="COLIMA">COLIMA</option>
                      <option value="DISTRITO FEDERAL">DISTRITO FEDERAL</option>
                      <option value="DURANGO">DURANGO</option>
                      <option value="GUANAJUATO">GUANAJUATO</option>
                      <option value="GUERRERO">GUERRERO</option>
                      <option value="HIDALGO">HIDALGO</option>
                      <option value="JALISCO">JALISCO</option>
                      <option value="MÉXICO">MÉXICO</option>
                      <option value="MICHOACÁN DE OCAMPO">MICHOACÁN DE OCAMPO</option>
                      <option value="MORELOS">MORELOS</option>
                      <option value="NAYARIT">NAYARIT</option>
                      <option value="NUEVO LEÓN">NUEVO LEÓN</option>
                      <option value="OAXACA">OAXACA</option>
                      <option value="PUEBLA">PUEBLA</option>
                      <option value="QUERÉTARO">QUERÉTARO</option>
                      <option value="QUINTANA ROO">QUINTANA ROO</option>
                      <option value="SAN LUIS POTOSÍ">SAN LUIS POTOSÍ</option>
                      <option value="SINALOA">SINALOA</option>
                      <option value="SONORA">SONORA</option>
                      <option value="TABASCO">TABASCO</option>
                      <option value="TAMAULIPAS">TAMAULIPAS</option>
                      <option value="TLAXCALA">TLAXCALA</option>
                      <option value="VERACRUZ DE IGNACIO DE LA LLAVE">VERACRUZ DE IGNACIO DE LA LLAVE</option>
                      <option value="YUCATÁN">YUCATÁN</option>
                      <option value="ZACATECAS">ZACATECAS</option>
                    </select>
                    {errors.stateOfBirth && (
                      <div className="invalid-feedback">
                        Elige el estado de nacimiento
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nationality"
                      className="form-label sol1-tittle"
                    >
                      Nacionalidad*
                    </label>
                    <select
                      className={
                        errors.nationality
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      id="nationality"
                      name="nationality"
                      value={values.nationality}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="MX" selected>
                        Mexicana
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label sol1-tittle">
                      País de nacimiento*
                    </label>
                    <select
                      className={
                        errors.countryOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="countryOfBirth"
                      name="countryOfBirth"
                      value={values.countryOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Pais
                      </option>
                      <option value="MÉXICO" selected>
                        MÉXICO
                      </option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="civilStatus"
                      className="form-label sol1-tittle"
                    >
                      Estado civil*
                    </label>
                    <select
                      className={
                        errors.civilStatus
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="civilStatus"
                      name="civilStatus"
                      value={values.civilStatus}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Separado">Separado</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Unión Libre">Unión Libre</option>
                      <option value="Viudo">Viudo</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.civilStatus && (
                      <div className="invalid-feedback">
                        El estado civil es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="economicDependents"
                      className="form-label sol1-tittle"
                    >
                      Dependientes económicos*
                    </label>
                    <input
                      type="number"
                      className={
                        errors.economicDependents
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="economicDependents"
                      name="economicDependents"
                      value={values.economicDependents}
                      onChange={handleChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="civilStatus"
                      className="form-label sol1-tittle"
                    >
                      Nivel de estudios*
                    </label>
                    <select
                      className={
                        errors.educationLevel
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="educationLevel"
                      name="educationLevel"
                      value={values.educationLevel}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Ninguna">Ninguna</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Preparatoria">Preparatoria</option>
                      <option value="Tecnico">Tecnico</option>
                      <option value="Profesional">Profesional</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                      <option value="Otros">Otros</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.educationLevel && (
                      <div className="invalid-feedback">
                        El nivel de estudios es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={"form-control"}
                      id="email"
                      name="userDetails.email"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label sol1-tittle">
                      Número de teléfono*
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="phone"
                      placeholder={userDetails.phone}
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-4">
              <button 
                  type="submit" 
                  className="btn btn-shadow-2"
                  disabled={!isValid}
                  >
                  Continuar
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 pt-3">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <motion.div
            initial={{ y: "50%", opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="container"
          >
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Nombre *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.firstName}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="secondName"
                      placeholder={userDetails.secondName}
                      disabled
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno *
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="lastFName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.lastFName}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno *
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="lastMName"
                      placeholder={userDetails.lastMName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <Stack spacing={3}>
                                          <DatePicker 
                                                                                         
                                          //label="Fecha nacimiento"
                                          openTo="day"
                                          toolbarPlaceholder="dff"
                                          toolbarFormat="//"
                                          value ={userDetails.birthDate.substring(0, 10)}
                                          views={[  'month', 'day', 'year']}
                                          onChange= {(newValue) => {
                                             
                                          }}
                                          disabled
                                          renderInput={
                                               (params) => 
                                                  <TextField 
                                                      {...params}
                                                      size="medium"
                                                      color="primary"
                                                      name="birthDate"
                                                      disabled
                                                      
                                        />}
                                          />
                                      </Stack>
                                      </LocalizationProvider> */}
                    <input
                      type="date"
                      className={"form-control"}
                      id="birthDate"
                      value={userDetails.birthDate.substring(0, 10)}
                      placeholder={userDetails.birthDate.substring(0, 10)}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-2">
                    <label htmlFor="sex" className="form-label sol1-tittle">
                      Genero *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Femenino"
                        disabled
                        checked={userDetails.sex === "femenino" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Masculino"
                        checked={userDetails.sex === "masculino" ? true : false}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Hombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="RFC" className="form-label sol1-tittle">
                      RFC*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 10 o 13 caracteres que conforman tu RFC con el formato correcto con o sin homoclove.<br></br> Ej: VECJ880326 ó VECJ8803264Y1</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.rfc ? " form-control is-invalid" : "form-control"
                      }
                      id="rfc"
                      name="rfc"
                      value={values.rfc}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 13) {
                          setFieldValue(
                            "rfc",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                      aria-invalid={errors.rfc ? "true" : "false"}
                    />
                    {errors.rfc && (
                      <div className="invalid-feedback">
                        El RFC es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="curp" className="form-label sol1-tittle">
                      CURP*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 18 caracteres que conforman tu CURP con el formato correcto.<br></br>Ej: PEGJ850315HJCRRN07<br></br>Puedes consultarlo aqui: <a href="https://www.gob.mx/curp/" target="_blank" rel="noopener noreferrer">https://www.gob.mx/curp/</a></span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.curp
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="curp"
                      name="curp"
                      value={values.curp}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 18) {
                          setFieldValue(
                            "curp",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado de nacimiento*
                    </label>
                    <select
                      className={
                        errors.stateOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="stateOfBirth"
                      name="stateOfBirth"
                      value={values.stateOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Pais
                      </option>
                      <option value="AGUASCALIENTES">AGUASCALIENTES</option>
                      <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
                      <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
                      <option value="CAMPECHE">CAMPECHE</option>
                      <option value="CHIAPAS">CHIAPAS</option>
                      <option value="CHIHUAHUA">CHIHUAHUA</option>
                      <option value="CIUDAD DE MÉXICO">CIUDAD DE MÉXICO</option>
                      <option value="COAHUILA DE ZARAGOZA">COAHUILA DE ZARAGOZA</option>
                      <option value="COLIMA">COLIMA</option>
                      <option value="DISTRITO FEDERAL">DISTRITO FEDERAL</option>
                      <option value="DURANGO">DURANGO</option>
                      <option value="GUANAJUATO">GUANAJUATO</option>
                      <option value="GUERRERO">GUERRERO</option>
                      <option value="HIDALGO">HIDALGO</option>
                      <option value="JALISCO">JALISCO</option>
                      <option value="MÉXICO">MÉXICO</option>
                      <option value="MICHOACÁN DE OCAMPO">MICHOACÁN DE OCAMPO</option>
                      <option value="MORELOS">MORELOS</option>
                      <option value="NAYARIT">NAYARIT</option>
                      <option value="NUEVO LEÓN">NUEVO LEÓN</option>
                      <option value="OAXACA">OAXACA</option>
                      <option value="PUEBLA">PUEBLA</option>
                      <option value="QUERÉTARO">QUERÉTARO</option>
                      <option value="QUINTANA ROO">QUINTANA ROO</option>
                      <option value="SAN LUIS POTOSÍ">SAN LUIS POTOSÍ</option>
                      <option value="SINALOA">SINALOA</option>
                      <option value="SONORA">SONORA</option>
                      <option value="TABASCO">TABASCO</option>
                      <option value="TAMAULIPAS">TAMAULIPAS</option>
                      <option value="TLAXCALA">TLAXCALA</option>
                      <option value="VERACRUZ DE IGNACIO DE LA LLAVE">VERACRUZ DE IGNACIO DE LA LLAVE</option>
                      <option value="YUCATÁN">YUCATÁN</option>
                      <option value="ZACATECAS">ZACATECAS</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="nationality"
                      className="form-label sol1-tittle"
                    >
                      Nacionalidad*
                    </label>
                    <select
                      className={
                        errors.nationality
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      id="nationality"
                      name="nationality"
                      value={values.nationality}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="MX" selected>
                        Mexicana
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label sol1-tittle">
                      País de nacimiento*
                    </label>
                    <select
                      className={
                        errors.countryOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="countryOfBirth"
                      name="countryOfBirth"
                      value={values.countryOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Pais
                      </option>
                      <option value="MÉXICO">MÉXICO</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="civilStatus"
                      className="form-label sol1-tittle"
                    >
                      Estado civil*
                    </label>
                    <select
                      className={
                        errors.civilStatus
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="civilStatus"
                      name="civilStatus"
                      value={values.civilStatus}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Separado">Separado</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Unión Libre">Unión Libre</option>
                      <option value="Viudo">Viudo</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.civilStatus && (
                      <div className="invalid-feedback">
                        El estado civil es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="economicDependents"
                      className="form-label sol1-tittle"
                    >
                      Dependientes económicos*
                    </label>
                    <input
                      type="number"
                      className={
                        errors.economicDependents
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="economicDependents"
                      name="economicDependents"
                      value={values.economicDependents}
                      onChange={handleChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="civilStatus"
                      className="form-label sol1-tittle"
                    >
                      Nivel de estudios*
                    </label>
                    <select
                      className={
                        errors.educationLevel
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="educationLevel"
                      name="educationLevel"
                      value={values.educationLevel}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Ninguna">Ninguna</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Preparatoria">Preparatoria</option>
                      <option value="Tecnico">Tecnico</option>
                      <option value="Profesional">Profesional</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                      <option value="Otros">Otros</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.educationLevel && (
                      <div className="invalid-feedback">
                        El nivel de estudios es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={"form-control"}
                      id="email"
                      name="userDetails.email"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label sol1-tittle">
                      Número de teléfono*
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="phone"
                      placeholder={userDetails.phone}
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <button 
                  type="submit" 
                  className="btn btn-shadow-2"
                  disabled={!isValid}
                  >
                  Continuar
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
      {/* </Form>
            )}
          </Formik> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
    </motion.div>
  );
}
