
import { Field, Formik, Form, useFormik, ErrorMessage, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import * as Yup from "yup";
import  Navbar  from '../../front/NavbarI';
import  Footer  from '../../front/FooterI';
import logo_pabs from '../../../img/logo_pabs.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";
import { UserContext } from "./../../../context/UserProvider";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo_balnco from '../../../img/logo_blanco.png';
import {motion} from "framer-motion";

const requierdMessage = "Requerido";


const validationSchema = Yup.object().shape({
  city: Yup.string().required(requierdMessage),
  contract: Yup.string().required(requierdMessage),
});

export const ValidacionPabs = ({ nextStep, setLoanAmount, setPabsNumber }) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ciudades, setCiudades] = useState([]);
  const [login,setLogin] = React.useState(false);
    const { userMain, updateUser } = React.useContext(UserContext);
  const [phoneStatus, setPhoneStatus] = React.useState(true)
  const [userLoan, setUserLoan] = useState({
    city: "",
    contract: "",
    firstName: "",
    secondName: "",
    lastFName: "",
    lastMName: "",
    dateOfBirth: "",
    state: "",
    gender: "",
    email: "",
    phone: "",
    attached1: false,
    attached2: false,
    attached3: false,
    password: "",
    confirmPassword: "",
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
    userId:localStorage.getItem("prspect"),
  });
  const[amount,setAmount] = useState(0);
    const[isLoading,setIsLoading] = useState(false);
    const[date,setDate] = useState(null);
    const [shown, setShown] = React.useState(false);
    const[phoneChange,setPhoneChange] = React.useState(false);
    const[newPhone,setNewPhone] = React.useState("");
    const[sendCode,setSendCode] = React.useState({
        phone:"",
        send:false
    });
  
  const [loading, setLoading] = useState(false);

  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  useEffect(() => {
    apiClient
      .getCity()
      .then((ciudades) => {
        setCiudades(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
      
  }, []);



  const createPABS = (values: any, actions: any) => {
    setLoading(true);
     setPabsNumber(values.contract);

     apiClient.userContract(values.contract, values.city).then(value => {
      setLoading(false);
      setLoanAmount(function (prevData: any) {
        var oldObj = { ...prevData };
        oldObj.upper = value.upper;
        oldObj.lower = value.lower;
        return { ...oldObj };
      });
      localStorage.setItem("upper",value.upper);
      localStorage.setItem("lower",value.lower);
      nextStep();
    }).catch(err => {
      if(err.message == 'Contract number was not found.'){
        history.push('/RECHAZADO/pabs/contract-not-found');
      }
      else if(err.message == 'La ciudad elegida no existe.'){
        history.push('/RECHAZADO/pabs/city-not-found');
      }
      else if(err.message == 'El título registrado ya fue utilizado. Cuenta con otro titulo vigente?'){
        history.push('/RECHAZADO/pabs/validated-user');
      }
      else if(err.message == 'El nombre regisrado no coincide con el número de contrato. Favor de revisar los datos'){
        history.push('/RECHAZADO/pabs/user-does-not-match');
      }
      else if(err.message == 'El título registrado está en Suspensión. Cuenta con otro titulo vigente?'){
        history.push('/RECHAZADO/pabs/suspended-account');
      }
      else if(err.message == 'El título registrado cuenta con un saldo mayor a $2,000.00. Cuenta con otro titulo pagado o saldo menor a $2,000.00?'){
        history.push('/RECHAZADO/pabs/major-balance');
      }
      else if(err.message == 'Prestamo denegado.'){
        history.push('/RECHAZADO/pabs/loan-denied');
      }
      else {
        history.push('/RECHAZADO/pabs/not-found');
      }
    }); 
 
    


  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function handleChangeDate(event: any) {
    console.log(event.target.value);
    let date = event.target.value.split('/');
    setFieldValue('dateOfBirth',date[2]+'-'+date[0]+'-'+date[1]);
}
function handleChangePhone(event: any) {
    setFieldValue('phone',event.target.value.toString());
}
function handleChangePhoneBlur(event: any) {
    // if(sendCode.phone !== ""){ // se envío sms
    //     //sendSms
    //     if(sendCode.phone !== values.phone){
    //         setPhoneChange(true)   
    //     }
    // } 
    if(values.phone.length > 0){
        apiClient.validatePhone(values.phone)
    .then((data) => {
            if(data.error){
                setOpenMessage(true);
                setMessage("Lo sentimos, el telefono ingresado, ya se encuentra registrado, si ya tienes una cuenta, inicia sesión");
                setSeverity('error');
                return;
            }
            setPhoneStatus(false)
        })
    }
    


}

 const handleBlurDate = (e) => console.log(e.target.value);
  function handleBlurPassword(event:any){
    if(!errors.confirmPassword){
        apiClient.signup(values.phone, values.password, "titulo")
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                    localStorage.setItem('cxdc',values.phone)
                    localStorage.setItem('fwcd',values.password)
                    if (data.error) {
                    setIsLoading(false);
                    localStorage.clear();
                    setOpenMessage(true);
                    setMessage(data.status >= 500 ? 'Error del servidor' : data.error.message);
                    setSeverity('error');
                    setLogin(false);
                    return;
                    }
                    setLogin(true)
                    localStorage.setItem('userId',data.user)
                }).catch(err => {
                    setIsLoading(false);
                    setOpenMessage(true);
                    setMessage('Error en el servidor');
                    setSeverity('error');
                });       
        }
  }

const { 
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue

    }= useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      phone: "",
      attached1: false,
      attached2: false,
      attached3: false,
      password: "",
      confirmPassword: "",
      val1: "",
      val2: "",
      val3: "",
      val4: "",
      val5: "",
      val6: "",
      userId:localStorage.getItem("prspect"),
      contract:"",
      city:"",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Tienes que ingresar la calle."),
      lastFName: Yup.string().required("Tienes que ingresar la calle."),
      lastMName: Yup.string().required("Tienes que ingresar la calle."),
      dateOfBirth: Yup.string().required("Tienes que ingresar la calle."),
      //state: Yup.string().required("Tienes que ingresar la calle."),
      email: Yup.string().required("Tienes que ingresar la calle."),
      phone: Yup.string().required("Tienes que ingresar la calle.").matches(/^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/),
      attached1: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked'),
      attached2: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked'),
      password: Yup.string().required("Tienes que ingresar la calle."),
      confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref('password'), null], "Passwords don't match."),
      contract: Yup.string().max(9).required("Ingresa el titulo"),
      city: Yup.string().required("Tienes que ingresar la calle."),
    }),
    onSubmit: values => {

        setIsLoading(true)
        let code = values.val1 + values.val2 + values.val3 + values.val4 + values.val5 + values.val6;
        apiClient.activateAccountSMS(code).then(data => {
            setOpenMessage(true);
            setMessage('Tu cuenta ha sido activada');
            console.log(localStorage.getItem('cxdc') , localStorage.getItem('fwcd'))
            if(localStorage.getItem('cxdc') && localStorage.getItem('fwcd')){
              console.log(data)
            apiClient
            .login(localStorage.getItem('cxdc'),localStorage.getItem('fwcd'))
            .then((res) => res.json())
            .then(async (a) => {
              localStorage.removeItem('cxdc')
              localStorage.removeItem('fwcd')
              if (a.error) {
                localStorage.clear();
                setOpenMessage(true);
                const statusCode = a.error?.status || a.error.statusCode;
                setMessage(statusCode === 401 ? 'Telefono o contraseña invalidos' : a.error.message);
                setSeverity("error");
      
                // if(statusCode === 403)
                //   return history.push("/step2");
                //   else return history.push("/login");
              }
      
              localStorage.setItem("TOKEN", a.token);
              localStorage.setItem("USER_ID", a.userId);
      
              apiClient.token = a.token;
              apiClient.userId = a.userId;
              if (a.fullName) {
                updateUser({
                  ...userMain,
                  nombre: a.fullName || a.fullName,
                  telefono: a.phone,
                });
              } else {
                updateUser({
                  ...userMain,
                  telefono: a.phone,
                });
              }
                try {
                    await apiClient.termsConditions({ publicidad: values.attached3 });
            
                } catch (error) {
                    setIsLoading(false);
                    setOpenMessage(true);
                    setMessage("Ha ocurrido un error al guardar los datos.");
                    setSeverity("error");
                    return;
                }
                    const data = {
                        firstName: values.firstName,
                        secondName: values.secondName,
                        lastFName: values.lastFName,
                        lastMName: values.lastMName,
                        birthDate: values.dateOfBirth,
                        email: values.email,
                        sex: values.gender
                    }
                    apiClient
                    .createUserDetails(data)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.error) {
                        setOpenMessage(true);
                        setMessage(data.error.message);
                        setSeverity("error");
                        }
                        const fullName = `${values.firstName} ${values.secondName} ${values.lastFName} ${values.lastMName}`;
                        updateUser({
                        ...userMain,
                        nombre: fullName,
                        });
                        return history.push("/solicitud-datos3");
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        setOpenMessage(true);
                        setMessage("Favor de verificar que la informacion sea correcta.");
                        setSeverity("error");
                    });

            })
            }
            //return history.push("/login");
          }).catch(reason => {
            setOpenMessage(true);
            setMessage(reason.message);
            setSeverity('error');
          });
    }
  });
  function handleChangeCode(e) {
    console.log(e.target)
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      } else if(parseInt(fieldIndex, 10) === 6){
        handleSubmit();
      }
    }
      setFieldValue([`val${fieldIndex}`].toString(), value)
  }
  return (
    <motion.div
    initial={{  opacity:0 }}
      animate={{opacity:1 }}
      exit={{opacity:0}}
      transition={{ ease: "easeInOut", duration: 0.6 }}
       className="grey-background">
            <br />
            <AlertMessage
              open={openMessage}
              message={message}
              handleClose={handleClose}
              severity={severity}
            />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-1">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-1">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-1">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <img src={logo_pabs} alt="PRADO'S" className="img-fluid mb-4" width="70" />
                        <h3 className="sol1-tittle">Bienvenido a tu solicitud <br />
                            de préstamo con Título PABS</h3>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {/* STEPS */}
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step">
                        <h5 className="numbre-step-blue">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-2 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <p className="txt-blue-strong">Rellena los campos vacíos con los datos de tu domicilio</p>
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="city" className="form-label sol1-tittle">Ciudad donde solicitaste tu titulo*</label>
                                        <select 
                                            className={ errors.city && touched.city  ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                            aria-label="Default select example"
                                            id="city"
                                            name="city"
                                            onChange={handleChange}
                                            value={values.city}
                                            onBlur={handleBlur}
                                        >
                                            <option selected>Ciudad</option>
                                            {ciudades.map((ciudad) => (
                                                <option key={ciudad.id} value={ciudad.name}>
                                                {ciudad.nameDescription}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.city && touched.city &&(
                                                <div className="invalid-feedback">
                                                Elige una ciudad
                                            </div>
                                            )}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label sol1-tittle">Nombre (s)*</label>
                                        <input 
                                            type="input" 
                                            className={ errors.firstName && touched.firstName ? "form-control  is-invalid" : "form-control"}
                                            id="firstName" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.firstName}
                                            onBlur={handleBlur}
                                             />
                                        {errors.firstName && touched.firstName &&(
                                                <div className="invalid-feedback">
                                                Elige una ciudad
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="contract" className="form-label sol1-tittle">Número de contrato PABS*</label>
                                        <input 
                                            type="input" 
                                            className={errors.contract && touched.contract ? " form-control is-invalid" : "form-control"} 
                                            id="contract" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.contract}
                                            onBlur={handleBlur}
                                            maxLength={9}
                                             />
                                        {errors.contract && touched.contract &&(
                                                <div className="invalid-feedback">
                                                Debes ingresar un contrato
                                            </div>
                                            )}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="secondName" className="form-label sol1-tittle">Segundo nombre</label>
                                        <input 
                                            type="input" 
                                            className={errors.secondName && touched.secondName ? "form-control  is-invalid" : "form-control"} 
                                            id="secondName" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.secondName}
                                            onBlur={handleBlur}
                                             />
                                             {errors.secondName && touched.secondName &&(
                                                <div className="invalid-feedback">
                                                Ingresa tu segundo nombre
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="lastFName" className="form-label sol1-tittle">Apellido paterno*</label>
                                        <input 
                                            type="input" 
                                            className={errors.lastFName && touched.lastFName ? "form-control  is-invalid" : "form-control"} 
                                            id="lastFName" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.lastFName}
                                            onBlur={handleBlur}
                                             />
                                             {errors.lastFName && touched.lastFName &&(
                                                <div className="invalid-feedback">
                                                Ingresa tu apellido paterno
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="lastMName" className="form-label sol1-tittle">Apellido materno*</label>
                                        <input 
                                            type="input" 
                                            className={errors.lastMName && touched.lastMName ? "form-control  is-invalid" : "form-control"}  
                                            id="lastMName" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.lastMName}
                                            onBlur={handleBlur}
                                             />
                                             {errors.lastMName && touched.lastMName &&(
                                                <div className="invalid-feedback">
                                                Ingresa tu apellido materno
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-1">
                                        <label htmlFor="birthDate" className="form-label sol1-tittle">Fecha de nacimiento *</label>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={values.dateOfBirth}
                                                            onBlur={handleBlurDate}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider>
                                            {errors.dateOfBirth &&(
                                                <div className="invalid-feedback">
                                                Ingresa tu apellido materno
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="gender" className="form-label sol1-tittle">Género *</label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                        <input 
                                            className={errors.gender && touched.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="femenino"
                                                checked={values.gender === "femenino" }
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                />
                                                
                                            <label className="form-check-label" htmlFor="inlineRadio1">Mujer</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                        <input 
                                                className={errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="masculino"
                                                checked={values.gender === "masculino" }
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                />
                                                
                                            <label className="form-check-label" htmlFor="inlineRadio2">Hombre</label>
                                        </div>
                                        {errors.gender && touched.gender &&(
                                                <div className="invalid-feedback">
                                                Elige un género
                                            </div>
                                            )}
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="row"> 
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label sol1-tittle">Correo electrónico*</label>
                                        <input 
                                            type="email" 
                                            className={errors.email && touched.email ? " form-control  is-invalid" : "form-control"}
                                            id="email" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={handleBlur}
                                             />
                                             {errors.email && touched.email &&(
                                                <div className="invalid-feedback">
                                                Ingresa tu correo electrónico
                                            </div>
                                            )}
                                    </div>
                                </div>
                                    
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Número de celular *</label>
                                        <input 
                                            type="number" 
                                            className={errors.phone && touched.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={ handleChangePhone}
                                            value={values.phone}
                                            onBlur={handleChangePhoneBlur}
                                            />
                                            {errors.phone && touched.phone &&(
                                                <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                            )}
                                            
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={errors.password && touched.password ? "form-control  is-invalid" : "form-control"}
                                            id="password" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.password}
                                            onBlur={handleBlur}
                                            autoComplete="nope"
                                            disabled={phoneStatus}
                                            />
                                            {errors.password && touched.password &&(
                                                <div className="invalid-feedback">
                                                Debes ingresar una contraseña
                                            </div>
                                            )}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                            id="confirmPassword" 
                                            aria-describedby="emailHelp"
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            onBlur={handleBlurPassword}
                                            disabled={phoneStatus}
                                            />
                                            <p>{phoneStatus }</p>
                                            {errors.confirmPassword && values.confirmPassword.length   >=  values.password.length  && (values.password.length > 0) && (
                                                <div className="invalid-feedback">
                                                Las contraseñas no coinciden
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                    <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input 
                                                className={errors.attached1 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                //value="" 
                                                id="attached1" 
                                                //checked={values.attached1}
                                                onChange={handleChange}
                                                disabled={errors.password || errors.confirmPassword ? true : false}
                                                onBlur={handleBlur}
                                                />
                                            <label className="form-check-label text-check" htmlFor="attached1">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                            {errors.attached1 && !errors.confirmPassword &&(
                                                <div className="invalid-feedback">
                                                Debes aceptar el aviso de privacidad
                                            </div>
                                            )}
                                            
                                        </div>
                                        <div className="form-check">
                                            <input 
                                                 className={errors.attached2 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                //value="" 
                                                id="attached2"
                                                //checked={values.attached2}
                                                onChange={handleChange}
                                                disabled={errors.password || errors.confirmPassword ? true : false}
                                                onBlur={handleBlur}
                                                 />

                                            <label className="form-check-label text-check" htmlFor="attached2">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                            {errors.attached2  && !errors.confirmPassword && (
                                                <div className="invalid-feedback">
                                                Debes aceptar el contrato de licencia
                                            </div>
                                            )}
                                        </div>
                                        <div className="form-check">
                                            <input 
                                                className="form-check-input checkbox-terms" 
                                                type="checkbox" 
                                                value="" 
                                                id="attached3"
                                                checked={values.attached3}
                                                onChange={handleChange}
                                                disabled={errors.password || errors.confirmPassword ? true : false}
                                                onBlur={handleBlur}
                                                 />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Confirma tu contraseña</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Numero de celular *</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                        <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-5 mb-4">
                                        <a href='/pabs/3' className="btn btn-shadow">
                                            Enviar código
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="text-center mt-1">
                                <p>Por favor, ingresa el código que te enviamos a tu celular:</p>
                                <p className="sol1-tittle">Código</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="p-1">
                                    <input 
                                        type="string" 
                                        className="form-control input-number " 
                                        id="val-1" 
                                        aria-describedby="emailHelp"
                                        name="val-1"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val1} 
                                        disabled={Object.keys(errors).length > 0  ? true : false}/>
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-2" 
                                        aria-describedby="emailHelp"
                                        name="val-2"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val2}
                                        disabled={Object.keys(errors).length > 0  ? true : false}/>
                                        
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-3" 
                                        aria-describedby="emailHelp"
                                        name="val-3"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val3}
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-4" 
                                        aria-describedby="emailHelp"
                                        name="val-4"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val4}
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-5" 
                                        aria-describedby="emailHelp"
                                        name="val-5"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val5}
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-6" 
                                        aria-describedby="emailHelp"
                                        name="val-6"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(errors).length > 0 ? '' : values.val6}
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                        
                                         />
                                         
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.appBar + 1 }}
                open={isLoading}                          
            >               
            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-3 img-client" width="300" />
            <CircularProgress color="primary" size={80} />                
            </Backdrop>
            <Footer />
        </motion.div>
  );
};
