import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import Backdrop from "@mui/material/Backdrop";
import ApiClient, { IUser } from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import Footer from "../../front/FooterI";
import logo_balnco from "../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation, useHistory } from "react-router-dom";
import AlertAccount from "./AlertAccount";
import AlertMessage from "../../alert.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import {validateString} from "../../../utils/StringValidator"
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const schema = yup
  .object()
  .shape({
    CURP: yup.string()
        .trim()
        .required("Requerido")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "Ingrese CURP valido"
        ),
    RFC: yup.string()
      .required("Requerido")
      .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
    educationLevel: yup.string().required("Selecciona un nivel de estudios"),
    civilStatus: yup.string().required("Elige un estado civil"),
    nationality: yup.string().required("Elige tu nacionalidad"),
    countryOfBirth: yup.string().required("Elige tu lugar de nacimiento"),
    stateOfBirth: yup.string().required("Elige tu estado de nacimiento"),
  })
  .required();
const PersonalForm = ({ nextStep }) => {
  const apiClient = ApiClient.getInstance();
  const history = useHistory();
  const [date, setDate] = useState("");
  const [paises, setPaises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [genero, setGenero] = useState("");
  const [preInfo, setPreInfo] = useState(null);
  const [amount, setAmount] = useState(0);
  const query = useQuery();
  const [showAlert, setShowAlert] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const [userDetails, setUserDetails] = useState<IUser>({
    email: "",
    birthDate: "",
    firstName: "",
    lastFName: "",
    lastMName: "",
    secondName: "",
    userId: "",
    phoneNumber: "",
    sex: "",
    phone: "",
  });

  useEffect(() => {
    apiClient
      .getUserData()
      .then((res) => {
        setUserDetails(res);
      })
      .catch((err) => {});
      apiClient.getUserPreInfo().then((data) => {
        if (!data.error) {
          console.log(data)
          setFieldValue("rfc", data.rfc);
          setFieldValue("stateOfBirth", data.stateOfBirth);
          setFieldValue("curp", data.curp);
          setFieldValue("civilStatus", data.civilStatus);
          //if(data.economicDependents !== 0){
          //  setFieldValue("economicDependents", data.economicDependents);
          //}
          setFieldValue("educationLevel", data.educationLevel);
        }
      });
  }, []);

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      nationality: "MX",
      countryOfBirth: "MÉXICO",
      stateOfBirth: "",
      curp: "",
      rfc: "",
      civilStatus: "",
      educationLevel: "",
      economicDependents: 0,
      identificationNumber: ""
    },
    validationSchema: yup.object({
      curp: yup.string()
        .trim()
        .required("Requerido")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "Ingrese CURP valido"
        ),
      rfc: yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
      educationLevel: yup.string().required("Selecciona un nivel de estudios"),
      civilStatus: yup.string().required("Elige un estado civil"),
      nationality: yup.string().required("Elige tu nacionalidad"),
      countryOfBirth: yup.string().required("Elige tu lugar de nacimiento"),
      stateOfBirth: yup.string().required("Elige tu estado de nacimiento"),
    }),
    onSubmit: (values) => {

      const data = values;

      const valuesBody = {
        curp: data?.curp,
        rfc: data?.rfc,
        nationality: data?.nationality,
        educationLevel: data?.educationLevel,
        countryOfBirth: data?.countryOfBirth,
        stateOfBirth: data?.stateOfBirth,
        economicDependents: 0,
        civilStatus: data?.civilStatus,
        identificationNumber: data?.identificationNumber,
        userCambaceoId : ''
      };
      console.log(valuesBody);
  
      setIsLoading(true);
      apiClient
        .personalFormData(valuesBody)
        .then((data) => {
          nextStep("ADDRESS");
          console.log(data);
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "personal-form-finish",
                personalLoanId: data.id,
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "personal-form-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          if(e.message === 'CREDITO_ACTIVO_CON_SALDO'){
            return history.push("/RECHAZADO/personal/CREDITO_ACTIVO_CON_SALDO")
          }
          if(e.message === 'MAX_DUE_DATE'){
            return history.push("/RECHAZADO/personal/MAX_DUE_DATE")
          }
          if(e.message === 'INTERNAL_BLACKLIST'){
            return history.push("/RECHAZADO/personal/INTERNAL_BLACKLIST")
          }
          
          
          setOpenMessage(true);
          setMessage(e.message);
          setSeverity("error");
        });
    }
  });

  /*
  const {
    register,
    handleSubmitOLD,
    reset,
    formState: { errorsOLD },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitOLD = (data) => {
    const values = {
      curp: data?.CURP,
      rfc: data?.RFC,
      nationality: data?.nationality,
      educationLevel: data?.educationLevel,
      countryOfBirth: data?.country,
      stateOfBirth: data?.state,
      economicDependents: 0,
      civilStatus: data?.civilStatus,
      identificationNumber: data?.identificationNumber,
      userCambaceoId : ''
    };
    console.log(values);

    setIsLoading(true);
    apiClient
      .personalFormData(values)
      .then((data) => {
        nextStep("ADDRESS");
        console.log(data);
        if (localStorage.getItem("preCreditoLocal")) {
          var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
          if (
            credito.idProcessMarketing !== "" &&
            credito.typeLoanMarketing === "Personal"
          ) {
            var dd = {
              processName: "personal-form-finish",
              personalLoanId: data.id,
            };
            updatePreCredito({
              ...preCredito,
              stepMarketing: "personal-form-finish",
            });
            updateMarketing(credito.idProcessMarketing, dd);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        if(e.message === 'CREDITO_ACTIVO_CON_SALDO'){
          return history.push("/RECHAZADO/personal/CREDITO_ACTIVO_CON_SALDO")
        }
        if(e.message === 'MAX_DUE_DATE'){
          return history.push("/RECHAZADO/personal/MAX_DUE_DATE")
        }
        if(e.message === 'INTERNAL_BLACKLIST'){
          return history.push("/RECHAZADO/personal/INTERNAL_BLACKLIST")
        }
        
        
        setOpenMessage(true);
        setMessage(e.message);
        setSeverity("error");
      });
  };*/
  const onSubmit2 = (data) => {
    const values = {
      curp: data?.CURP,
      rfc: data?.RFC,
      nationality: data?.nationality,
      educationLevel: data?.studiesLevel,
      countryOfBirth: data?.country,
      stateOfBirth: data?.state,
      economicDependents: 0,
      identificationNumber: data?.identificationNumber,
    };
    console.log(values);

    setIsLoading(true);
    apiClient
      .personalFormData(values)
      .then((data) => {
        if (data.error) {
          alert(1);
        }
        nextStep("ADDRESS");
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setOpenMessage(true);
        setMessage(e.message);
        setSeverity("error");
      });
  };

  /*useEffect(() => {
    apiClient.getCountries().then((data) => setPaises(data.countries));
    apiClient
      .getUserData()
      .then((data) => {
        setGenero(data.sex);
        setDate(data.birthDate.substr(0, 10));
        //     console.log(data)
        //     var d = new Date(data.birthDate),
        //     month = "" + (d.getMonth() + 1),
        //     day = "" + (d.getDate() + 1),
        //     year = d.getFullYear();

        //   if (month.length < 2) month = "0" + month;
        //   if (day.length < 2) day = "0" + day;

        //   var formatoFecha: string = [ month, day, year].join("/");
        const user = data;
        //      user.birthDate = formatoFecha
        //     setDate(formatoFecha)
        reset(user);
      })
      .catch((e) => console.error(e));

    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
  }, []);*/
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    const checkFromRoute = async () => {
      const from = query.get("from");
      if (from === "init") {
        setShowAlert(true);
        history.push("/prestamo-personal");
      }
    };
    checkFromRoute();
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "personal-form-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "personal-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "personal-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  const handleClose = () => {
    setOpenMessage(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.8 }}
      className="grey-background"
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <AlertAccount open={showAlert} />
      <br />
      <br />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Por favor, rellena los siguientes campos con tu información</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className=" d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Nombre *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.firstName}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="lastFName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.lastFName}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="secondName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.secondName}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="lastMName"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.lastMName}
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    <input
                      type="date"
                      className={"form-control"}
                      id="birthDate"
                      value={userDetails.birthDate.substring(0, 10)}
                      placeholder={userDetails.birthDate.substring(0, 10)}
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-2">
                    <label htmlFor="sex" className="form-label sol1-tittle">
                      Genero *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Femenino"
                        disabled
                        checked={userDetails.sex === "femenino" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={"form-check-input"}
                        type="radio"
                        value="Masculino"
                        checked={userDetails.sex === "masculino" ? true : false}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Hombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="rfc" className="form-label sol1-tittle">
                      RFC*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 10 o 13 caracteres que conforman tu RFC con el formato correcto con o sin homoclove.<br></br> Ej: VECJ880326 ó VECJ8803264Y1</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.rfc ? " form-control is-invalid" : "form-control"
                      }
                      id="rfc"
                      name="rfc"
                      value={values.rfc}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 13) {
                          setFieldValue(
                            "rfc",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                      aria-invalid={errors.rfc ? "true" : "false"}
                    />
                    {errors.rfc && (
                      <div className="invalid-feedback">
                        El RFC es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="curp" className="form-label sol1-tittle">
                      CURP*
                      <span style={{ fontSize: "12px"}} className="text-danger"><br></br>Anota los 18 caracteres que conforman tu CURP con el formato correcto.<br></br>Ej: PEGJ850315HJCRRN07<br></br>Puedes consultarlo aqui: <a href="https://www.gob.mx/curp/" target="_blank" rel="noopener noreferrer">https://www.gob.mx/curp/</a></span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.curp
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="CURP"
                      name="CURP"
                      value={values.curp}
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 18) {
                          setFieldValue(
                            "curp",
                            validateString(value.toUpperCase().trim())
                          );
                        }
                      }}
                    />
                    {errors.curp && (
                      <div className="invalid-feedback">
                        El CURP es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="educationLevel"
                      className="form-label sol1-tittle"
                    >
                      Nivel de estudios*
                    </label>
                    <select
                      className={
                        errors.educationLevel
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="educationLevel"
                      name="educationLevel"
                      value={values.educationLevel}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Ninguna">Ninguna</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Preparatoria">Preparatoria</option>
                      <option value="Tecnico">Tecnico</option>
                      <option value="Profesional">Profesional</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                      <option value="Otros">Otros</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.educationLevel && (
                      <div className="invalid-feedback">
                        El nivel de estudios es requerido
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="civilStatus"
                      className="form-label sol1-tittle"
                    >
                      Estado civil*
                    </label>
                    <select
                      className={
                        errors.civilStatus
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="civilStatus"
                      name="civilStatus"
                      value={values.civilStatus}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Separado">Separado</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Unión Libre">Unión Libre</option>
                      <option value="Viudo">Viudo</option>
                      <option value="Desconocido">Desconocido</option>
                    </select>
                    {errors.civilStatus && (
                      <div className="invalid-feedback">
                        El estado civil es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nationality"
                      className="form-label sol1-tittle"
                    >
                      Nacionalidad*
                    </label>
                    <select
                      className={
                        errors.nationality
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      id="nationality"
                      name="nationality"
                      value={values.nationality}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Seleccione una opción</option>
                      <option value="MX" selected>
                        Mexicana
                      </option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado de nacimiento*
                    </label>
                    <select
                      className={
                        errors.stateOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="stateOfBirth"
                      name="stateOfBirth"
                      value={values.stateOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Estado
                      </option>
                      <option value="Aguascalientes">Aguascalientes</option>
                      <option value="Baja California">Baja California</option>
                      <option value="Baja California Sur">Baja California Sur</option>
                      <option value="Campeche">Campeche</option>
                      <option value="Chiapas">Chiapas</option>
                      <option value="Chihuahua">Chihuahua</option>
                      <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                      <option value="Colima">Colima</option>
                      <option value="Colima">Distrito Federal</option>
                      <option value="Durango">Durango</option>
                      <option value="Guanajuato">Guanajuato</option>
                      <option value="Guerrero">Guerrero</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="México">México</option>
                      <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                      <option value="Morelos">Morelos</option>
                      <option value="Nayarit">Nayarit</option>
                      <option value="Nuevo León">Nuevo León</option>
                      <option value="Oaxaca">Oaxaca</option>
                      <option value="Puebla">Puebla</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Quintana Roo">Quintana Roo</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Sinaloa">Sinaloa</option>
                      <option value="Sonora">Sonora</option>
                      <option value="Tabasco">Tabasco</option>
                      <option value="Tamaulipas">Tamaulipas</option>
                      <option value="Tlaxcala">Tlaxcala</option>
                      <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                      <option value="Yucatán">Yucatán</option>
                      <option value="Zacatecas">Zacatecas</option>
                    </select>
                    {errors.stateOfBirth && (
                      <div className="invalid-feedback">
                        Elige el estado de nacimiento
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label sol1-tittle">
                      País de nacimiento*
                    </label>
                    <select
                      className={
                        errors.countryOfBirth
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="countryOfBirth"
                      name="countryOfBirth"
                      value={values.countryOfBirth}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Seleccione un Pais
                      </option>
                      <option value="MÉXICO">MÉXICO</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={"form-control"}
                      id="email"
                      name="userDetails.email"
                      aria-describedby="emailHelp"
                      disabled
                      placeholder={userDetails.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label sol1-tittle">
                      Número de teléfono*
                    </label>
                    <input
                      type="input"
                      className={"form-control"}
                      id="phone"
                      placeholder={userDetails.phone}
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <button type="submit" className="btn btn-shadow-2">
                  Continuar
                </button>
              </div>
            </form>
          </div>
        </div>
      </motion.div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
