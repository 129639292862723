import React,{useState, useEffect} from 'react'
import logo_balnco from '../../../img/logo_blanco.png';
import fondo_1 from '../../../img/fondo_1.png';
import fondo_2 from '../../../img/fondo_2.png';
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import {  useHistory } from "react-router-dom";
import { UserContext } from "./../../../context/UserProvider";
import * as Yup from "yup";
import { useFormik} from "formik";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import {motion} from "framer-motion";
export default function ResetPassword() {
    const history = useHistory();
    const apiClient = ApiClient.getInstance();
    const[isLoading,setIsLoading] = useState(false);
    const[date,setDate] = useState(null);
    const [shown, setShown] = React.useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [ciudades, setCiudades] = useState([]);
    const [login,setLogin] = React.useState(false);
    const { userMain, updateUser } = React.useContext(UserContext);
    const [phoneStatus, setPhoneStatus] = React.useState(true)
    const [sendCode,setSendCode] = React.useState(false);
    const [codeValidated, setCodeValidated] = React.useState(false)
    function redirect() {
        history.push("/prestamo-personal")
    }
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenMessage(false);
      };
    function handleChangeCode(e) {
        console.log(e.target)
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
    
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
            ) as HTMLElement | null;
    
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          } else if(parseInt(fieldIndex, 10) === 6){
            handleSubmit();
          }
        }
          setFieldValue([`val${fieldIndex}`].toString(), value)
      }
      const { 
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    
        }= useFormik({
        initialValues: {
          phone: "",
        },
        validationSchema: Yup.object({
          phone: Yup.string().required("Ingresa un telefono valido"),
        }),
        onSubmit: values => {
            setIsLoading(true)
            const data = {
                phone: values.phone
            }
            localStorage.setItem("phone", values.phone);
            apiClient.resetPassword(data).then(res => {
              
                setOpenMessage(true);
                setMessage('Se ha enviado un codigo de verificación a su telefono');
                history.push('/reset-password-code')
        
            }).catch(error => {
              setOpenMessage(true);
              setMessage('Ocurrio un error al enviar la petición, verifique su numero de telefono');
              setSeverity('error');
            });;
            
        }
      });
   
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}
           className="blue-background-mess">
            <Navbar />
            <AlertMessage
              open={openMessage}
              message={message}
              handleClose={handleClose}
              severity={severity}
            />
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="row">
                    <div className="col-3">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top" width="250" />
                    </div>
                    <div className="col-6">
                    <form className="col-12" onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                        <h4 className="mt-2 mb-3">Recupera tu contraseña</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <input 
                                        className="form-control" 
                                        name="phone" 
                                        id="phone"
                                        value={values.phone}
                                        placeholder="Ingresa tu teléfono" 
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                         />
                                         {errors.phone && (
                                            <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                        )}  
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-5">
                            <button className="btn btn-continue-large mt-3" type="submit" >
                                        Validar
                                    </button>
                        </div>
                        
                    </form>
                    </div>
                    <div className="col-3">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2" width="260" />
                    </div>
                
                </div>
            </div>


            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mb-3">
                    <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                </div>
                <div className="text-white text-center">
                <div className="row">
                    <div className="col-12">
                    <form className="col-12" onSubmit={handleSubmit}>
                        <div className="text-white text-center">
                        <h4 className="mt-2 mb-3">Recupera tu contraseña</h4>
                        </div>
                        <div className="row">
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <input 
                                        className="form-control" 
                                        name="phone" 
                                        id="phone"
                                        value={values.phone}
                                        placeholder="Ingresa tu teléfono" 
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                         />
                                         {errors.phone && (
                                            <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                        )}  
                            </div>
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-5">
                            <button className="btn btn-continue-large mt-3" type="submit" >
                                        Validar
                                    </button>
                        </div>
                        
                    </form>
                    </div>
                
                </div>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
