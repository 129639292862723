import GeolocationService from "./geolocate";
import {API_URL} from '../utils/config'

export interface cdcInfo {
  rfc:string;
  address:{
    street: string;
  exteriorNumber: string;
  suburb: string;
  delegation: string;
  city: string;
  state: string;
  postalCode: string;
  };
  userDetails: {
    lastFName: string;
  lastMName:string;
  firstName:string;
  secondName:string;
  birthDate: string;
  };
}

interface UserDetails {
  lastFName: string;
  lastMName:string;
  firstName:string;
  secondName:string;
  birthDate: string;
}

interface Address {
  street: string;
  exteriorNumber: string;
  suburb: string;
  delegation: string;
  city: string;
  state: string;
  postalCode: string;
}

interface ICity {
  id: string;
  name: string;
  nameDescription: string;
}

interface userDetails {
  firstName: string;
  lastFName: string;
  lastMName: string;
  birthDate: string;
  email: string;
  sex: string;
}

interface userDetails_v2 {
  userDetails: userDetails
  knowAboutPercapita: string;
}

export interface loanAmount {
  upper: number;
  lower: number;
}

export interface IUser {
  userId: string;
  email: string;
  firstName: string;
  lastFName: string;
  lastMName: string;
  secondName?: string;
  birthDate: string;
  phoneNumber: string;
  sex: string;
}

export interface IUser {
  userId: string;
  email: string;
  firstName: string;
  lastFName: string;
  lastMName: string;
  secondName?: string;
  birthDate: string;
  phoneNumber: string;
  employeeNumber?: string;
  phone?: string;
  knowAboutPercapita?: string;
}

export interface IAddress {
  estado: string;
  municipio: string;
  ciudad: string;
  colonias: IColonia[];
}

export interface IAddressPersonal {
  estado: string;
  municipio: string;
  ciudad: string;
  delegation: string;
  colonias: IColonia[];
}

export interface ISmartphone {
  id: string;
  option: IOption[];
  promedio: string;
  type: string;
  recurrencyPayment: string;
  employeeCode: string;
}

interface IOption {
  name: string;
  category: string;
  cost: number;
}

interface IColonia {
  colonia: string;
  tipo_asentamiento: string;
}

export interface CreditConfig {
  amount: number;
  numberPayments: number;
  frequencyPayments: string;
}

export interface Payment {
  amountPayable: number;
  capitalPayment: number;
  commission: number;
  concept: string;
  cumulativeTotal: number;
  id: string;
  interestAmount: number;
  ivaCommission: number;
  nominaLoanId: string | null;
  paidOut: boolean;
  paymentDate: string;
  paymentNumber: number;
  personalLoanId: string | null;
  userId: string;
}

export interface PabsPayment {
  capital: number;
  pago_semanal: number;
  semanas: number;
  total_pagado: number;
  total_interes: number;
  iva_interes: string;
  tasa_anual: string;
  periodo_gracia: number;
  cortes: string;
  tipo_amortizacion: string;
  name: string;
}

export interface ActiveCredit {
  id: string;
  creditType: string;
  status?: string;
}
export interface ActiveCreditProduct {
  credits: CreditsProduct[];
}
interface CreditsProduct {
  id: string;
  creditType: string;
  status?: string;
}

export interface VeriffSession {
  sessionId: string;
  sessionURL: string;
  isActive: boolean;
  state: "approved" | "resubmission_requested" | "declined" | "expired" | "abandoned" | "review";
  timestamp: string;
  userId: string;
}

export interface Prospective {
  amount: number;
  amount_payment: number;
  attached1:boolean;
  attached2:boolean;
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender: string;
  lastFName: string;
  lastMName: string;
  //paymentStatus: string;
  phone: string;
  secondName: string;
  state: string;
  step: string;
  term: string;
  way_to_payment: string;
}

export interface ChangeData {
  name: string;
  phone: string;
  phone2:string;
  city:string;
  state: string;
  message:string;
}

type CreditType = "nomina" | "personal" | "pabs" | "diremovil";

export default class ApiClient {
  private URL = API_URL;
  private TOKEN: string;
  private NOMINA_ID: string;
  private USER_ID: string;
  private static instance: ApiClient;
  private geoService: GeolocationService;

  private constructor() {
    const token = localStorage.getItem("TOKEN");
    const userId = localStorage.getItem("USER_ID");
    const nominaId = localStorage.getItem("NOMINA_ID");

    if (token) {
      this.TOKEN = token;
    }
    if (nominaId) {
      this.NOMINA_ID = nominaId;
    }

    if (userId) {
      this.USER_ID = userId;
    }
    this.geoService = new GeolocationService();
  }

  public set token(token: string) {
    this.TOKEN = token;
  }

  public set userId(id: string) {
    this.USER_ID = id;
  }
  public set nominaId(id: string) {
    this.NOMINA_ID = id;
  }

  public getUserId() {
    return this.USER_ID;
  }
  public getUserToken() {
    return this.TOKEN;
  }
  public getNominaId() {
    return this.NOMINA_ID;
  }

  /**
   * Get an instance of the class.
   */
  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  /**
   * Endpoint for logging into an account.
   */
  public login(user: string, password: string) {
    return fetch(`${this.URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user, password }),
    });
  };

  /**
   * Endpoint for logging into an account.
   */
  public login_v2(user: string, password: string) {
    return fetch(`${this.URL}/login-v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user, password }),
    });
  };

  /**
   * Create new account.
   */
  public async signup(phone: string, password: string, type: string) {
    let coords = await this.geoService.getCoordinates();
    if(type === "diremovil"){
      return fetch(`${this.URL}/signup-diremovil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone,
          password,
          coordinates: {
            lat: coords.lat,
            lng: coords.long,
          },
        }),
      });
    } else if(type === "pabs"){
      return fetch(`${this.URL}/signup-pabs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone,
          password,
          coordinates: {
            lat: coords.lat,
            lng: coords.long,
          },
        }),
      });
    } else {
      return fetch(`${this.URL}/signup-personal-v2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone,
          password,
          coordinates: {
            lat: coords.lat,
            lng: coords.long,
          },
        }),
      });
    }

  }

  /*
   *   Register-form
   */
  public async createUserDetails(data: userDetails) {
    const response = await fetch(`${this.URL}/user-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify({
        userId: this.USER_ID,
        ...data,
      }),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response;
  }
  /*
   *   Register-form
   */
  public async createUserDetails_v2(data: userDetails_v2) {
    const response = await fetch(`${this.URL}/user-details-v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify({
        userId: this.USER_ID,
        ...data,
      }),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response;
  }

  /**
   * Endpoint for activate an account with a given token.
   */
  public activateAccount(token: string) {
    return fetch(`${this.URL}/activate/${token}`, {
      method: "PUT",
    });
  }

  /**
   * Function for validating if user has registered
   * user details.
   */
  public async userHasUserDetails() {
    const response = await fetch(
      `${this.URL}/user/exists/userDetails/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return (await response.json()).registered;
  }

  /**
   * Validate a user in a blacklist.
   */
  public async userBlacklistStatus(): Promise<"ON HOLD" | "APROOVED"> {
    const response = await fetch(
      `${this.URL}/users/${this.USER_ID}/user-record`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (Object.values(await response.json()).some((x) => x === true)) {
      return "ON HOLD";
    }

    return "APROOVED";
  }

  /**
   * Get available PABS cities
   */
  public async getCity(): Promise<ICity[]> {
    const response = await fetch(`${this.URL}/cities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    return response.json();
  }

  /**
   * Validate PABS contract
   */
  public async userContract(contract: string, city: string) {
    const response = await fetch(
      `${this.URL}/user/pabs/validate/${this.USER_ID}/${contract}/${city}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    console.log(response);

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Get user data with user id.
   */
  public async getUserData(): Promise<IUser> {
    const response = await fetch(`${this.URL}/user-details/${this.USER_ID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    return response.json();
  }

  public async setCompany(value: string): Promise<IUser> {
    const response = await fetch(
      `${this.URL}/user/company/${this.USER_ID}/${value}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async setCompanyNomina(value: string, tipo: string): Promise<IUser> {
    const response = await fetch(
      `${this.URL}/user/company/${this.USER_ID}/${value}/${tipo}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Endpoint for uploading files to backend service.
   * @param files files to upload as a FormData object.
   */
  public async uploadPabsFiles(files: FormData) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/pabs-loan/file-upload/${id}`, {
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: files,
      method: "POST",
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();
  }

  /**
   * Endpoint for uploading files to backend service.
   * @param files files to upload as a FormData object.
   */
  public async upLoadInformation(files: FormData) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/pabs-loan/file-upload/${id}`, {
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: files,
      method: "POST",
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();
  }

  /**
   * creates a new PABS loan object in the BACKEND SERVICE UWU.
   */
  public async createPABSloan(body: any) {
    const coords = await this.geoService.getCoordinates();
    console.log("coooooooooooordenadas", coords);
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };

    const response = await fetch(`${this.URL}/pabs-loan/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(bodyWithCoords),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async dowloandPabs(id: string) {
    const response = await fetch(`${this.URL}/pabs-loan/files/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async dowloandPabsAmortizacion(id: string) {
    const response = await fetch(
      `${this.URL}/pabs-loan/files-amortizacion/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async dowloandNomina(id: string) {
    const response = await fetch(`${this.URL}/nomina-loan/files/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async dowloandNominaAmortizacion(id: string) {
    const response = await fetch(
      `${this.URL}/nomina-loan/files-amortizacion/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }
  public async dowloandPersonal(id: string) {
    const response = await fetch(`${this.URL}/personal-loan/files/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async dowloandPersonalAmortizavion(id: string) {
    const response = await fetch(
      `${this.URL}/personal-loan/files-amortizacion/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  async getValidationTerms() {
    const response = await fetch(
      `${this.URL}/user/validate/privacy-loan/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Registers pending info on a PABS loan object.
   */
  public async pabsContinue(body: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/pabs-loan/continue/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();
  }

  /**
   * Get all countries available for registering a loan
   * @returns an object containing the list of countries
   */
  public async getCountries(): Promise<{ countries: string[] }> {
    const response = await fetch(`${this.URL}/country`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    return response.json();
  }

  public async getCountryStates(): Promise<{ states: string[] }> {
    const response = await fetch(`${this.URL}/country/state`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    return response.json();
  }

  /**
   * Creates a personal loan object on the db.
   * @param body Request body
   * @returns json response.
   */
  public async createPersonalLoan(body: object): Promise<any> {
    const coords = await this.geoService.getCoordinates();
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };
    const response = await fetch(`${this.URL}/personal-loan/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(bodyWithCoords),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Upload files to a form data object
   * @param files files to upload as form data object
   * @returns response as json
   */
  public async uploadPersonalLoanFiles(
    files: FormData,
    id: string
  ): Promise<any> {
    const response = await fetch(
      `${this.URL}/personal-loan/file-upload/${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: files,
      }
    );

    return response.json();
  }

  /**
   * Creates a nomina loan object on the db.
   * @param body Request body
   * @returns json response.
   */
  public async createNominaLoan(body: object): Promise<any> {
    const coords = await this.geoService.getCoordinates();
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };

    const response = await fetch(
      `${this.URL}/nomina-loan/personal-data/${this.USER_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(bodyWithCoords),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      //throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Upload files to a form data object
   * @param files files to upload as form data object
   * @returns response as json
   */
  public async uploadNominaLoanFiles(
    files: FormData,
    id: string
  ): Promise<any> {
    const response = await fetch(`${this.URL}/nomina-loan/file-upload/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: files,
    });

    return response.json();
  }

  public async updateCreditSettings(
    creditType: CreditType,
    data: CreditConfig,
    id: string
  ) {
    const requestBody = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(data),
    };

    if (creditType === "nomina") {
      return (
        await fetch(
          `${this.URL}/nomina-loan/payment-settings/${id}`,
          requestBody
        )
      ).json();
    }

    if (creditType === "personal") {
      return (
        await fetch(
          `${this.URL}/personal-loan/payment-settings/${id}`,
          requestBody
        )
      ).json();
    }
  }

  public async getCreditStatus(id: string, creditType: string) {
    const request = await fetch(
      `${this.URL}/loan/approved/${id}/${creditType}`,
      {
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return request.json();
  }

  public async getCredit(creditType: string) {
    const requestBody = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    };
    //llmada anterior para creditos de nomina
    // if (creditType === "nomina") {
    //   return (
    //     await fetch(`${this.URL}/nomina-loan/loan/${this.USER_ID}`, requestBody)
    //   ).json();
    // }
    if (creditType === "nomina" || creditType === "celular" || creditType === "tequila") {
      return (
        await fetch(`${this.URL}/nomina-loan/loan-product/${this.USER_ID}/${creditType}`, requestBody)
      ).json();
    }


    if (creditType === "personal") {
      return (
        await fetch(
          `${this.URL}/personal-loan/loan/${this.USER_ID}`,
          requestBody
        )
      ).json();
    }

    if (creditType === "pabs") {
      return (
        await fetch(`${this.URL}/pabs-loan/loan/${this.USER_ID}`, requestBody)
      ).json();
    }

    if(creditType === "diremovil"){
      return (
        await fetch(
          `${this.URL}/personal-loan/loan-diremovil/${this.USER_ID}`,
          requestBody
        )
      ).json();
    }

    if(creditType === "diremovil-promotion"){
      return (
        await fetch(
          `${this.URL}/personal-loan/loan-diremovil/${this.USER_ID}`,
          requestBody
        )
      ).json();
    }
  }

  public async getTablaAmortizacion(creditType: string, id: string) {
    switch (creditType) {
      case "nomina":
        return this.getTablaAmortizacionNomina(id);
      case "pabs":
        return this.getTablaAmortizacionPabs(id);
      case "personal":
        return this.getTablaAmortizacionPersonal(id);
        case "diremovil":case "diremovil-promotion":
        return this.getTablaAmortizacionPersonal(id);
      default:
        console.log("NO SE ENCONTRO EL CREDITTYPE =  " + creditType);
        break;
    }
  }

  public async getTablaAmortizacionPersonal(id: string): Promise<Payment[]> {
    const response = await fetch(
      `${this.URL}/personal-loan/table-amortization/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return response.json();
  }

  public async getTablaAmortizacionNomina(id: string): Promise<Payment[]> {
    const response = await fetch(
      `${this.URL}/nomina-loan/table-amortization/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return response.json();
  }

  public async getTablaAmortizacionPabs(id: string): Promise<Payment[]> {
    const response = await fetch(
      `${this.URL}/pabs-loan/table-amortization/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return response.json();
  }

  public async getActiveCredit(): Promise<ActiveCredit> {
    const response = await fetch(`${this.URL}/loans/active/${this.USER_ID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getProducts(
    upper: number,
    lower: number
  ): Promise<PabsPayment[]> {
    const response = await fetch(
      `${this.URL}/pabs-loan/products/${lower}-${upper}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    return response.json();
  }

  public async setProductName(productName: string) {
    const id = (await this.getActiveCredit()).id;

    const response = await fetch(`${this.URL}/pabs-loan/productName/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ productName }),
    });
    return response.json();
  }

  public async activateAccountSMS(code: string) {
    const response = await fetch(`${this.URL}/user/activate/${code}`, {
      method: "PUT",
    });

    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }

  public async resetPassword(phone: object) {
    const response = await fetch(`${this.URL}/user/reset-password/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(phone),
    });

    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }

  public async resetPasswordValue(value: string) {
    const response = await fetch(
      `${this.URL}/user/validate/reset-password/${value}`,
      {
        method: "GET",
      }
    );

    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }

  public async resetPasswordChange(password: object, token: string) {
    const response = await fetch(`${this.URL}/user/change-password/${token}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(password),
    });

    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }

  public async PabsValid() {
    const response = await fetch(
      `${this.URL}/user/pabs/validate/page/${this.USER_ID}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }  

  public async getSteps() {
    const response = await fetch(
      `${this.URL}/nomina-loan/process/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    // if (response.status !== 200) {
    //   const errorResponse = await response.json();
    //   throw console.log(errorResponse.error.message);
    // }

    return response.json();
  }

  public async getStepsNomina() {
    const response = await fetch(
      `${this.URL}/nomina-loan/process-products/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.json();
  }

  public async getNomina(codigo, ciudad) {
    const response = await fetch(
      `${this.URL}/user/nomina/web-service/${this.USER_ID}/${codigo}/${ciudad}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getItemsNomina(){
    let rate = 'B';
    const response = await fetch(`${this.URL}/smartphone/get-items/${rate}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "content-type": "application/json"
      },
    })
  }

  public async getNominaTipo(codigo, ciudad, tipo) {
    const response = await fetch(
      `${this.URL}/user/nomina/web-service/${this.USER_ID}/${codigo}/${ciudad}/${tipo}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async getNominaTipoProductos(codigo, ciudad, tipo) {
    const response = await fetch(
      `${this.URL}/user/nomina/web-service-celular/${this.USER_ID}/${codigo}/${ciudad}/${tipo}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getEmpleadoNumber() {
    console.log("peticion funcion");
    const response = await fetch(
      `${this.URL}/user/nomina/config/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }
    console.log(response.status);

    return response.json();
  }

  public async getProductsNomina(tipo: string): Promise<ISmartphone>{
    const response = await fetch(
      `${this.URL}/user/nomina/config-product/${this.USER_ID}/${tipo}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }
    console.log(response.status);

    return response.json();
  }

  public async sendNomina(body) {
    const response = await fetch(
      `${this.URL}/user/nomina/config/${this.USER_ID}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async sendNominaProducto(body,tipo:string) {
    const response = await fetch(
      `${this.URL}/user/nomina/config-product/${this.USER_ID}/${tipo}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateInformation(body: object) {
    console.log(body);
    const response = await fetch(
      `${this.URL}/nomina-loan/personal-data/${this.USER_ID}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateAdress(body: object) {
    // this.getNominaId()
    const response = await fetch(
      `${this.URL}/nomina-loan/address/${localStorage.getItem("NOMINA_ID")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateReference(body: object) {
    const response = await fetch(
      `${this.URL}/nomina-loan/labor-data/${localStorage.getItem("NOMINA_ID")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  /**
   * Upload files to a form data object
   * @param body files to upload as form data object
   * @returns response as json
   */
  public async updateFile(body: FormData) {
    console.log(body)
    const response = await fetch(
      `${this.URL}/nomina-loan/file-upload/${localStorage.getItem("NOMINA_ID")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: body,
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  public async getCityNomina(): Promise<ICity[]> {
    const response = await fetch(`${this.URL}/cities/nomina`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    return response.json();
  }

  public async termsConditions(body: any) {
    const response = await fetch(
      `${this.URL}/user/accept/terms-conditions-privacy/${this.USER_ID}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async termsConditions_v2(body: any) {
    const response = await fetch(
      `${this.URL}/user/accept/terms-conditions-privacy-v2/${this.USER_ID}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async contratoPersonal(accepted: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/personal-loan/accept/terms-conditions-privacy/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async contratoPABS(accepted: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/pabs-loan/accept/terms-conditions-privacy/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async contratoNomina(accepted: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/nomina-loan/accept/terms-conditions-privacy/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /** * * * * * * * * * * * +
   *    Third party API's   *
   ** * * * * * * * * * * * /  

  /**
   * Get address from zip code.
   */
  public async getAdrressFromZipCode(zip: any) {
    // const response = await fetch(`https://pc.axoloft.com/findcp/${zip}`, {
      const response = await fetch(`${this.URL}/loan/postal-code/${zip}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`
      },
    });

    if (response.status !== 200) {
      return {
        estado: "error",
        municipio: "error",
        ciudad: "error",
        colonias: "error"
      };
      console.log(await response.json());
      throw new Error("Error inesperado");
    }

    return response.json();
  }

  /**
   * Get address from zip code.
   */
  public async getAdrressFromZipCodePersonal(zip: any) {
    // const response = await fetch(`https://pc.axoloft.com/findcp/${zip}`, {
      const response = await fetch(`${this.URL}/loan/postal-code/${zip}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`
      },
    });

    if (response.status !== 200) {
      if ( response.status === 401 ) {
        return  window.location.replace("https://app.percapita.mx/login");
      }
      return {
        estado: "error",
        municipio: "error",
        ciudad: "error",
        delegation: "error",
        colonias: "error"
      };
      console.log(await response.json());
      throw new Error("Error inesperado");
    }

    return response.json();
  }

  /** * * * * * * * * * * * +
   *    back API's   *
   ** * * * * * * * * * * * /  
    /**
   * set banck Information
   */

  public async pabsBankInfo(banckInfo: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/pabs-loan/bank-information/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(banckInfo),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async nominaBankInfo(banckInfo: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/nomina-loan/bank-information/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(banckInfo),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async personalBankInfo(banckInfo: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/personal-loan/bank-information/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },

        body: JSON.stringify(banckInfo),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  /** * * * * * * * * * * * +
   *    popup API's   *
   ** * * * * * * * * * * * /  
    /**
   * get contrato Information
   */
  public async getContractPabs() {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/pabs-loan/info-contrato/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getContractNomina() {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/nomina-loan/info-contrato/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getContractPersonal() {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/personal-loan/info-contrato/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  /** * * * * * * * * * * * +
   *    popup API's   *
   ** * * * * * * * * * * * */
  public async getComentariosClient() {
    const id = (await this.getActiveCredit()).id;
    const type = (await this.getActiveCredit()).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/client/${id}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async getComentariosAdmi() {
    const id = (await this.getActiveCredit()).id;
    const type = (await this.getActiveCredit()).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/admin/${id}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async addComentariosClient(comment: string) {
    const loanId = (await this.getActiveCredit()).id;
    const typeLoan = (await this.getActiveCredit()).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/client/${this.USER_ID}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ comment, loanId, typeLoan }),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getCountComentarios() {
    const id = (await this.getActiveCredit()).id;
    const type = (await this.getActiveCredit()).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/viewed-client/${id}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async setCountComentarios() {
    const id = (await this.getActiveCredit()).id;
    const type = (await this.getActiveCredit()).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/viewed-client/${id}/${type}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

   /**
   * Get step to personal credit
   */
  public personalLoan(id: string) {
    return fetch(`${this.URL}/personal-loan/process/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
  };

  /**
   * Personal Form
   */
  public async personalFormData(body: any) {
    let coords = await this.geoService.getCoordinates();
    body.userCambaceoId = '';
    
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };

    const response = await fetch(`${this.URL}/personal-loan/personal-data/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(bodyWithCoords),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async addressFormData(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/address/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    console.log(response);

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async laborForm(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/labor-data/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    return response.json();

  };

  public async referencesForm(body: any, id:string) {
 
    const response = await fetch(`${this.URL}/personal-loan/references-personal/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async pepbsForm(body: any, id:string) {
 
    const response = await fetch(`${this.URL}/personal-loan/question-peps/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async finishedForm(body: any, id: string) {

    const response = await fetch(`${this.URL}/personal-loan/file-upload/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: body,
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async finishedFormRenovation(body: any, id: string) {

    const response = await fetch(`${this.URL}/personal-loan/file-upload-renovation/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: body,
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async checkUserVeriffValidated(): Promise<{veriffValidated: boolean}> {
    const response = await fetch(`${this.URL}/user/isVeriffValidated/${this.USER_ID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async createVeriffSession(documentType: "ID_CARD" | "PASSPORT" | "DRIVERS_LICENSE" | "RESIDENCE_PERMIT", creditType: string, loanId: string): Promise<VeriffSession> {
    const response = await fetch(`${this.URL}/veriff/${this.USER_ID}/session/${loanId}/${creditType}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        documentType,
      })
    });
    if(response.status === 409){
      const errorResponse = await response.json();
      const error = new Error(errorResponse.error.status);
      error.name = 'VeriffLimitSessions';
      throw error;
    } else if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }

    return response.json();
  }

  public async getActiveVeriffSession(): Promise<VeriffSession> {
    const response = await fetch(`${this.URL}/veriff/${this.USER_ID}/active-session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status === 404) {
      const errorResponse = await response.json();
      const error = new Error(errorResponse.error.status);
      error.name = 'VeriffSessionNotFound';
      throw error;
    } 
    else if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

    public async getUserPreInfo() {
    const response = await fetch(`${this.URL}/user/pre-info/${this.USER_ID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

  public async sendSmsCdc(){
    const response = await fetch(`${this.URL}/user/send-sms-cdc/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

    public async validateCirculoCredito(code:string, isRenovacion:boolean){
    
    var tiempo = new Date(),
    month = "" + (tiempo.getMonth() + 1),
    day = "" + (tiempo.getDate() ),
    year = tiempo.getFullYear(),
    hour = tiempo.getHours().toString(),
    minute = tiempo.getMinutes().toString(),
    second = tiempo.getSeconds().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if(hour.length < 2) hour = "0" + hour;
    if(minute.length < 2) hour = "0" + minute;
    if(second.length < 2) hour = "0" + second;

    const data = {
        folio:code,
        fechaAcepta: year + '-' + month + '-' + day,
        horaAcepta: hour + ':' + minute + ':' + second,

    }
    const response = await fetch(`${this.URL}/personal-loan/${isRenovacion ? 'code-cdc-renovation' : 'code-cdc'}/${this.USER_ID}`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(data),
    });
    
    return response.json();
  }

  public async getProductsDesglose() {
    const response = await fetch(
      `${this.URL}/nomina-loan/products`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        }
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  public async updateInformationProduct(body: object,type: string) {
    console.log(body);
    const response = await fetch(
      `${this.URL}/nomina-loan/personal-data-product/${this.USER_ID}/${type}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }
  
  public async updateAdressProduct(body: object,type: string) {
    // this.getNominaId()
    const response = await fetch(
      `${this.URL}/nomina-loan/address-product/${localStorage.getItem("NOMINA_ID")}/${type}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateReferenceProduct(body: object,type: string) {
    const response = await fetch(
      `${this.URL}/nomina-loan/labor-data-product/${localStorage.getItem("NOMINA_ID")}/${type}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateFileProduct(body: FormData,type: string) {
    console.log(body)
    const response = await fetch(
      `${this.URL}/nomina-loan/file-upload-product/${localStorage.getItem("NOMINA_ID")}/${type}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: body,
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw console.log(errorResponse.error.message);
    }

    return response.json();
  }
  public async getActiveCreditNomina(type: string) {
    const response = await fetch(`${this.URL}/loans/active-product/${this.USER_ID}/${type}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getComentariosClientProduct(tipo:string) {
    const id = (await this.getActiveCreditNomina(tipo)).id;
    const type = (await this.getActiveCreditNomina(tipo)).creditType;
    const response = await fetch(
      `${this.URL}/comments-loans/client/${id}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async contratoNominaProduct(accepted: any, type: string) {
    const id = (await this.getActiveCreditNomina(type)).id;
    const response = await fetch(
      `${this.URL}/nomina-loan/accept/terms-conditions-privacy/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async dowloandNominaProduct(id: string, type: string) {
    const response = await fetch(`${this.URL}/nomina-loan/files-product/${id}/${type}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

   public async dowloandNominaInstructionLetter(id: string) {
    const response = await fetch(
      `${this.URL}/nomina-loan/instructions-letter/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

    async getValidationTermsProduct(type:string) {
    const response = await fetch(
      `${this.URL}/user/validate/privacy-loan-product/${this.USER_ID}/${type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

    public async nominaBankInfoType(banckInfo: any, tipo: string) {
    const id = (await this.getActiveCreditNomina(tipo)).id;
    const response = await fetch(
      `${this.URL}/nomina-loan/bank-information/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(banckInfo),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }
  
  public async setProspect(values: Prospective){
    const response = await fetch(`${this.URL}/user/save-prospect`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response;
  }

  public async getProspect(id:string){
    const response = await fetch(`${this.URL}/user/get-prospect/${id}`,{
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      }
    }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response;
  }
  public async preValidation(id:string,code:string){
    const response = await fetch(`${this.URL}/user/pre-activate/${code}/${id}`,{
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      }
    }
    );
    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }

  public async getCityWithoutLogin(): Promise<ICity[]> {
    const response = await fetch(`${this.URL}/cities-pabs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }

  public async validatePhone(code: string){
    const response = await fetch(`${this.URL}/user/exist-phone/${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }

  public async validatePhoneTitulo(code: string,utm_medium: string,utm_campaign: string,utm_source: string){
    const response = await fetch(`${this.URL}/user/exist-phone/${utm_medium}/${utm_campaign}/${utm_source}/${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }

  public async validatePhoneFirstPagePersonal(code: string,utm_medium: string,utm_campaign: string,utm_source: string){
    const response = await fetch(`${this.URL}/user/exist-phone-from-personal/${utm_medium}/${utm_campaign}/${utm_source}/${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }

  public async sendSmsCredit(){
    const response = await fetch(`${this.URL}/user/send-sms-credit/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

  public async sendSmsCreditByPhone(phone: string){
    const response = await fetch(`${this.URL}/user/send-sms-credit-by-phone/${phone}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

  public async sendWhatsAppCredit(){
    const response = await fetch(`${this.URL}/user/send-whatsapp-credit/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

  public async sendWhatsAppCreditByPhone(phone: string){
    const response = await fetch(`${this.URL}/user/send-whatsapp-credit-by-phone/${phone}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    
    return response.json();
  }

  public async signupPersonal(phone: string, password: string, from: string) {
    let coords = await this.geoService.getCoordinates();

    return fetch(`${this.URL}/signup-personal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone,
        password,
        coordinates: {
          lat: coords.lat,
          lng: coords.long,
        },
        from,
      }),
    });
  }

  public async saveData(values: ChangeData){
    const response = await fetch(`${this.URL}/user/save-change-data/${this.USER_ID}`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response;
  }

  public async getDataChange() {
    const response = await fetch(
      `${this.URL}/user/get-data-change`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async getCDCDataUser():Promise<cdcInfo>{
    const response = await fetch(`${this.URL}/personal-loan/get-info/${this.USER_ID}`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      }
    }
    );
    console.log(response)
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateInfoCdc(data:cdcInfo){
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/personal-loan/update-info/${id}`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }
    );
    
    // if (response.status !== 200) {
    //   const errorResponse = await response.json();
    //   console.log(errorResponse.error.message)
    //   throw new Error(errorResponse.error.message);
    // }

    return response.json();
  }
  public async getInactiveCredit(): Promise<ActiveCredit> {
    const response = await fetch(`${this.URL}/loans/no-active/${this.USER_ID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async saveMarketingTrack(data: any) {
    const response = await fetch(`${this.URL}/marketing/register-step`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateMarketingTrack(id: any, data: any) {
    const response = await fetch(`${this.URL}/marketing/update-step/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

     /**
   * Get step to diremovil credit
   */
     public personalLoanDiremovil(id: string) {
      return fetch(`${this.URL}/personal-loan/process-diremovil/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });
    };
  
      /**
   * Personal Form
   */
  public async diremovilFormData(body: any) {
    let coords = await this.geoService.getCoordinates();
    var cambaceo = JSON.parse(localStorage.getItem("userVentaDiremovil"));
    if(cambaceo){
      if(cambaceo.userId !== null && cambaceo.userId !== undefined && cambaceo.userId !== ''){
        body.userCambaceoId = cambaceo.userId;
      }
    } else {
      body.userCambaceoId = '';
    }
    
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };

    const response = await fetch(`${this.URL}/personal-loan/personal-data-diremovil/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(bodyWithCoords),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };
  
  public async addressFormDiremovilData(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/address-diremovil/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    console.log(response);

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async laborFormDiremovil(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/labor-data-diremovil/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    return response.json();

  };

  public async referencesFormDiremovil(body: any, id:string) {
 
    const response = await fetch(`${this.URL}/personal-loan/references-personal-diremovil/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async finishedFormDiremovil(body: any, id: string) {

    const response = await fetch(`${this.URL}/personal-loan/file-upload-diremovil/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: body,
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async updateCreditSettingsDiremovil(
    creditType: CreditType,
    data: CreditConfig,
    id: string
  ) {
    const requestBody = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(data),
    };

      return (
        await fetch(
          `${this.URL}/personal-loan/payment-settings-diremovil/${id}`,
          requestBody
        )
      ).json();
  }

  public loginDiremovil(user: string, password: string) {
    return fetch(`${this.URL}/login-diremovil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user, password }),
    });
  };

  public async dowloandContratoDiremovil(id: string) {
    const response = await fetch(`${this.URL}/personal-loan/files-diremovil/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async dowloandDiremovilAmortization(id: string) {
    const response = await fetch(
      `${this.URL}/personal-loan/files-amortizacion-diremovil/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async contratoDiremovil(accepted: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/personal-loan/accept/terms-conditions-privacy-diremovil/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async dowloandDiremovilInstructionLetter(id: string) {
    const response = await fetch(
      `${this.URL}/personal-loan/instructions-letter/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.blob();
  }

  public async getExceptionVeriff() {
    const response = await fetch(
      `${this.URL}/user/get-veriff-exception/${this.USER_ID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${this.TOKEN}`,
        },
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async uploadPabsFilesAll(files: FormData) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(`${this.URL}/pabs-loan/file-upload-all/${id}`, {
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: files,
      method: "POST",
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();
  }
  public async getDeliveredCredit(): Promise<ActiveCredit> {
    const response = await fetch(`${this.URL}/loans/delivered-credit/${this.USER_ID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async activateAccountSMSV2(code: string, data : any) {
    const response = await fetch(`${this.URL}/user/activate-pabsLoan-v2/${code}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 400) {
      throw new Error("Código inválido");
    }

    if (response.status === 404) {
      throw new Error("El usuario no existe.");
    }

    if (response.status === 500) {
      throw new Error("Un error inesperado ocurrio.");
    }

    return response.json();
  }


  public async diremovilPromotionFormData(body: any) {
    let coords = await this.geoService.getCoordinates();
    body.userCambaceoId = '';
    
    const bodyWithCoords = {
      ...body,
      coordinates: {
        lat: coords.lat,
        lng: coords.long,
      },
    };

    const response = await fetch(`${this.URL}/personal-loan/personal-data-diremovil-promotion/${this.USER_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(bodyWithCoords),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };
  public personalLoanDiremovilPromotion(id: string) {
    return fetch(`${this.URL}/personal-loan/process-diremovil-promotion/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });
  };
  public async addressFormDiremoviPromotionlData(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/address-diremovil-promotion/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    console.log(response);

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };
  // '/product-personal-diremovil
  public async getProductsPersonalDiremovil() {
    const response =  await fetch(`${this.URL}/product-personal-diremovil`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();

  };
  public async getProductsPersonalDiremovil2() {
    const response =  await fetch(`${this.URL}/product-personal-diremovil2`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
    return response.json();

  };

  public async updateCreditSettingsDiremovilPromotion(
    data: CreditConfig,
    id: string
  ) {
    const requestBody = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(data),
    };

      return (
        await fetch(
          `${this.URL}/personal-loan/payment-settings-diremovil-promotion/${id}`,
          requestBody
        )
      ).json();
  }

  public async laborFormDiremovilPromotion(body: any, nominaId: string) {
 
    const response = await fetch(`${this.URL}/personal-loan/labor-data-diremovil-promotion/${nominaId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    return response.json();

  };

  public async referencesFormDiremovilPromotion(body: any, id:string) {
 
    const response = await fetch(`${this.URL}/personal-loan/references-diremovil-promotion/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();

  };

  public async finishedFormDiremovilPromotion(body: any, id: string) {

    const response = await fetch(`${this.URL}/personal-loan/file-upload-diremovil-promotion/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.TOKEN}`,
      },
      body: body,
    });

    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

  public async contratoDiremovilPromotion(accepted: any) {
    const id = (await this.getActiveCredit()).id;
    const response = await fetch(
      `${this.URL}/personal-loan/accept/terms-conditions-privacy-diremovil-promotion/${id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accepted),
      }
    );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }

    return response.json();
  }

}
