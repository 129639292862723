import React, { useEffect, useState, useRef } from "react";
import Navbar from "././NavbarI";
import Footer from "././FooterI";
import circulo_flecha from "../../img/circulo-flecha.png";
import logo_pabs2 from "../../img/logo_pabs.png";
import logo from "../../img/logo-bco.png";
import logo_balnco from "../../img/logo_blanco.png";
import icono_mujer from "../../img/icono_mujer.png";
import { useHistory, useLocation } from "react-router-dom";
import { PreTramiteCreditoContext } from "../../context/PreTramiteCreditoProvider";
import { TramiteCreditoContext } from "../../context/TramiteCreditoProvider";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { UserContext } from "./../../context/UserProvider";
import { animate, motion } from "framer-motion";
//import {useInView} from "react-intersection-observer"
import { useAnimation, useInView } from "framer-motion";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function HomeI() {
  const [state, setState] = useState(false);
  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  const ref = useRef(null);
  const refM = useRef(null);
  const inView = useInView(ref);
  const inViewMobile = useInView(refM);
  const query = useQuery();
  const history = useHistory();
  const [param, setParam] = useState({
    minValue: 2000,
    maxValue: 20000,
    step: 1000,
  });
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const { credito, updateCredito, deleteCredito } = React.useContext(
    TramiteCreditoContext
  );
  const { userMain, updateUser } = React.useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(7000);
  const [amountText, setAmountText] = useState(amount);
  const [plazoL, setPlazoL] = useState("quincenal");
  const [plazoM, setplazoM] = useState("6 meses");
  const [pago, setPago] = useState(840);
  const [fin, setFin] = useState(2000);
  const [change, setChange] = useState(false);
  const [change2, setChange2] = useState(false);

  const setCredit = (credit) => {
    updateUser({
      ...userMain,
      creditType: credit,
    });
  };
  const [table, setTable] = useState([
    { monto: 2000, quincenal: 420, semanal: 210, plazo: "3 meses" },
    { monto: 3000, quincenal: 630, semanal: 310, plazo: "3 meses" },
    { monto: 4000, quincenal: 840, semanal: 410, plazo: "3 meses" },
    { monto: 5000, quincenal: 600, semanal: 300, plazo: "6 meses" },
    { monto: 6000, quincenal: 720, semanal: 350, plazo: "6 meses" },
    { monto: 7000, quincenal: 840, semanal: 410, plazo: "6 meses" },
    { monto: 8000, quincenal: 960, semanal: 500, plazo: "6 meses" },
    { monto: 9000, quincenal: 1080, semanal: 550, plazo: "6 meses" },
    { monto: 10000, quincenal: 1200, semanal: 600, plazo: "6 meses" },
    { monto: 11000, quincenal: 1320, semanal: 650, plazo: "6 meses" },
    { monto: 12000, quincenal: 930, semanal: 450, plazo: "12 meses" },
    { monto: 13000, quincenal: 1000, semanal: 480, plazo: "12 meses" },
    { monto: 14000, quincenal: 1100, semanal: 600, plazo: "12 meses" },
    { monto: 15000, quincenal: 1170, semanal: 550, plazo: "12 meses" },
    { monto: 16000, quincenal: 1250, semanal: 580, plazo: "12 meses" },
    { monto: 17000, quincenal: 1320, semanal: 620, plazo: "12 meses" },
    { monto: 18000, quincenal: 1400, semanal: 650, plazo: "12 meses" },
    { monto: 19000, quincenal: 1480, semanal: 700, plazo: "12 meses" },
    { monto: 20000, quincenal: 1550, semanal: 720, plazo: "12 meses" },
  ]);
  const [move, setMove] = useState(true);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen2(false);

  function handleChange(event) {
    setChange(!change);
    setChange2(!change2);
    setAmount(parseFloat(event.target.value));
    setAmountText(parseFloat(event.target.value));
    const filter: any = table.filter(
      (e) => e.monto === parseFloat(event.target.value)
    );
    plazoL == "semanal"
      ? setPago(filter[0].semanal)
      : setPago(filter[0].quincenal);
    setplazoM(filter[0].plazo);
    // setTimeout(() => {
    //   setChange(false)
    // }, 0.5);
  }
  function handleChangeRadio(event) {
    setPlazoL(event.target.value);
    setChange2(!change2);
    const filter: any = table.filter((e) => e.monto === amount);
    event.target.value == "semanal"
      ? setPago(filter[0].semanal)
      : setPago(filter[0].quincenal);
    setplazoM(filter[0].plazo);
  }

  function next(event) {
    let limit = param.maxValue;
    let a = amount + 1000;
    if (a <= limit) {
      setAmountText(a);
      setChange(!change);
      setChange2(!change2);
      setAmount(a);
      const filter: any = table.filter((e) => e.monto === a);
      plazoL == "semanal"
        ? setPago(filter[0].semanal)
        : setPago(filter[0].quincenal);
      setplazoM(filter[0].plazo);
    }
  }

  const solicitarClicked = async () => {
    setOpen2(true);
    updateUser({
      ...userMain,
      preAmount: 0,
    });
  }

  function previous(event) {
    let limit = param.minValue;
    let a = amount - 1000;
    setChange(!change);
    setChange2(!change2);
    if (a >= limit) {
      setAmountText(a);
      setAmount(a);
      const filter: any = table.filter((e) => e.monto === a);
      plazoL == "semanal"
        ? setPago(filter[0].semanal)
        : setPago(filter[0].quincenal);
      setplazoM(filter[0].plazo);
    }
  }

  const sendData = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    updatePreCredito({
      ...preCredito,
      amount: amount,
      paymentStatus: "0",
      term: plazoL,
      way_to_payment: plazoM,
      amount_payment: pago,
      step: "preSolicitud",
    });
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      history.push("/solicitud-datos");
    }, 1200);
  };
  const checkFromRoute = async () => {
    const from = query.get("from");
    if (from === "Titulo") {
      history.push("/Titulo");
    } else if (from === "Personal") {
      history.push("/Personal");
    } else if (
      credito.estatusSolicitud === "solicitud-no-aprobada" ||
      credito.estatusSolicitud === "sin-solicitud" ||
      credito.estatusSolicitud === "sin-credito"
    ) {
      console.log(3);
      setOpen2(false);
    }
  };

  useEffect(() => {
    checkFromRoute();
    setTimeout(() => {
      setMove(false);
    }, 1200);
  }, []);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();
  const animation6 = useAnimation();
  const animation7 = useAnimation();
  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation2.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation3.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
      animation4.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation3.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation4.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);

  useEffect(() => {
    if (inViewMobile) {
      animation5.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation6.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation7.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inViewMobile) {
      animation5.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation6.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation7.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inViewMobile]);

  return (
    <motion.div
      // //initial={{ y:'50%', opacity:0, scale: 0.5 }}
      // animate={animation}
      //  //exit={{y: "50%", transition:{duration:0.4}}}
      // transition={{ ease: "easeOut", duration: 0.4 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="d-flex justify-content-end p-2  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-5">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo un <br /> título PABS{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/titulo"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("titulo")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a>Click aqui</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito en <br />
                      menos de 24 hrs
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/personal"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("personal")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  // width="220"
                  className="img-fluid test-modal"
                />
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo un <br /> título PABS{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/titulo"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("titulo")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito en <br />
                      menos de 24 hrs
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/personal"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("personal")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      <div className="d-none d-sm-none d-md-block">
        <div className="bg-index">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="txt-index" ref={ref}>
                      <div className="mb-2">
                        <motion.div
                          ////initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation}
                          //////exit={{y: "50%", transition:{duration:1.2}}}
                          //transition={{ ease: "easeInOut", duration: 5 }}
                        >
                          <h1 className="rLight display-2 mb-4">Adquiere </h1>
                        </motion.div>
                        <motion.div
                          //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation2}
                          ////exit={{y: "50%", transition:{duration:1.2}}}
                          //transition={{ ease: "easeInOut", duration: 1.2 }}
                        >
                          <h1 className="rBlack display-2 mb-4">libertad</h1>
                        </motion.div>
                        <motion.div
                          //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation3}
                          //exit={{y: "50%", transition:{duration:1.6}}}
                          //transition={{ ease: "easeInOut", duration: 1.6 }}
                        >
                          <h1 className="rBlack txt-azul display-2 mb-4">
                            económica
                          </h1>
                        </motion.div>
                      </div>
                      <motion.div
                        //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                        animate={animation4}
                        //exit={{y: "50%", transition:{duration:2}}}
                        //transition={{ ease: "easeInOut", duration: 2 }}
                      >
                        <h2 className="rBold">
                          <img
                            src={circulo_flecha}
                            alt="PERCAPITA"
                            className="img-fluid mb-2"
                          />
                          En menos de 24 horas
                        </h2>
                      </motion.div>
                    </div>
                  </div>
                  <motion.div
                    //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                    animate={animation}
                    //exit={{y: "50%", transition:{duration:1}}}
                    transition={{ ease: "easeOut", duration: 1 }}
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                  >
                    <div className="mx-3">
                      {/*<div className="bg-cuadro-bco" ref={ref}>
                        <div className="margen-cuadro py-5">
                          <h2 className="rExtraBold txt-azul-marino" ref={ref}>
                            ¿Con <b className="txt-azul">cuantó dinero</b>
                          </h2>
                          <h2 className="rExtraBold txt-azul-marino" ref={ref}>
                            podemos apoyarte?
                          </h2>
                          <div className="my-3 d-flex justify-content-center">
                            <div className="text-center me-5 " ref={ref}>
                              <button className="btn btn-primary btn-minus">
                                <i
                                  className="fas fa-minus"
                                  onClick={previous}
                                ></i>
                              </button>
                            </div>
                            <div className="text-center ms-5" ref={ref}>
                              <CSSTransition
                                nodeRef={nodeRef}
                                in={change}
                                timeout={200}
                                classNames="my-node"
                              >
                                <div ref={nodeRef}>
                                  <h2 className="oExtraBold txt-azul-marino">
                                    {" "}
                                    {amountText.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}{" "}
                                  </h2>
                                </div>
                                  </CSSTransition>*/}
                              {/* <h2 className="oExtraBold txt-azul-marino"> {amountText.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                  }</h2> */}
                            {/*</div>
                            <div className="text-center ms-5" ref={ref}>
                              <button className="btn btn-primary btn-plus">
                                <i className="fas fa-plus" onClick={next}></i>
                              </button>
                            </div>
                          </div>
                          <div className="slidecontainer pb-2" ref={ref}>
                            <input
                              type="range"
                              className="slider"
                              min={param.minValue}
                              max={param.maxValue}
                              step={param.step}
                              value={amount}
                              id="customRange1"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="my-4" ref={ref}>
                            <div className="pago-aprox">
                              <div className="mx-3 py-4">
                                <div className="text-center mb-4">
                                  <h6 className="txt-azul-marino rExtraBold h5">
                                    Tu pago aproximado:
                                  </h6>
                                </div>
                                <div className="d-flex mb-4">
                                  <div className="me-5">
                                    <div className="form-check">
                                      <input
                                        className="htmlForm-check-input"
                                        type="radio"
                                        name="radioQuincenal"
                                        id="radioQuincenal"
                                        value="quincenal"
                                        checked={plazoL === "quincenal"}
                                        onChange={handleChangeRadio}
                                      />
                                      <label
                                        className="form-check-label txt-gris oBold"
                                        htmlFor="radioQuincenal"
                                      >
                                        Quincenal
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="htmlForm-check-input"
                                        type="radio"
                                        name="radioSemanal"
                                        id="radioSemanal"
                                        value="semanal"
                                        checked={plazoL === "semanal"}
                                        onChange={handleChangeRadio}
                                      />
                                      <label
                                        className="form-check-label txt-gris oBold"
                                        htmlFor="radioSemanal"
                                      >
                                        Semanal
                                      </label>
                                    </div>
                                  </div>
                                  <div className="ms-2">
                                    <CSSTransition
                                      nodeRef={nodeRef2}
                                      in={change2}
                                      timeout={200}
                                      classNames="my-node"
                                    >
                                      <div ref={nodeRef2}>
                                        <h2 className="oExtraBold txt-azul">
                                          {pago.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </h2>
                                      </div>
                                    </CSSTransition>
                                  </div>
                                </div>
                                <div className="my-4 mx-3">
                                  <p className="rLight txt-gris">
                                    El pago estimado es para fines informativos
                                    y de comparación,
                                  </p>
                                  <p className="rLight txt-gris">
                                    este podrá cambiar en base al proceso de
                                    evaluación de
                                  </p>
                                  <p className="rLight txt-gris">
                                    nuestra área de crédito.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>*/}
                          {/* <div className="mt-3 text-center">
                            <a href="#" className="btn btn-primary btn-solicitar">
                              <h6 className="pt-1 rBlack">Solicitar crédito</h6>
                            </a>
                          </div> */}
                          {/*{credito.estatusSolicitud === "sin-solicitud" ||
                          credito.estatusSolicitud === "sin-credito" ||
                          credito.estatusSolicitud === "" ? (
                            <div className="mt-3 text-center">
                              <a
                                href="#"
                                className="btn btn-primary btn-conoce-azul"
                                onClick={solicitarClicked}
                              >
                                <h6 className="rBlack txt-azul-marino pt-1">
                                  Solicitar
                                </h6>
                              </a>
                            </div>
                          ) : (
                            <div className="mt-3 text-center">
                              <button
                                className="btn btn-primary btn-conoce-azul"
                                disabled
                              >
                                <h6 className="rBlack txt-azul-marino pt-1">
                                  Solicitar
                                </h6>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>*/}
                      <div className=" pt-5" ref={ref}>
                        <div className="margen-cuadro py-5">
                          {credito.estatusSolicitud === "sin-solicitud" ||
                          credito.estatusSolicitud === "sin-credito" ||
                          credito.estatusSolicitud === "" ? (
                            <div className="mt-3 text-center pt-5">
                              <a
                                href="#"
                                className="btn btn-primary btn-conoce-azul-xl"
                                onClick={solicitarClicked}
                              >
                                <h2 className="rBlack txt-azul-marino pt-1">
                                  Solicitar
                                </h2>
                              </a>
                            </div>
                          ) : (
                            <div className="mt-3 text-center pt-5">
                              <button
                                className="btn btn-primary btn-conoce-azul-xl"
                                disabled
                              >
                                <h2 className="rBlack txt-azul-marino pt-1">
                                  Solicitar
                                </h2>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none">
        <div className="bg-index-phone py-5" ref={refM}>
          <div className="container">
            <div className="row">
              <div className="text-center">
                <div className="mb-5">
                  <motion.div animate={animation5}>
                    <h1 className="rLight display-2">
                      Adquiere <b className="rBlack">libertad</b>
                    </h1>
                  </motion.div>
                  <motion.div animate={animation6}>
                    <h1 className="rBlack txt-azul display-2">económica</h1>
                  </motion.div>
                </div>
                <motion.div animate={animation7}>
                  <div className="text-center pt-3">
                    <img
                      src={circulo_flecha}
                      alt="PERCAPITA"
                      className="img-fluid mb-2"
                    />
                  </div>
                  <h2 className="rBold">En menos de 24 horas</h2>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gris">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="mx-1">
                  {/*<div className="bg-cuadro-bco">
                    <div className="margen-cuadro py-5">
                      <h2 className="rExtraBold txt-azul-marino">
                        ¿Con <b className="txt-azul">cuantó dinero</b>
                      </h2>
                      <h2 className="rExtraBold txt-azul-marino">
                        podemos apoyarte?
                      </h2>
                      <div className="my-3 d-flex justify-content-center">
                        <div className="text-center me-5 ">
                          <button className="btn btn-primary btn-minus">
                            <i
                              className="fa-solid fa-minus"
                              onClick={previous}
                            ></i>
                          </button>
                        </div>
                        <div className="text-center ms-2">
                          <h2 className="oExtraBold txt-azul-marino">
                            {amountText.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h2>
                        </div>
                        <div className="text-center ms-5">
                          <button className="btn btn-primary btn-plus">
                            <i className="fa-solid fa-plus" onClick={next}></i>
                          </button>
                        </div>
                      </div>
                      <div className="slidecontainer pb-2">
                        <input
                          type="range"
                          className="slider"
                          min={param.minValue}
                          max={param.maxValue}
                          step={param.step}
                          value={amount}
                          id="customRange1"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="my-4">
                        <div className="">
                          <div className=" py-4">
                            <div className="text-center mb-4">
                              <h6 className="txt-azul-marino rExtraBold h5">
                                Tu pago aproximado:
                              </h6>
                            </div>
                            <div className="d-flex mb-4">
                              <div className="me-5">
                                <div className="form-check">
                                  <input
                                    className="htmlForm-check-input"
                                    type="radio"
                                    name="radioQuincenal"
                                    id="radioQuincenal"
                                    value="quincenal"
                                    checked={plazoL === "quincenal"}
                                    onChange={handleChangeRadio}
                                  />
                                  <label
                                    className="form-check-label txt-gris oBold"
                                    htmlFor="radioQuincenal"
                                  >
                                    Quincenal
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="htmlForm-check-input"
                                    type="radio"
                                    name="radioSemanal"
                                    id="radioSemanal"
                                    value="semanal"
                                    checked={plazoL === "semanal"}
                                    onChange={handleChangeRadio}
                                  />
                                  <label
                                    className="form-check-label txt-gris oBold"
                                    htmlFor="radioSemanal"
                                  >
                                    Semanal
                                  </label>
                                </div>
                              </div>
                              <div className="ms-2">
                                <h2 className="oExtraBold txt-azul">
                                  {pago.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </h2>
                              </div>
                            </div>
                            <div className="mt-4">
                              <p className="rLight txt-gris">
                                El pago estimado es para fines informativos y de
                                comparación, este podrá cambiar en base al
                                proceso de evaluación de nuestra área de
                                crédito.
                              </p>
                            </div>
                          </div>
                          {credito.estatusSolicitud ===
                            "solicitud-no-aprobada" ||
                          credito.estatusSolicitud === "sin-solicitud" ||
                          credito.estatusSolicitud === "sin-credito" ? (
                            <div className="text-center">
                              <a
                                href="#"
                                className="btn btn-primary btn-conoce-azul"
                                onClick={solicitarClicked}
                              >
                                <h6 className="rBlack  pt-1">Solicitar</h6>
                              </a>
                            </div>
                          ) : (
                            <div className="text-center">
                              <button
                                className="btn btn-primary btn-conoce-azul"
                                disabled
                              >
                                <h6 className="rBlack  pt-1">Solicitar</h6>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div className="">
                    <div className="margen-cuadro py-5">
                      <div className="my-4">
                        <div className="">
                          {credito.estatusSolicitud ===
                            "solicitud-no-aprobada" ||
                          credito.estatusSolicitud === "sin-solicitud" ||
                          credito.estatusSolicitud === "sin-credito" ? (
                            <div className="text-center">
                              <a
                                href="#"
                                className="btn btn-primary btn-conoce-azul"
                                onClick={solicitarClicked}
                              >
                                <h6 className="rBlack  pt-1">Solicitar</h6>
                              </a>
                            </div>
                          ) : (
                            <div className="text-center">
                              <button
                                className="btn btn-primary btn-conoce-azul"
                                disabled
                              >
                                <h6 className="rBlack  pt-1">Solicitar</h6>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </motion.div>
  );
}
