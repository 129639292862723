import {
  Field,
  Formik,
  Form,
  useFormik,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { useHistory, useLocation } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import * as Yup from "yup";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import logo_pabs from "../../../img/logo_pabs.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState, useRef } from "react";
import { UserContext } from "./../../../context/UserProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import { useAnimation, useInView } from "framer-motion";
import moment from "moment";
import { validateString } from "../../../utils/StringValidator";

const requierdMessage = "Requerido";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const validationSchema = Yup.object().shape({
  city: Yup.string().required(requierdMessage),
  contract: Yup.string().required(requierdMessage),
});

export default function Validacion() {
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const query = useQuery();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ciudades, setCiudades] = useState([]);
  const [login, setLogin] = React.useState(false);
  const { userMain, updateUser } = React.useContext(UserContext);
  const [phoneStatus, setPhoneStatus] = React.useState(true);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [shown, setShown] = React.useState(false);
  const [phoneChange, setPhoneChange] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState("");
  const [fromLogin, setFromLogin] = React.useState(false);
  const [sendCode, setSendCode] = React.useState({
    phone: "",
    send: false,
  });

  const [loading, setLoading] = useState(false);

  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  useEffect(() => {
    apiClient
      .getCityWithoutLogin()
      .then((ciudades) => {
        setCiudades(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    apiClient
      .getUserData()
      .then((data) => {
        console.log(data);
        setFieldValue("firstName", data.firstName);
        setFieldValue("secondName", data.secondName);
        setFieldValue("lastFName", data.lastFName);
        setFieldValue("lastMName", data.lastMName);
        setFieldValue("email", data.email);
        setFieldValue("dateOfBirth", data.birthDate.substr(0, 10));
        setFieldValue("gender", data.sex);
        setFieldValue("knowAboutPercapita", data.knowAboutPercapita);
        console.log(
          "fecha CDC",
          moment(data.birthDate).utc().format("YYYY-MM-DD")
        );
        var d = new Date(data.birthDate),
          month = "" + (d.getMonth() + 1),
          day = "" + (d.getDate() + 1),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var formatoFecha: string = [month, day, year].join("/");
        const user = data;
        user.birthDate = formatoFecha;
        setDate(formatoFecha);
      })
      .catch((e) => console.error(e));
  }, []);

  function handleChangeKnowAboutPercapita(event: any) {
    
    console.log("handleChangeKnowAboutPercapita = " + event.target.value);
    if (event.target.value === "Selecciona una opción:") {
      errors.knowAboutPercapita = null;
      setFieldValue("knowAboutPercapita", null);
    }
    else {
      errors.knowAboutPercapita = event.target.value;
      setFieldValue("knowAboutPercapita", event.target.value);
    }
  };

  const createPABS = (values: any) => {
    setLoading(true);

    apiClient
      .userContract(values.contract, values.city)
      .then((value) => {
        localStorage.setItem("upper", value.upper);
        localStorage.setItem("lower", value.lower);
        console.log("Fom 113", value);
        history.push("/PABS");
      })
      .catch((err) => {
        console.log("115 error", err);
        if (err.message == "Contract number was not found.") {
          history.push("/RECHAZADO/pabs/contract-not-found");
        } else if (err.message == "La ciudad elegida no existe.") {
          history.push("/RECHAZADO/pabs/city-not-found");
        } else if (
          err.message ==
          "El título registrado ya fue utilizado. Cuenta con otro titulo vigente?"
        ) {
          history.push("/RECHAZADO/pabs/validated-user");
        } else if (
          err.message ==
          "El nombre regisrado no coincide con el número de contrato. Favor de revisar los datos"
        ) {
          history.push("/RECHAZADO/pabs/user-does-not-match");
        } else if (
          err.message ==
          "El título registrado está en Suspensión. Cuenta con otro titulo vigente?"
        ) {
          history.push("/RECHAZADO/pabs/suspended-account");
        } else if (
          err.message ==
          "El título registrado cuenta con un saldo mayor a $2,000.00. Cuenta con otro titulo pagado o saldo menor a $2,000.00?"
        ) {
          history.push("/RECHAZADO/pabs/major-balance");
        } else if (err.message == "Prestamo denegado.") {
          history.push("/RECHAZADO/pabs/loan-denied");
        } else {
          history.push("/RECHAZADO/pabs/not-found");
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      contract: "",
      city: "",
      knowAboutPercapita: ""
    },
    validationSchema: Yup.object({
      contract: 
      Yup.lazy((value: string) => {
              const patterns: RegExp[] = [
                  /^[a-zA-Z]\d{6}$/,          // Regla 1 caracter y 6 digitos X000001
                  /^\d[a-zA-Z]{2}\d{6}$/,        // Regla 1 dígito seguido de dos caracteres y 6 digitos 1AJ002556
                  /^[a-zA-Z]{2}\d{6}$/        // Regla dos caracteres y 6 digitos AX002556
              ];

              // recorre arreglod e expresiones regulares para validar si cumple con todas las validaciones
              for (const pattern of patterns) {
                  if (pattern.test(value)) {
                      return Yup.string().required().matches(pattern, 'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0)');
                  }
              }

              return Yup.string().test('invalid', 'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0)', () => false);
          }),
      city: Yup.string().required("Tienes que ingresar la calle."),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      apiClient
        .userContract(values.contract, values.city)
        .then((value) => {
          localStorage.setItem("upper", value.upper);
          localStorage.setItem("lower", value.lower);
          console.log("Fom 113", value);
          history.push("/PABS");
        })
        .catch((err) => {
          console.log("115 error", err);
          if (err.message == "Contract number was not found.") {
            history.push("/RECHAZADO/pabs/contract-not-found");
          } else if (err.message == "La ciudad elegida no existe.") {
            history.push("/RECHAZADO/pabs/city-not-found");
          } else if (
            err.message ==
            "El título registrado ya fue utilizado. Cuenta con otro titulo vigente?"
          ) {
            history.push("/RECHAZADO/pabs/validated-user");
          } else if (
            err.message ==
            "El nombre regisrado no coincide con el número de contrato. Favor de revisar los datos"
          ) {
            history.push("/RECHAZADO/pabs/user-does-not-match");
          } else if (
            err.message ==
            "El título registrado está en Suspensión. Cuenta con otro titulo vigente?"
          ) {
            history.push("/RECHAZADO/pabs/suspended-account");
          } else if (
            err.message ==
            "El título registrado cuenta con un saldo mayor a $2,000.00. Cuenta con otro titulo pagado o saldo menor a $2,000.00?"
          ) {
            history.push("/RECHAZADO/pabs/major-balance");
          } else if (err.message == "Prestamo denegado.") {
            history.push("/RECHAZADO/pabs/loan-denied");
          } else {
            history.push("/RECHAZADO/pabs/not-found");
          }
        });
    },
  });

  const checkFromRoute = async () => {
    var creditoLocal = "";
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    const from = query.get("from");
    if (from === "Login") {
      setFieldValue("phone", localStorage.getItem("cxdc"));
      setFieldValue("password", localStorage.getItem("fwcd"));
      setFieldValue("confirmPassword", localStorage.getItem("fwcd"));
      setFromLogin(true);
      setPhoneStatus(false);
    }
  };
  useEffect(() => {
    checkFromRoute();
  }, []);

  useEffect(() =>{
  const getProcessNomina = async () => {
    await apiClient
    .PabsValid()
    .then(async (res) => {
        if(res.validated) {
          history.push("/PABS")
        }
    })
    .catch((err) => {
    });
  }    
  getProcessNomina();
}, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-1"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-1">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row mb-1"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-1">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <img
              src={logo_pabs}
              alt="PRADO'S"
              className="img-fluid mb-4"
              width="70"
            />
            <h3 className="sol1-tittle">
              Bienvenido a tu solicitud <br />
              de préstamo con Título PABS
            </h3>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step-blue">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center mt-2 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            {/* {fromLogin === false ?  (
                            <div className="row">
                            <div className="col-6 col-sm-6">
                                            <h6 className="text-grey">Antes de continuar, vamos a validar tu número de teléfono. <br /><br /> <br />    </h6>
                                            </div>
                                        <div className="col-6 col-sm-6">
                                                <label htmlFor="phone" className="form-label sol1-tittle">Número de celular *</label>
                                                <input 
                                                    type="number" 
                                                    className={errors.phone ? "form-control  is-invalid" : "form-control"}
                                                    id="phone" 
                                                    aria-describedby="emailHelp"
                                                    onChange={ handleChangePhone}
                                                    value={values.phone}
                                                    onBlur={handleChangePhoneBlur}
                                                    />
                                                    {errors.phone && (
                                                        <div className="invalid-feedback">
                                                        Ingresa un teléfono válido
                                                    </div>
                                                    )}  
                                            </div>
                                            <div className="col-6 col-sm-6">
                                                <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                                <input 
                                                    type={shown ? "text" : "password"}
                                                    className={errors.password ? "form-control  is-invalid" : "form-control"}
                                                    id="password" 
                                                    aria-describedby="emailHelp"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                    autoComplete="false"
                                                    />
                                            
                                        </div>
                                        
                                            <div className="col-6 col-sm-6">
                                                <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                                <input 
                                                    type={shown ? "text" : "password"}
                                                    className={errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                                    id="confirmPassword" 
                                                    aria-describedby="emailHelp"
                                                    onChange={handleChange}
                                                    value={values.confirmPassword}
                                                    onBlur={handleBlurPassword}
                                                    />
                                                    {errors.confirmPassword && values.confirmPassword.length   >=  values.password.length  && (values.password.length > 0) && (
                                                        <div className="invalid-feedback">
                                                        Las contraseñas no coinciden
                                                    </div>
                                                    )}
                                                    <a href="/login" >
                                                        <span className="pt-0 rBlack">¿Ya tienes una cuenta?</span>
                                                        </a>
                                            </div>
                                    </div>
                        ) : ''} */}

            <hr className="mt-4 mb-4" />
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad donde solicitaste tu titulo*
                    </label>
                    <select
                      className={
                        errors.city && touched.city
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="city"
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                    >
                      <option selected>Ciudad</option>
                      {ciudades.map((ciudad) => (
                        <option key={ciudad.id} value={ciudad.name}>
                          {ciudad.nameDescription}
                        </option>
                      ))}
                    </select>
                    {errors.city && touched.city && (
                      <div className="invalid-feedback">Elige una ciudad</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="contract"
                      className="form-label sol1-tittle"
                    >
                      Número de contrato PABS*
                      <span style={{ fontSize: "12px"}} className="text-danger">Anota los 7, 8 o 9 caracteres que conforman tu número de título. Ej: XYZ000099, XY999999 o X009999</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.contract && touched.contract
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="contract"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "contract",
                          validateString(value.trim().toUpperCase())
                        );
                      }}
                      value={values.contract}
                      onBlur={handleBlur}
                      maxLength={9}
                    />
                    {errors.contract && touched.contract && (
                      <div className="invalid-feedback">
                        {errors.contract}
                      </div>
                    )}
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastFName && touched.lastFName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastFName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.lastFName}
                      onBlur={handleBlur}
                      disabled
                    />
                    {errors.lastFName && touched.lastFName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido paterno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastMName && touched.lastMName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastMName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.lastMName}
                      onBlur={handleBlur}
                      disabled
                    />
                    {errors.lastMName && touched.lastMName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido materno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Nombre*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.firstName && touched.firstName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="firstName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.firstName}
                      onBlur={handleBlur}
                      disabled
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="invalid-feedback">Elige una ciudad</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre
                    </label>
                    <input
                      type="input"
                      className={
                        errors.secondName && touched.secondName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="secondName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.secondName}
                      onBlur={handleBlur}
                      disabled
                    />
                    {errors.secondName && touched.secondName && (
                      <div className="invalid-feedback">
                        Ingresa tu segundo nombre
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-1">
                    <label
                      htmlFor="birthDate"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    <input
                      type="date"
                      className={
                        errors.dateOfBirth && touched.dateOfBirth
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="dateOfBirth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.dateOfBirth}
                      onBlur={handleBlur}
                      disabled
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                disabled
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            disabled
                                                            name="dateOfBirth"
                                                            onChange={handleChange}
                                                            value={values.dateOfBirth}
                                                            onBlur={handleBlur}
                                                            
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider> */}
                    {errors.dateOfBirth && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido materno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-4">
                    <label htmlFor="gender" className="form-label sol1-tittle">
                      Género *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender && touched.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="femenino"
                        checked={values.gender === "femenino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="masculino"
                        checked={values.gender === "masculino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Hombre
                      </label>
                    </div>
                    {errors.gender && touched.gender && (
                      <div className="invalid-feedback">Elige un género</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={
                        errors.email && touched.email
                          ? " form-control  is-invalid"
                          : "form-control"
                      }
                      id="email"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      disabled
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback">
                        Ingresa tu correo electrónico
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="knowAboutPercapita" className="form-label sol1-tittle">
                      ¿Cómo conoció Percapita?*
                    </label>
                    <select
                      className={
                        errors.knowAboutPercapita && touched.knowAboutPercapita
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="knowAboutPercapita"
                      name="knowAboutPercapita"
                      onChange={handleChangeKnowAboutPercapita}
                      value={values.knowAboutPercapita}
                      onBlur={handleBlur}
                    >
                      <option selected>Selecciona una opción:</option>
                      <option value="RECOMENDACIÓN">RECOMENDACIÓN</option>
                      <option value="REDES SOCIALES">REDES SOCIALES</option>
                      <option value="GOOGLE">GOOGLE</option>
                      <option value="WHATSAPP">WHATSAPP</option>
                      <option value="SMS">SMS</option>
                      <option value="VENDEDOR">VENDEDOR</option>
                      <option value="RECUPERADOR PABS">RECUPERADOR PABS</option>
                      <option value="COBRANZA PABS">COBRANZA PABS</option>
                      <option value="OTRO">OTRO</option>
                    </select>
                    {errors.knowAboutPercapita && touched.knowAboutPercapita && (
                      <div className="invalid-feedback">Selecciona una opción</div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="d-flex justify-content-around mt-3">
                  <button 
                    type="submit" 
                    className="btn btn-shadow"
                    disabled={!isValid}
                    >
                    Continuar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
}
