import "./uploadBox.css";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import Compressor from 'compressorjs';
export default function UploadBox({
  image,
  descripton,
  fileSetter,
  fileKey,
  callback
}) {

  /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
  const onDrop = useCallback((acceptedFiles) => {
    // alert('drop')
    const image = acceptedFiles[0];
    console.log(image.size);
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        console.log(compressedResult)
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.        
        fileSetter(compressedResult)
      },
    });
    //fileSetter(acceptedFiles[0])
    setFileName(acceptedFiles[0].name)

    callback();
  }, []);

  const [fileName, setFileName] = useState();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // const handleCompressedUpload = (e) => {
  //   const image = e.target.files[0];
  //   new Compressor(image, {
  //     quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //     success: (compressedResult) => {
  //       // compressedResult has the compressed file.
  //       // Use the compressed file to upload the images to your server.        
  //       // setCompressedFile(res)
  //     },
  //   });
  // };

  return (
    // <div className="wrapper">
    //   <div className="uploadBox" {...getRootProps()}>

    //     {
    //       fileName ?
    //         <strong style={{fontSize: 15}}>{fileName}</strong> :
    //         <>
    //           <img src={image} alt="upload icon" />
    //           <span>
    //             <strong style={{fontSize: 15}}>Elige un archivo</strong>
    //             <input {...getInputProps()} /> <br />
    //             {isDragActive ? (
    //               <strong style={{fontSize: 15}}>Dejalo caer aqui</strong>
    //             ) : (
    //               <strong style={{fontSize: 15}}>o arrastralo hasta aqui</strong>
    //             )}
    //           </span>
    //         </>

    //     }

    //   </div>
    //   <strong style={{fontSize: 15}}>{descripton}</strong>
    // </div>
    // <div className="mb-3" {...getRootProps()} >
    //                                     <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
    //                                         {descripton}</label>
    //                                     <div className="fileupload fileupload-new" data-provides="fileupload">
    //                                         <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
    //                                             <span className="fileupload-exists">Cambiar</span>
    //                                             <input
    //                                                 type="file"
    //                                                 //id={propKey}
    //                                                 {...getInputProps()}
    //                                             />
    //                                         </span>
    //                                         <span className="fileupload-preview"></span>
    //                                         <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
    //                                     </div>
    //                                     <p></p>
    // </div>
//     <>
//             <div className="d-none d-sm-none d-md-block d-flex">
//                 <div className="mb-3 justify-content-center" {...getRootProps()}>
//                     <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />{descripton}</label>
//                     <div className="fileupload fileupload-new" data-provides="fileupload">
//                         <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}></div>
//                         <div>
//                             <span className="btn btn-file">
//                                 <span className="fileupload-new">Seleccione un archivo</span>
//                                 <span className="fileupload-exists">Cambiar</span>
//                                 <input
//                                     type="file"
//                                     //id={propKey}
//                                     {...getInputProps()}
//                                 />
//                                 </span>
//                             <a  href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
//                         </div>
//                     </div>
//                 </div>
//             </div>
// <div className="d-block d-sm-block d-md-none p-0 m-0">
//    <div className="mb-3" {...getRootProps()}>
//     <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de {descripton}</label>
//     <div className="fileupload fileupload-new" data-provides="fileupload">
//         <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}>
//     </div>
//     <div className="d-flex justify-content-center mt-2 mb-4">
//     <span className="btn btn-file">
//         <span className="fileupload-new">Seleccione un archivo</span>
//         <span className="fileupload-exists">Cambiar</span>
//         <input
//         type="file"
//         //id={propKey}
//         {...getInputProps()}
//         />
//         </span>
//         <a href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
//     </div>
    
//     </div>
//     </div>
// </div>
//         </>
<div className="mb-3" {...getRootProps()}>
    <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de <br />{descripton}</label>
    <div className="fileupload fileupload-new" data-provides="fileupload">
        <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}>
    </div>
    <div className="d-flex justify-content-center mt-2 mb-2">
    <span className="btn btn-file">
        <span className="fileupload-new">Seleccione un archivo</span>
        <span className="fileupload-exists">Cambiar</span>
        <input
        type="file"
        //id={propKey}
        {...getInputProps()}
        />
        </span>
        <a href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
    </div>
    
    </div>
    </div>
  );
}
