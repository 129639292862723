import Login from "../components/portal/page/Login";
import LoginDiremovil from "../components/portal/page/login.diremovil";
import Solicitud1 from "../components/portal/personal/Solicitud1";
import SolicitudMontoDiremovil from "../components/portal/diremovil/Solicitud1";
import Solicitud2 from "../components/portal/personal/Solicitud2";
import Solicitud3 from "../components/portal/personal/Solicitud3";
import InicioDiremovil from "../components/portal/diremovil/Solicitud3";
import Solicitud6 from "../components/portal/personal/Solicitud6";
import Solicitud7 from "../components/portal/personal/Solicitud7";
import Solicitud8 from "../components/portal/personal/Solicitud8";
import Solicitud9 from "../components/portal/personal/Solicitud9";
import Solicitud10 from "../components/portal/personal/Solicitud10";
import Solicitud11 from "../components/portal/personal/Solicitud11";
import Solicitud12 from "../components/portal/personal/Solicitud12";
import Solicitud13 from "../components/portal/personal/Solicitud13";
import Solicitud14 from "../components/portal/personal/Solicitud14";
import Titulo2 from "../components/portal/pabs/Pabs_2";
import ValidacionPabs from "../components/portal/pabs/ValidationPabs";
import Cuenta1 from "../components/portal/cuenta/Cuenta1";
import Cuenta2 from "../components/portal/cuenta/Cuenta2";
import Cuenta3 from "../components/portal/cuenta/Cuenta4";
import AvisoPrivacidad from "../components/portal/page/avisoPrivacidad";
import Configuracion from "../components/portal/personal/SolicutdConfiguration";
import ResetPassword from "../components/portal/page/resetPassword";
import ResetPasswordCode from "../components/portal/page/resetPasswordCode";
import Blog from "../components/portal/page/Blog";
import CatYComisiones from "../components/portal/page/CatYComisiones";
import BlogD from "../components/portal/page/BlogD";
import Contacto from "../components/portal/page/Contacto";
import Financiamiento from "../components/portal/page/Financiamiento";
import Nosotros from "../components/portal/page/Nosotros";
import Buro from "../components/portal/page/Buro";
import TerminosCondiciones from "../components/portal/page/TerminosCondiciones";
import Faq from "../components/portal/page/Faq";
import DerechoArco from "../components/portal/page/DerechoArco";
import {LoginCambaceo} from '../components/loading.component';
import LoginWithoutOption from "../components/portal/page/LoginWithoutOption"
import Solicitud3Geolocation from "../components/portal/personal/Solicitud3Geolocation";
import CreditoPersonal from "../components/portal/page/CreditoPersonal";
import RegistroDiremovilPromotion from "../components/portal/diremovil_promotion/RegistroDiremovilPromotion"
const rutasPublicas = [
  { path: "/login", component: Login },
  { path: "/aviso-de-privacidad", component: AvisoPrivacidad },
  { path: "/solicitud-monto/:type", component: Solicitud1 },
  { path: "/solicitud-monto-diremovil", component: SolicitudMontoDiremovil },
  { path: "/solicitud-datos", component: Solicitud2 },
  { path: "/Titulo", component: ValidacionPabs },
  { path: "/Personal", component: Solicitud3 },
  { path: "/Diremovil", component: InicioDiremovil },
  { path: "/solicitud-datos5", component: Solicitud6 },
  { path: "/solicitud-datos6", component: Solicitud7 },
  { path: "/solicitud-datos7", component: Solicitud8 },
  { path: "/solicitud-datos8", component: Solicitud9 },
  { path: "/solicitud-datos9", component: Solicitud10 },
  { path: "/solicitud-datos10", component: Solicitud11 },
  { path: "/solicitud-datos11", component: Solicitud12 },
  { path: "/solicitud-datos12", component: Solicitud13 },
  { path: "/solicitud-datos13", component: Solicitud14 },
  { path: "/solicitud-datos15", component: Titulo2 },
  { path: "/cuenta1", component: Cuenta1 },
  { path: "/cuenta2", component: Cuenta2 },
  { path: "/cuenta3", component: Cuenta3 },
  { path: "/configuracion", component: Configuracion },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password-code", component: ResetPasswordCode },
  { path: "/Blog", component: Blog },
  { path: "/BlogD", component: BlogD },
  { path: "/Cat-y-comisiones", component: CatYComisiones },
  { path: "/Contacto", component: Contacto },
  { path: "/Financiamiento", component: Financiamiento },
  { path: "/Nosotros", component: Nosotros },
  { path: "/Buro", component: Buro },
  { path: "/Terminos-y-condiciones", component: TerminosCondiciones },
  { path: "/Faq", component: Faq },
  { path: "/Derechos-arco", component: DerechoArco },
  { path: "/login-cambaceo/:userId/:nameUser/:type", component: LoginCambaceo},
  { path: "/login-diremovil", component: LoginDiremovil},
  { path: "/option-login", component: LoginWithoutOption},
  { path: "/PersonalGeolocation", component: Solicitud3Geolocation },
  { path: "/Credito-personal", component: CreditoPersonal },
  { path: "/DiremovilPromotion", component: RegistroDiremovilPromotion },
];

export default rutasPublicas;
