import React from 'react'
import icon_percapita from '../../../img/icon-percapita.png';
import  Footer from '../../front/FooterI';
import Navbar from '../../front/NavbarI';
import circulo_phone from '../../../img/circulo-phone.png';
import circulo_whats from '../../../img/circulo-whats.png';
import circulo_ubi from '../../../img/circulo-ubi.png';
import circulo_mail from '../../../img/circulo-mail.png';
import {motion} from "framer-motion";

export default function Contacto() {
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <div class="bg-contacto">
                <div class="container">
                    <div class="row">
                        <div class="text-center txt-contacto">
                            <img src={icon_percapita} alt="PERCAPITA" class="img-fluid  icono-headers" />
                            <h1 class="rBlack display-4 mt-0">Contacto</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container py-5">
                <div class="row pb-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-md-lg-11">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                            <div class="col-12 col-md-12 col-md-12 col-lg-5">
                                <div class="mb-3">
                                    <h1 class="rLight txt-azul-marino display-5 mb-0">¿Tienes alguna duda?</h1>
                                    <h1 class="rBlack txt-azul-marino display-4 mt-0">Contáctanos</h1>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_phone} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="tel:+523336168059" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-3 txt-gris">33 3616-8059</h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_whats} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="https://api.whatsapp.com/send?phone=523315206918" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-3 txt-gris"></h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_mail} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="malto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-2 txt-gris">contacto@percapita.mx</h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="d-none d-sm-none d-md-block">
                                    <div class="txt-direccion">
                                        <img src={circulo_ubi} alt="Contactanos" class="img-fluid icon-contact mb-4" />
                                        <aside>
                                            <a href="https://goo.gl/maps/XAg38oJRqhg9wSV4A" class="a_none_style txt-gris">
                                                <h5 class="oRegular txt-gris pt-2">Av. Miguel Hidalgo y Costilla 1333, </h5>
                                                <h5 class="oRegular txt-gris">Col Americana, Zona Centro, 44160</h5>
                                                <h5 class="oRegular txt-gris">Guadalajara, Jal.</h5>
                                            </a>
                                        </aside>
                                    </div>
                                </div>
                                <div class="d-block d-sm-block d-md-none">
                                    <div class="">
                                        <img src={circulo_ubi} alt="Contactanos" class="img-fluid icon-contact mb-4" />
                                        <aside>
                                            <a href="https://goo.gl/maps/XAg38oJRqhg9wSV4A" class="a_none_style txt-gris">
                                                <h5 class="oRegular txt-gris pt-2">Av. Miguel Hidalgo y Costilla 1333,
                                                    Col Americana, Zona Centro, 44160
                                                    Guadalajara, Jal.</h5>
                                            </a>
                                        </aside>
                                    </div>
                                </div>


                            </div>
                            <div class="col-12 col-md-12 col-md-12 col-lg-6 pt-5">
                                <div class="mx-2">
                                    <div class="bg-gris py-5">
                                        <div class="mx-5">
                                            <form class="form-contacto">
                                                <div class="mb-4">
                                                    <label for="exampleInputEmail1" class="form-label">NOMBRE</label>
                                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="exampleInputEmail1" class="form-label">EMAIL</label>
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="exampleInputEmail1" class="form-label">TELÉFONO</label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="exampleInputEmail1" class="form-label">CIUDAD</label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="exampleInputEmail1" class="form-label">ESTADO</label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-5">
                                                    <label for="floatingTextarea2">MENSAJE</label>
                                                    <textarea class="form-control" id="floatingTextarea2" ></textarea>
                                                </div>
                                                <div class="mb-3 text-end">
                                                    <a href="#" class="btn btn-primary btn-solicitar">
                                                        <h6 class="pt-1 rBlack">Enviar</h6>
                                                    </a>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="container-fluid p-0">
                <div class="row">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.822743056121!2d-103.36793048477467!3d20.67678928619018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae1b45d64001%3A0xe525da8046177a2b!2sAv.%20Miguel%20Hidalgo%20y%20Costilla%201333%2C%20Col%20Americana%2C%20Zona%20Centro%2C%2044160%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1666886732327!5m2!1ses-419!2smx" width="600" height="750" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
