import React, { useEffect, useState, useCallback, useRef } from "react";
import AlertMessage from "../../../alert.component";
import ApiClient, { IAddress } from "../../../../services/apiclient";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../../img/logo_blanco.png";
import flechai from "../../../../img/flechai.png";
import flechad from "../../../../img/flechaD.png";
import { motion } from "framer-motion";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";
import { validateString } from "../../../../utils/StringValidator";
export default function Domicilio({
  userData,
  setUserData,
  nextStep,
  prevStep,
}) {
  const apiClient = ApiClient.getInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const [zipData, setZipData] = useState<IAddress>({
    ciudad: "",
    estado: "",
    municipio: "",
    colonias: [],
  });
  const [zipDataCobranza, setZipDataCobranza] = useState<IAddress>({
    ciudad: "",
    estado: "",
    municipio: "",
    colonias: [],
  });

  const [zip, setZip] = useState({
    postalCode: "",
  });

  const handleClose = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    //if(userData.address.postalCode){
    //   apiClient
    //       .getAdrressFromZipCode(userData.address.postalCode)
    //       .then((data) => {
    //         console.log(data);
    //         setZip(userData.address.postalCode);
    //         setZipData(data);
    //       })
    //       .catch((err) => console.error(err));

    // }
    console.log(userData);
  }, []);

  const formikRef = React.useRef(null);

  const inputChange = useCallback((e) => {
    if (e.target.name === "address.postalCode") {
      if (e.target.value.length !== 5) {
        formikRef.current?.handleChange(e);
        return;
      }

      apiClient
        .getAdrressFromZipCode(e.target.value)
        .then((data) => {
          setZip(e.target.value);
          setZipData(data);
        })
        .catch((err) => console.error(err));
    }

    formikRef.current?.handleChange(e);
  }, []);

  const onsubmitForm = (values: any, actions: any) => {
    // values.userData.address?.city = zipData.ciudad || zipData.municipio;
    // values.userData.address?.state = zipData.estado;
    // values.userData.address?.country = "Mexico";
    // if(!values.userData.address?.antiquityMonth){
    //   values.userData.address?.antiquityMonth = 0;
    // }
    // setUserData(values);
    // nextStep();
  };

  const validationSchema = Yup.object().shape({
    address: Yup.object({
      // street: Yup.string().required("Requerido"),
      // interiorNumber: Yup.string(),
      // exteriorNumber: Yup.string().required("Requerido"),
      // suburb: Yup.string().required("Requerido"),
      // postalCode: Yup.string().required("Requerido"),
      // homePhone: Yup.string().required("Requerido"),
      // antiquity: Yup.number().nullable().required("Requerido"),
      // antiquityMonth: Yup.number().nullable().required("Requerido"),
      // propertyType: Yup.string().required("Requerido"),
    }),
  });
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      street: "",
      interiorNumber: "",
      exteriorNumber: "",
      suburb: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      homePhone: "",
      antiquity: 0,
      antiquityMonth: 0,
      propertyType: "",
      address:"",
      
      streetCobranza: "",
      interiorNumberCobranza: "",
      exteriorNumberCobranza: "",
      suburbCobranza: "",
      postalCodeCobranza: "",
      cityCobranza: "",
      stateCobranza: "",
      countryCobranza: "",
      addressCobranza:"",
    },
    validationSchema: Yup.object().shape({
      street: Yup.string().required("Requerido"),
      interiorNumber: Yup.string(),
      exteriorNumber: Yup.string().required("Requerido"),
      suburb: Yup.string().required("Requerido"),
      postalCode: Yup.string().required("Requerido"),
      homePhone: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      address: Yup.string().required("Requerido"),

      streetCobranza: Yup.string().required("Requerido"),
      interiorNumberCobranza: Yup.string(),
      exteriorNumberCobranza: Yup.string().required("Requerido"),
      suburbCobranza: Yup.string().required("Requerido"),
      postalCodeCobranza: Yup.string().required("Requerido"),
      addressCobranza: Yup.string().required("Requerido"),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      values.antiquity = Number(values.antiquity);
      userData.address = values;

      if (!values.antiquityMonth) {
        values.antiquityMonth = 0;
      }
      if (localStorage.getItem("preCreditoLocal")) {
        var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
        if (
          credito.idProcessMarketing !== "" &&
          credito.typeLoanMarketing === "Titulo"
        ) {
          var dd = {
            processName: "address-form-finished",
          };
          updatePreCredito({
            ...preCredito,
            stepMarketing: "address-form-finished",
          });
          updateMarketing(credito.idProcessMarketing, dd);
        }
      }
      setUserData(userData);
      nextStep();
    },
  });
  function handleChangePhone(event: any) {
    //var regExp = /[a-zA-Z+-/*. ]/g;
    var regExp = /^[0-9]*$/;
                
    if(regExp.test(event.target.value)){
      console.log("if 1");
      if ( event.target.value.length <= 10 ) {
        console.log("if 2");
        setFieldValue("homePhone", event.target.value.toString());
      } else {
        console.log("else 2");
      }
    } else {
      console.log("else 1");
    }
  }

  function handleSameAddress(e) {
    console.log(e.target.checked);
    let isChecked = e.target.checked;
    if ( isChecked ) {
      
      setFieldValue("streetCobranza", values.street);
      setFieldValue("exteriorNumberCobranza", values.exteriorNumber);
      setFieldValue("interiorNumberCobranza", values.interiorNumber);
      setFieldValue("postalCodeCobranza", values.postalCode);
      setFieldValue("addressCobranza", values.address);
      setFieldValue("suburbCobranza", values.suburb);
      setFieldValue("cityCobranza", values.city);
      setFieldValue("stateCobranza", values.state);
      setFieldValue("countryCobranza", values.country);
    } else {
      setFieldValue("streetCobranza", '');
      setFieldValue("exteriorNumberCobranza", '');
      setFieldValue("interiorNumberCobranza", '');
      setFieldValue("postalCodeCobranza", '');
      setFieldValue("addressCobranza",'');
      setFieldValue("suburbCobranza", '');
      setFieldValue("cityCobranza", '');
      setFieldValue("stateCobranza", '');
      setFieldValue("countryCobranza", '');
    }
    
  };

  const handleZipBlur = (event: React.FocusEvent) => {
    if (errors.postalCode) {
      setMessage("Debes introducir un código postal válido.");
      setSeverity("error");
      setOpenMessage(true);
      return;
    }

    apiClient
      .getAdrressFromZipCode(values.postalCode)
      .then((data) => {
        if ( data.estado != 'error' ) {
          console.log("codigo postal correcto");
          setFieldValue("state", data.estado);
          setFieldValue("city", data.ciudad || data.municipio);
          setFieldValue("country", "Mexico");
          setFieldValue("postalCode", values.postalCode);
          //setZip(values.userData.address?.postalCode.toString());
          setZipData(data);
        } else {
          console.log("codigo postal INcorrecto");

          setFieldValue("state", "");
          setFieldValue("city","");
          setFieldValue("country", "");
          setZipData({
            ciudad: "",
            estado: "",
            municipio: "",
            colonias: [],
          });

          setMessage("Debes introducir un código postal válido.");
          setSeverity("error");
          setOpenMessage(true);
        }
      })
      .catch((e) => {

        setFieldValue("state", "");
        setFieldValue("city","");
        setFieldValue("country", "");
        setZipData({
          ciudad: "",
          estado: "",
          municipio: "",
          colonias: [],
        });
        
        setMessage("Debes introducir un código postal válido.");
        setSeverity("error");
        setOpenMessage(true);
      });
  };

  const handleZipBlurCobranza = (event: React.FocusEvent) => {
    if (errors.postalCodeCobranza) {
      setMessage("Debes introducir un código postal válido.");
      setSeverity("error");
      setOpenMessage(true);
      return;
    }

    apiClient
      .getAdrressFromZipCode(values.postalCodeCobranza)
      .then((data) => {
        if ( data.estado != 'error' ) {
          setFieldValue("stateCobranza", data.estado);
          setFieldValue("cityCobranza", data.ciudad || data.municipio);
          setFieldValue("countryCobranza", "Mexico");
          setFieldValue("postalCodeCobranza", values.postalCodeCobranza);
          //setZip(values.userData.address?.postalCode.toString());
          setZipDataCobranza(data);
        } else {

          setFieldValue("stateCobranza", "");
          setFieldValue("cityCobranza","");
          setFieldValue("countryCobranza", "");
          setZipDataCobranza({
            ciudad: "",
            estado: "",
            municipio: "",
            colonias: [],
          });

          setMessage("Debes introducir un código postal válido.");
          setSeverity("error");
          setOpenMessage(true);
        }
      })
      .catch((e) => {
        setMessage("Debes introducir un código postal válido.");
        setSeverity("error");
        setOpenMessage(true);
      });
  };

  const handleBlurI = (event: any) => {};

  useEffect(() => {
    const setPostalCode = () => {
      if (values.postalCode.trim().length == 5) {
        const postalCode = values.postalCode.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            if ( data.estado != 'error' ) {
              setFieldValue("state", data.estado);
              setFieldValue("city", data.ciudad || data.municipio);
              setFieldValue("postalCode", postalCode);
              setFieldValue("country", "Mexico");
              setZipData(data);
            } else {

              setFieldValue("state", "");
              setFieldValue("city","");
              setFieldValue("country", "");
              setZipData({
                ciudad: "",
                estado: "",
                municipio: "",
                colonias: [],
              });

              setMessage("Debes introducir un código postal válido.");
              setSeverity("error");
              setOpenMessage(true);
            }
          })
          .catch((e) => console.log(e));
      } else {
        setFieldValue("state", "");
        setFieldValue("city","");
        setFieldValue("country", "");
        setZipData({
          ciudad: "",
          estado: "",
          municipio: "",
          colonias: [],
        });
      }
    };

    if (values.postalCode !== "") {
      setPostalCode();
    }
  }, [values.postalCode]);

  useEffect(() => {
    const setPostalCodeCobranza = () => {
      if (values.postalCodeCobranza.trim().length == 5) {
        const postalCode = values.postalCodeCobranza.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            if ( data.estado != 'error' ) {
              setFieldValue("stateCobranza", data.estado);
              setFieldValue("cityCobranza", data.ciudad || data.municipio);
              setFieldValue("postalCodeCobranza", values.postalCodeCobranza);
              setFieldValue("countryCobranza", "Mexico");
              setZipDataCobranza(data);
            } else {

              setFieldValue("stateCobranza", "");
              setFieldValue("cityCobranza","");
              setFieldValue("countryCobranza", "");
              setZipDataCobranza({
                ciudad: "",
                estado: "",
                municipio: "",
                colonias: [],
              });

              setMessage("Debes introducir un código postal válido.");
              setSeverity("error");
              setOpenMessage(true);
            }
          })
          .catch((e) => console.log(e));
      } else {
        setFieldValue("stateCobranza", "");
        setFieldValue("cityCobranza","");
        setFieldValue("countryCobranza", "");
        setZipDataCobranza({
          ciudad: "",
          estado: "",
          municipio: "",
          colonias: [],
        });
      }
    };

    if (values.postalCodeCobranza !== "") {
      setPostalCodeCobranza();
    }
  }, [values.postalCodeCobranza]);

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "address-form-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "address-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "address-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  useEffect(() => {
    const preinfo = async () => {
      await apiClient.getUserPreInfo().then((data) => {
        if (!data.error) {
          setFieldValue("street", data.street);
          setFieldValue("exteriorNumber", data.exteriorNumber);
          setFieldValue("interiorNumber", data.interiorNumber);
          setFieldValue("propertyType", data.propertyType);
          setFieldValue("postalCode", data.postalCode);
          setFieldValue("antiquity", data.antiquity);
          setFieldValue("antiquityMonth", data.antiquityMonth);
          setFieldValue("homePhone", data.homePhone);
        }
      });
    };
    preinfo();
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      {/* <Formik
            initialValues={userData}
            onSubmit={onsubmitForm}
            validationSchema={validationSchema}
            innerRef={formikRef}
          >
            {({ handleSubmit, errors, touched, handleChange, values, handleBlur}) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }} 
              >*/}
      <div className="d-flex justify-content-center mt-4 container">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <motion.div
            initial={{ y: "50%", opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="container"
          >
            <p className="txt-blue-strong">
              Escriibe tu dirección como aparece en tu comprobante de domicilio vigente
            </p>
            <hr className="mt-2 mb-2" />
            <p className="txt-blue-strong">
              Domicilio de casa
            </p>
            <form id="div-perfil" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="street" className="form-label sol1-tittle">
                  Calle *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "street",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.street}
                  onBlur={handleBlur}
                  className={
                    errors.street ? " form-control is-invalid" : "form-control"
                  }
                  id="street"
                  name="street"
                />
                {errors.street && (
                  <div className="invalid-feedback">
                    El campo calle es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exteriorNumber"
                      className="form-label sol1-tittle"
                    >
                      Numero exterior *
                    </label>
                    <input
                      type="input"
                      id="exteriorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "exteriorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.exteriorNumber}
                      onBlur={handleBlur}
                      className={
                        errors.exteriorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.exteriorNumber && (
                      <div className="invalid-feedback">
                        El número exterior es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="interiorNumber"
                      className="form-label sol1-tittle"
                    >
                      Número interior
                    </label>
                    <input
                      type="input"
                      id="interiorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "interiorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.interiorNumber}
                      onBlur={handleBlur}
                      className={
                        errors.interiorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label sol1-tittle">
                  Entre Calles *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "address",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.address}
                  onBlur={handleBlur}
                  className={
                    errors.address ? " form-control is-invalid" : "form-control"
                  }
                  id="address"
                  name="address"
                />
                {errors.address && (
                  <div className="invalid-feedback">
                    El campo entre calles es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="postalCode"
                      className="form-label sol1-tittle"
                    >
                      Código postal *
                    </label>
                    <input
                      type="input"
                      id="postalCode"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 5) {
                          setFieldValue(
                            "postalCode",
                            value.trim()
                          )
                        }
                      }}
                      value={values.postalCode}
                      onBlur={handleZipBlur}
                      className={
                        errors.postalCode
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.postalCode && (
                      <div className="invalid-feedback">
                        El código postal es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="suburb" className="form-label sol1-tittle">
                      Colonia *
                    </label>
                    <select
                      id="suburb"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.suburb}
                      onBlur={handleBlur}
                      className={
                        errors.suburb
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {zipData.colonias.map((colonia) => (
                        <option key={colonia.colonia} value={colonia.colonia}>
                          {colonia.colonia}
                        </option>
                      ))}
                    </select>
                    {errors.suburb && (
                      <div className="invalid-feedback">
                        El campo colonia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad *
                    </label>
                    <input
                      type="input"
                      id="city"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                      className={
                        errors.city
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label sol1-tittle">
                      Pais *
                    </label>
                    <input
                      type="input"
                      id="country"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.country}
                      onBlur={handleBlur}
                      className={
                        errors.country
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado *
                    </label>
                    <input
                      type="input"
                      id="state"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      className={
                        errors.state
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="homePhone"
                      className="form-label sol1-tittle"
                    >
                      Teléfono fijo
                    </label>
                    <input
                      type="input"
                      id="homePhone"
                      aria-describedby="emailHelp"
                      onChange={handleChangePhone}
                      value={values.homePhone}
                      onBlur={handleBlur}
                      className={
                        errors.homePhone
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.homePhone && (
                      <div className="invalid-feedback">
                        El campo teléfono es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquity"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <select
                      id="antiquity"
                      name="antiquity"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquity}
                      onBlur={handleBlur}
                      className={
                        errors.antiquity
                          ? "form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="0">Menos de 6 meses</option>
                      <option value="1">1 año</option>
                      <option value="2">2 años</option>
                      <option value="3">3 años</option>
                      <option value="4">4 años</option>
                      <option value="5">5 años</option>
                      <option value="6">Mas de 6 años</option>
                    </select>
                    {errors.street && (
                      <div className="invalid-feedback">
                        El antigueada domicilio es requerido
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="propertyType"
                      className="form-label sol1-tittle"
                    >
                      Tipo de propiedad*
                    </label>
                    <select
                      className={
                        errors.propertyType
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      id="propertyType"
                      name="propertyType"
                      value={values.propertyType}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="PROPIA">Propia</option>
                      <option value="ALQUILER">Alquiler</option>
                      <option value="DESCONOCIDO">Familiar</option>
                      <option value="EMPRESA">Empresa</option>
                      <option value="FAMILIAR">Familiar</option>
                      <option value="PROPIETARIO">Propietario</option>
                      <option value="PROPIETARIO HIPOTECA">
                        Propietario hipoteca
                      </option>
                      <option value="SIN DOMICILIO PROPIO">
                        Sin domicilio propio
                      </option>
                    </select>
                    {errors.propertyType && (
                      <div className="invalid-feedback">
                        El campo tipo propiedad es requerido
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquityMonth"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <input
                      type="number"
                      placeholder="Meses"
                      id="antiquityMonth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquityMonth}
                      onBlur={handleBlur}
                      className={
                        errors.antiquityMonth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div> */}
              </div>
              <hr className="mt-2 mb-2" />
              <p className="txt-blue-strong">
                Domicilio de cobranza
                <span style={{ fontSize: "12px"}} className=""><br></br>*Si los abonos serán por transferencia o depósito agregar el mismo domicilio</span>
              </p>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-2">
                    <div className="form-check">
                      <input className="form-check-input checkbox-terms" type="checkbox" value="" id="checkSameAddress" onChange={(e) => handleSameAddress(e)} />
                      <label className="form-check-label text-check" htmlFor="checkSameAddress">
                          Mismo domicilio de casa
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="streetCobranza" className="form-label sol1-tittle">
                      Calle *
                    </label>
                    <input
                      type="input"
                      //id="street"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "streetCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.streetCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.streetCobranza ? " form-control is-invalid" : "form-control"
                      }
                      id="street"
                      name="street"
                    />
                    {errors.streetCobranza && (
                      <div className="invalid-feedback">
                        El campo calle es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exteriorNumberCobranza"
                      className="form-label sol1-tittle"
                    >
                      Numero exterior *
                    </label>
                    <input
                      type="input"
                      id="exteriorNumberCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "exteriorNumberCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.exteriorNumberCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.exteriorNumberCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.exteriorNumberCobranza && (
                      <div className="invalid-feedback">
                        El número exterior es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="interiorNumberCobranza"
                      className="form-label sol1-tittle"
                    >
                      Número interior
                    </label>
                    <input
                      type="input"
                      id="interiorNumberCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "interiorNumberCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.interiorNumberCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.interiorNumberCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="addressCobranza" className="form-label sol1-tittle">
                  Entre Calles *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "addressCobranza",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.addressCobranza}
                  onBlur={handleBlur}
                  className={
                    errors.addressCobranza ? " form-control is-invalid" : "form-control"
                  }
                  id="address"
                  name="address"
                />
                {errors.addressCobranza && (
                  <div className="invalid-feedback">
                    El campo entre calles es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="postalCodeCobranza"
                      className="form-label sol1-tittle"
                    >
                      Código postal *
                    </label>
                    <input
                      type="input"
                      id="postalCodeCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 5) {
                          setFieldValue(
                            "postalCodeCobranza",
                            value.trim()
                          )
                        }
                      }}
                      value={values.postalCodeCobranza}
                      onBlur={handleZipBlurCobranza}
                      className={
                        errors.postalCodeCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.postalCodeCobranza && (
                      <div className="invalid-feedback">
                        El código postal es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="suburbCobranza" className="form-label sol1-tittle">
                      Colonia *
                    </label>
                    <select
                      id="suburbCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.suburbCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.suburbCobranza
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {zipDataCobranza.colonias.map((colonia) => (
                        <option key={colonia.colonia} value={colonia.colonia}>
                          {colonia.colonia}
                        </option>
                      ))}
                    </select>
                    {errors.suburbCobranza && (
                      <div className="invalid-feedback">
                        El campo colonia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="cityCobranza" className="form-label sol1-tittle">
                      Ciudad *
                    </label>
                    <input
                      type="input"
                      id="cityCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.cityCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.cityCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="countryCobranza" className="form-label sol1-tittle">
                      Pais *
                    </label>
                    <input
                      type="input"
                      id="countryCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.countryCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.countryCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="stateCobranza" className="form-label sol1-tittle">
                      Estado *
                    </label>
                    <input
                      type="input"
                      id="stateCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.stateCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.stateCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquityMonth"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <input
                      type="number"
                      placeholder="Meses"
                      id="antiquityMonth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquityMonth}
                      onBlur={handleBlur}
                      className={
                        errors.antiquityMonth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {/* <div className="d-flex justify-content-center mt-5 mb-4">
                    <a
                      href="/solicitud/personal/5"
                      className="btn btn-shadow-2"
                    >
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button
                      type="submit"
                      className="btn btn-shadow-2"
                      disabled={!isValid}
                    >
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
      {/* </Form>
             )}
           </Formik> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
    </motion.div>
  );
}
