import File from '../../../img/credito_actual.png';
import upload from '../../../img/esfera_percapita.png';
import check from '../../../assets/check-mark.png';
import { useDropzone } from "react-dropzone";
import React, { SetStateAction, useCallback } from "react";
import { FileUpload } from '../../../interfaces/fileInterace';
import flechad from '../../../img/flechaD.png';
import Compressor from 'compressorjs';

interface UploadBoxParams {
    propKey: string,
    mimeType: string,
    setter: React.Dispatch<SetStateAction<File>>,
    message: string,
    message2: string,
    file: any
}

export default function UploadBox({
    mimeType,
    propKey,
    setter,
    message,
    message2,
    file
}) {


    /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
    const onDrop = useCallback((acceptedFiles) =>  {
        
        const img = acceptedFiles[0];
        console.log(img.size);
        new Compressor(img, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            console.log(compressedResult)
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.        
            setter({key: propKey, file: compressedResult})
          },
        });
        //setter({ key: propKey, file: acceptedFiles[0] });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: mimeType });

    return (
        // <>
        //     <div className="d-none d-sm-none d-md-block d-flex">
        //         <div className="mb-3 justify-content-center" {...getRootProps()}>
        //             <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tuuuuu <br />{message}</label>
        //             <div className="fileupload fileupload-new" data-provides="fileupload">
        //                 <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}></div>
        //                 <div>
        //                     <span className="btn btn-file">
        //                         <span className="fileupload-new">Seleccione un archivo</span>
        //                         <span className="fileupload-exists">Cambiar</span>
        //                         <input
        //                             type="file"
        //                             id={propKey}
        //                             {...getInputProps()}
        //                         />
        //                         </span>
        //                     <a href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
//<div >
   <div className="mb-3" {...getRootProps()}>
    <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de {message}</label>
    <div className="fileupload fileupload-new" data-provides="fileupload">
        <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}>
    </div>
    <div className="d-flex justify-content-center mt-2 mb-4">
    <span className="btn btn-file">
        <span className="fileupload-new">Seleccione un archivo</span>
        <span className="fileupload-exists">Cambiar</span>
        <input
        type="file"
        id={propKey}
        {...getInputProps()}
        />
        </span>
        <a href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
    </div>
    
    </div>
    </div>
//</div>
        // </> 
        
    )
}