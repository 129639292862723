import React, { useState, useEffect } from "react";
import ApiClient from "../../../services/apiclient";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HeaderDashboar from "./components/header.comoponent";
import NavDashboard from "./components/nav.component";
import Inicio from "./components/inicio.component";
import Credito from "./components/credito.component";
import EstadoCuenta from "./components/estadoCuenta.component";
import Datos from "./components/datos.component";
import { nextNav } from "../../../store/actions/navActions";
import { UserContext } from "../../../context/UserProvider";
import PopUpContrato from "../../front/popup/PopupContrato";
import FooterI from "../../front/FooterI";
import logo_balnco from "../../../img/logo_blanco.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import AlertAccount from "./components/AlertAccount"
const Cuenta = ({ nav, nextNav }) => {
  const apiClient = ApiClient.getInstance();
  const currentUserId = apiClient.getUserId();
  const [numberPage, setNumberPage] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [stepLoaded, setStepLoaded] = useState(false);
  const [test, setTest] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [creditType, setCreditType] = useState("");
  const [stp, setStp] = useState({
    cuentaBeneficiario: "",
    institucionContraparte: "",
    isClave: "",
    isCheque: "",
  });
  const { userMain, updateUser } = React.useContext(UserContext);
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    console.log(credit);
    if(credit !== null){
      setAmount(credit.amount);
      setStatus(credit.status);
      setCreditType(activeCredit.creditType);
      let data = {
        cuentaBeneficiario: credit.cuentaBeneficiario,
        institucionContraparte: credit.institucionContraparte,
        isClave: credit.isClave,
        isCheque: credit.isCheque,
      };
      setStp(data);
      console.log(credit);
      return activeCredit;
    } else {
      alert('not active loan')
    }
  };
  useEffect(() => {
    checkActiveCredit();
  }, []);

  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [today, setToday] = React.useState<string>(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  const getValidationTerms = () => {
    apiClient
      .getValidationTerms()
      .then((resp) => {
        const { loanActive, acceptedContract } = resp;
        console.log("LOoan ACCCTIBVE ", resp);
        if (loanActive === true && acceptedContract === false) {
          setPopupVisibility(true);
        } else if (loanActive === true && acceptedContract === true) {
          history.push("/dashboard");
        } else {
          setPopupVisibility(false);
        }
      })
      .catch((err) => {
        if (err.message.includes("Entity not found: User with id")) {
          localStorage.clear();
          history.push("/login");
        }

        if (err.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      });
  };

  React.useEffect(() => {
    getValidationTerms();
    const date = new Date();

    setToday(
      date.toLocaleDateString("es-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  }, []);
  const setNextNav = () => {
    nextNav(0);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <HeaderDashboar />
      {isPopupVisible ? (
        <PopUpContrato
          isPopupVisible={isPopupVisible}
          setPopupVisibility={setPopupVisibility}
          setNextNav={setNextNav}
          setShowAlert={setShowAlert}
        />
      ) : null}
      {showAlert ? (<AlertAccount setShowAlert={setShowAlert} />) : null}
      <div className="">
        <div className="row">
          <NavDashboard />
          {nav === 0 ? (
            <Inicio amount={amount} status={status} stp={stp} setStp={setStp} mCreditType={creditType} />
          ) : null}
          {nav === 1 ? <Credito amount={amount} status={status} /> : null}
          {nav === 2 ? <EstadoCuenta /> : null}
          {nav === 3 ? <Datos /> : null}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid  img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <FooterI />
    </motion.div>
  );
};

const mapStateToProps = ({ navReducer }) => ({
  nav: navReducer?.nav ?? 1,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextNav,
    },
    dispatch
  );
};

          export default connect(mapStateToProps, mapDispatchToProps)(Cuenta);
