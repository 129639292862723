import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ApiClient, { Prospective } from "../../../services/apiclient";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import { useFormik } from "formik";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AlertMessage from "../../alert.component";
import { UserContext } from "../../../context/UserProvider";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import { motion } from "framer-motion";
import moment from "moment";
import Swal from "sweetalert2";
import { DiremovilContext } from "../../../context/UserDiremovilProvider";
import Modal from "@mui/material/Modal";
import logo from "../../../img/logo.png";
import { Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import {
  isBrowser,
  isDesktop,
  isMobile,
  isTablet,
  isSmartTV,
  isConsole,
  isWearable,
  isEmbedded,
  isMobileSafari,
  isChromium,
  isMobileOnly,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isYandex,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isElectron,
  isEdgeChromium,
  isLegacyEdge,
  isWindows,
  isMacOs,
  isMIUI,
  isSamsungBrowser,
} from "react-device-detect";
import intruction_web from "../../../img/instruccion_1_web.png";
import intruction_web_edge from "../../../img/instruccion_1_web_edge.png";
import intruction_web_fire from "../../../img/instruccion_1_web_fire.png";
import intruction_web_safari from "../../../img/instruccion_1_web_safari.jpg";
import intruction_mobile from "../../../img/instruccion_1_movil.jpg";
import intruction_mobile_smg from "../../../img/instruccion_1_movil_smg.jpg";
import intruction_mobile_safari from "../../../img/instruccion_1_movil_safari.jpg";
import mobile_step_1 from "../../../img/mobile_step_1.jpg";
import mobile_step_2 from "../../../img/mobile_step_2.jpg";
import mobile_step_3 from "../../../img/mobile_step_3.jpg";
import mobile_step_4 from "../../../img/mobile_step_4.jpg";
import mobile_step_1_smg from "../../../img/mobile_step_1_smg.jpg";
import mobile_step_2_smg from "../../../img/mobile_step_2_smg.jpg";
import mobile_step_3_smg from "../../../img/mobile_step_3_smg.jpg";
import step_web_1 from "../../../img/step_web_1.png";
import step_web_2 from "../../../img/step_web_2.png";
import step_web_3 from "../../../img/step_web_3.png";
import step_web_1_fire from "../../../img/step_web_1_fire.png";
import step_web_2_fire from "../../../img/step_web_2_fire.png";
import step_web_3_fire from "../../../img/step_web_3_fire.png";
import step_web_4_fire from "../../../img/step_web_4_fire.png";
import step_web_1_edge from "../../../img/step_web_1_edge.png";
import step_web_2_edge from "../../../img/step_web_2_edge.png";
import step_web_3_edge from "../../../img/step_web_3_edge.png";

import step_web_1_safari from "../../../img/step_web_1_safari.jpg";
import step_web_2_safari from "../../../img/step_web_2_safari.jpg";
import step_web_3_safari from "../../../img/step_web_3_safari.jpg";
import step_web_4_safari from "../../../img/step_web_4_safari.jpg";
import step_web_5_safari from "../../../img/step_web_5_safari.jpg";

import mobile_step_1_safari from "../../../img/mobile_step_1_safari.jpg";
import mobile_step_2_safari from "../../../img/mobile_step_2_safari.jpg";
import mobile_step_3_safari from "../../../img/mobile_step_3_safari.jpg";
import mobile_step_4_safari from "../../../img/mobile_step_4_safari.jpg";
import {validateString} from "../../../utils/StringValidator"

const style3 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};

const imagesMobileChrome = [
  {
    label: "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: mobile_step_1,
  },
  {
    label: "Paso 2.- Busca el menú de Permisos y presiona",
    imgPath: mobile_step_2,
  },
  {
    label: "Paso 3. Marca el campo de ubicación",
    imgPath: mobile_step_3,
  },
  {
    label: "Paso 4. Asegurate de que se muestre asi el campo de ubicación",
    imgPath: mobile_step_4,
  },
];
const imagesMobileSmg = [
  {
    label: "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: mobile_step_1_smg,
  },
  {
    label: "Paso 2.- Busca el menú de Permisos y presiona",
    imgPath: mobile_step_2_smg,
  },
  {
    label: "Paso 3. Marca el campo de ubicación",
    imgPath: mobile_step_3_smg,
  },
];
const  imagesMobileSafari = [
  {
    label: "Paso 1.- Ve a los ajustes de tu dispositivo.",
    imgPath: mobile_step_1_safari,
  },
  {
    label: "Paso 2.- Busca la aplicación de safari, y presiona.",
    imgPath: mobile_step_2_safari,
  },
  {
    label: "Paso 3. Elige el menú de ubicación.",
    imgPath: mobile_step_3_safari,
  },
  {
    label: "Paso 4. Presiona permitir. Vuelve a la pagina de percapita",
    imgPath: mobile_step_4_safari,
  },
];
const imagesWebChrome = [
  {
    label: "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1,
  },
  {
    label: "Paso 2.- Busca el menú de Ubicación y presiona el botón",
    imgPath: step_web_2,
  },
  {
    label: "Paso 3.- Asegurate que haz marcado el campo de ubicación",
    imgPath: step_web_3,
  },
];

const imagesWebFire = [
  {
    label: "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1_fire,
  },
  {
    label: 'Paso 2.- Ubica la opcíon que vendra marcada como "Acceder a tu ubicación" y presiona en la opción de Bloqueado temporalmente',
    imgPath: step_web_2_fire,
  },
  {
    label: "Paso 3.- Refresca la pagina presionando el boton como se muestra en la imagen.",
    imgPath: step_web_3_fire,
  },
  {
    label: 'Paso 4.- Nuavamente se mostrará la ventana donde se solicita el permiso para acceder a tu ubicación. Asegurate de presinar "Permitir"',
    imgPath: step_web_4_fire,
  },
];

const imagesWebEdge = [
  {
    label: "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1_edge,
  },
  {
    label: 'Paso 2.- Ubica el menú "Permisos para este sitio" y dentro de el ve a la opción de "Ubicación", te mostrará una lista de opciones, elige "Permitir"',
    imgPath: step_web_2_edge,
  },
  {
    label: 'Paso 3.- Asegurate que aparezca seleccionada la opcion de "Permitir"',
    imgPath: step_web_3_edge,
  },
];
const imagesWebSafari = [
  {
    label: 'Paso 1.- Haz click en el menú de inicio de tu equipo y ve a la opción de "Preferencias del sistema".',
    imgPath: step_web_1_safari,
  },
  {
    label: 'Paso 2. -Dirigete a la opción de "Seguridad y Privacidad".',
    imgPath: step_web_2_safari,
  },
  {
    label: 'Paso 3.- Haz click en el candado para que peudas editar los permisos.',
    imgPath: step_web_3_safari,
  },
  
  {
    label: 'Paso 4.- Asegurate que  el icono del candado aparezca como se muestra en la imagen. Ubica la opción de safari y deja seleccionada la casilla como se muesta en la imagen.',
    imgPath: step_web_4_safari,
  },
  
  {
    label: 'Paso 5.- Cuando ingreses al sitio te mostrara la opción para permitir la ubicación. Asegurate de seleccionar "OK"',
    imgPath: step_web_5_safari,
  },
];

const principalImageByBrowser = [
  {
    isMobile: true,
    browser: "chrome",
    img: intruction_mobile,
  },
  {
    isMobile: true,
    browser: "samsumg",
    img: intruction_mobile_smg,
  },
  {
    isMobile: true,
    browser: "safari",
    img: intruction_mobile_safari,
  },
  {
    isMobile: false,
    browser: "chrome",
    img: intruction_web,
  },
  {
    isMobile: false,
    browser: "firefox",
    img: intruction_web_fire,
  },
  {
    isMobile: false,
    browser: "edge",
    img: intruction_web_edge,
  },
  {
    isMobile: false,
    browser: "safari",
    img: intruction_web_safari,
  },
];

export default function Solicitud3Geolocation() {
  const query = useQuery();
  const history = useHistory();
  const apiClient = ApiClient.getInstance();
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [date, setDate] = useState(null);
  const [shown, setShown] = React.useState(false);
  const [phoneChange, setPhoneChange] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState("");
  const [sendCode, setSendCode] = React.useState({
    phone: "",
    send: false,
  });
  const [fromLogin, setFromLogin] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const { userMain, updateUser } = React.useContext(UserContext);
  const { ubicacion, setUbicacion } = React.useContext(DiremovilContext);

  const [phoneStatus, setPhoneStatus] = React.useState(true);
  const [minDate, setMinDate] = React.useState(null);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const [open2, setOpen2] = React.useState(false);
  const [ssnValues, setValue] = React.useState({
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
  });
  const [isSmartphone, setIsSmartphone] = useState(false);
  const [errorUbication, setErrorUbication] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [images, setImages] = useState(null);
  const [imageInstructionOne, setImageInstructionOne] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const StyledModal = styled(Modal)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledModal2 = styled(Modal)(({ theme }) => ({}));

  const StyledPaper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: isSmartphone ? theme.spacing(1, 1, 2) : theme.spacing(1, 10, 3),
    borderRadius: "8px",
    outline: "none",
    position: "absolute" as "absolute",
    top: isSmartphone ? "1%" : "10%",
    left: isSmartphone ? "1%" : "20%",
    right: isSmartphone ? "1%" : "20%",

    // transform: "translate(-50%, -50%)",
    // maxWidth: "800px",
  }));

  const StyledPaper2 = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: isSmartphone ? theme.spacing(1, 1, 2) : theme.spacing(1, 10, 3),
    borderRadius: "8px",
    outline: "none",
    position: "absolute" as "absolute",
    top: isSmartphone ? "1%" : "10%",
    left: isSmartphone ? "1%" : "20%",
    right: isSmartphone ? "1%" : "20%",

    // transform: "translate(-50%, -50%)",
    // maxWidth: "800px",
  }));

  // useEffect(() => {
  //     apiClient.
  //     getProspect(localStorage.getItem('prspect'))
  //     .then((res) => res.json())
  //     .then(async (data) => {
  //         if(!data.error){
  //             let fecha = new Date(data.dateOfBirth)
  //             console.log(fecha)
  //             console.log( leftPad(fecha.getMonth().toString(),2,'0')+ "/" + (leftPad(fecha.getDay().toString(),2,'0')) + "/" + fecha.getFullYear())
  //             formik.setFieldValue('firstName',data.firstName)
  //             formik.setFieldValue('secondName',data.secondName)
  //             formik.setFieldValue('lastFName',data.lastFName)
  //             formik.setFieldValue('lastMName',data.lastMName)
  //             formik.setFieldValue('dateOfBirth', fecha.getFullYear() + "-"+ leftPad(fecha.getMonth().toString(),2,'0')+ "-" + (leftPad(fecha.getDay().toString(),2,'0')) )
  //             formik.setFieldValue('state',data.state)
  //             formik.setFieldValue('gender',data.gender)
  //             formik.setFieldValue('email',data.email)
  //             formik.setFieldValue('phone',data.phone)
  //             //formik.setFieldValue('attached1',data.attached1)
  //             //formik.setFieldValue('attached2',data.attached2)
  //             setDate(leftPad(fecha.getMonth().toString(),2,'0')+ "/" + (leftPad(fecha.getDay().toString(),2,'0')) + "/" + fecha.getFullYear())
  //             setAmount(data.amount)
  //             setNewPhone(data.phone);
  //         }
  //     })
  // },[])

  function handleChangeDate(event: any) {
    console.log(event.target.value);
    let date = event.target.value.split("/");
    formik.setFieldValue(
      "dateOfBirth",
      date[2] + "-" + date[0] + "-" + date[1]
    );
  }
  function handleChangePhone(event: any) {
    formik.setFieldValue("phone", event.target.value.toString());
  }
  function handleChangePhoneBlur(event: any) {
    // if(sendCode.phone !== ""){ // se envío sms
    //     //sendSms
    //     if(sendCode.phone !== formik.values.phone){
    //         setPhoneChange(true)
    //     }
    // }
    if (!formik.errors.phone) {
      apiClient.validatePhone(formik.values.phone).then((data) => {
        if (data.error) {
          Swal.fire({
            title: "Telefono registrado",
            text: "Encontramos que tu teléfono ya ha sido registrado, te invitamos a iniciar sesión presionando el botón (Iniciar Sesión)",
            icon: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Salir",
            confirmButtonText: "Iniciar Sesión",
          }).then((result) => {
            if (result.isConfirmed) {
              return history.push("/login");
            } else {
              return  window.location.replace("https://percapita.mx");
            }
          });
          // setOpenMessage(true);
          // setMessage(
          //   "Lo sentimos, el telefono ingresado ya se encuentra registrado, si ya tienes una cuenta, inicia sesión"
          // );
          // setSeverity("error");
          // return;
        }
      });
    }
  }
  function changePhone(event: any) {
    setPhoneChange(false);
    //sendMessage sms
  }
  function handleClosePhone() {
    formik.setFieldValue("phone", sendCode.phone);
    setPhoneChange(false);
  }
  const handleBlur = (e) => console.log(e.target.value);

  function leftPad(str: string, len: number, ch = "0"): string {
    len = len - str.length + 1;
    return len > 0 ? new Array(len).join(ch) + str : str;
  }
  function handleChangeCheck(event: any) {
    formik.setFieldValue("attached1", !formik.values.attached1);
    const nextSibling = document.querySelector(
      "#attached2"
    ) as HTMLElement | null;
    nextSibling.focus();
  }
  function handleChangeCheck2(event: any) {
    formik.setFieldValue("attached2", !formik.values.attached2);
    const nextSibling = document.querySelector(
      "#attached3"
    ) as HTMLElement | null;
    nextSibling.focus();
  }
  function validateSendCode() {}

  function handleBlurCheck() {
    console.log(formik.values.attached1, formik.values.attached2);
    if (!formik.errors.attached1 && !formik.errors.attached2) {
    }
  }
  function handleClickCheck() {
    console.log(formik.values.attached1, formik.values.attached2);
  }
  function handleBlurPassword(event: any) {
    if (!formik.errors.confirmPassword) {
      apiClient
        .signup(formik.values.phone, formik.values.password,  "personal")
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          localStorage.setItem("cxdc", formik.values.phone);
          localStorage.setItem("fwcd", formik.values.password);
          if (data.error) {
            setIsLoading(false);
            //localStorage.clear();
            setOpenMessage(true);
            setMessage(
              data.status >= 500 ? "Error del servidor" : data.error.message
            );
            setSeverity("error");
            setLogin(false);

            return;
          }
          setLogin(true);
          setPhoneStatus(false);
          localStorage.setItem("userId", data.user);
        })
        .catch((err) => {
          setIsLoading(false);
          setOpenMessage(true);
          setMessage("Error en el servidor");
          setSeverity("error");
        });
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      phone: "",
      attached1: false,
      attached2: false,
      attached3: false,
      password: "",
      confirmPassword: "",
      val1: "",
      val2: "",
      val3: "",
      val4: "",
      val5: "",
      val6: "",
      userId: localStorage.getItem("prspect"),
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Tienes que ingresar la calle."),
      lastFName: Yup.string().required("Tienes que ingresar la calle."),
      lastMName: Yup.string().required("Tienes que ingresar la calle."),
      dateOfBirth: Yup.date()
        .required("Ingresa una fecha de nacimiento válida")
        .max(
          moment().subtract(21, "year"),
          "Lo sentimos, no cumples con la edad mínima para continuar con el proceso"
        ),
      //state: Yup.string().required("Tienes que ingresar la calle."),
      email: Yup.string().nullable(),
      phone: Yup.string()
        .required("Tienes que ingresar la calle.")
        .matches(
          /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/
        ),
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      attached2: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      password: Yup.string().required("Tienes que ingresar la calle."),
      confirmPassword: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords don't match."),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let code =
        formik.values.val1 +
        formik.values.val2 +
        formik.values.val3 +
        formik.values.val4 +
        formik.values.val5 +
        formik.values.val6;
      apiClient
        .activateAccountSMS(code)
        .then((data) => {
          setOpenMessage(true);
          setMessage("Tu cuenta ha sido activada");
          console.log(
            localStorage.getItem("cxdc"),
            localStorage.getItem("fwcd")
          );
          if (localStorage.getItem("cxdc") && localStorage.getItem("fwcd")) {
            console.log(data);
            apiClient
              .login(localStorage.getItem("cxdc"), localStorage.getItem("fwcd"))
              .then((res) => res.json())
              .then(async (a) => {
                localStorage.removeItem("cxdc");
                localStorage.removeItem("fwcd");
                if (a.error) {
                  localStorage.clear();
                  setOpenMessage(true);
                  const statusCode = a.error?.status || a.error.statusCode;
                  setMessage(
                    statusCode === 401
                      ? "Telefono o contraseña invalidos"
                      : a.error.message
                  );
                  setSeverity("error");

                  // if(statusCode === 403)
                  //   return history.push("/step2");
                  //   else return history.push("/login");
                  setIsLoading(false);
                }

                localStorage.setItem("TOKEN", a.token);
                localStorage.setItem("USER_ID", a.userId);

                apiClient.token = a.token;
                apiClient.userId = a.userId;
                if (a.fullName) {
                  updateUser({
                    ...userMain,
                    nombre: a.fullName || a.fullName,
                    telefono: a.phone,
                  });
                } else {
                  updateUser({
                    ...userMain,
                    telefono: a.phone,
                  });
                }
                try {
                  await apiClient.termsConditions({
                    publicidad: formik.values.attached3,
                  });
                } catch (error) {
                  setIsLoading(false);
                  setOpenMessage(true);
                  setIsLoading(false);
                  setMessage("Ha ocurrido un error al guardar los datos.");
                  setSeverity("error");
                  return;
                }
                const data = {
                  firstName: formik.values.firstName,
                  secondName: formik.values.secondName,
                  lastFName: formik.values.lastFName,
                  lastMName: formik.values.lastMName,
                  birthDate: new Date(formik.values.dateOfBirth).toISOString(), //formik.values.dateOfBirth.substr(0, 10), //
                  email: formik.values.email,
                  sex: formik.values.gender,
                };
                apiClient
                  .createUserDetails(data)
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.error) {
                      setOpenMessage(true);
                      setMessage(data.error.message);
                      setSeverity("error");
                      setIsLoading(false);
                    }
                    const fullName = `${formik.values.firstName} ${formik.values.secondName} ${formik.values.lastFName} ${formik.values.lastMName}`;
                    updateUser({
                      ...userMain,
                      nombre: fullName,
                    });
                    return history.push("/prestamo-personal/?from=init");
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    setOpenMessage(true);
                    setMessage(
                      "Favor de verificar que la informacion sea correcta."
                    );
                    setSeverity("error");
                    setIsLoading(false);
                  });
                if (localStorage.getItem("preCreditoLocal")) {
                  var credito = JSON.parse(
                    localStorage.getItem("preCreditoLocal")
                  );
                  var dd = {
                    processName: "register-finished",
                    userId: a.userId,
                  };
                  updatePreCredito({
                    ...preCredito,
                    stepMarketing: "register-finished",
                  });
                  updateMarketing(credito.idProcessMarketing, dd);
                }
              });
          }
          //return history.push("/login");
        })
        .catch((reason) => {
          if (reason.message === "Código inválido") {
            formik.setFieldValue("val1", "");
            formik.setFieldValue("val2", "");
            formik.setFieldValue("val3", "");
            formik.setFieldValue("val4", "");
            formik.setFieldValue("val5", "");
            formik.setFieldValue("val6", "");
          }
          console.log(reason);
          setIsLoading(false);
          setOpenMessage(true);
          setMessage(reason.message);
          setSeverity("error");
        });
    },
  });
  const handleClose = () => {
    setOpenMessage(false);
  };
  const handleClose2 = () => {};
  function handleChangeCode(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 6) {
        formik.handleSubmit();
      }
    }
    formik.setFieldValue([`val${fieldIndex}`].toString(), value);
  }
  function handleChangeCodePhone(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 16) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 16) {
        formik.handleSubmit();
      }
    }
    formik.setFieldValue([`val${fieldIndex - 10}`].toString(), value);
  }

  const checkFromRoute = async () => {
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    const from = query.get("from");
    if (from === "Login") {
      formik.setFieldValue("phone", localStorage.getItem("cxdc"));
      formik.setFieldValue("password", localStorage.getItem("fwcd"));
      formik.setFieldValue("confirmPassword", localStorage.getItem("fwcd"));
      setFromLogin(true);
      setPhoneStatus(false);
    }
  };
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    checkFromRoute();
    var a = moment().subtract(21, "year").format("DD/MM/YYYY");
    console.log("mínimo fecha permitido: ", a);
    setMinDate(a);
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "register-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "register-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "register-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  useEffect(() => {
    if (!ubicacion) {
      //alert("no tienes permisos de ubicacion")
    }
    console.log(ubicacion);
  }, []);
  const sendSms = async () => {
    if (fromLogin === true) {
      let phone = localStorage.getItem("cxdc");
      await apiClient
        .sendSmsCreditByPhone(phone)
        .then((res) => res.json())
        .then((data) => {
          setOpenMessage(true);
          setMessage("Código reenviado con éxito");
        })
        .catch((err) => {});
    } else {
      await apiClient
        .sendSmsCredit()
        .then((res) => res.json())
        .then((data) => {
          setOpenMessage(true);
          setMessage("Código reenviado con éxito");
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        permissionStatus.onchange = () => {
          console.log(
            `geolocation permission status has changed to ${permissionStatus.state}`
          );
          if (permissionStatus.state === "granted") {
            return setErrorUbication(false);
          } else {
            return setErrorUbication(true);
          }
        };
        console.log("obect position", permissionStatus);
        if (permissionStatus.state === "granted") {
        } else if (permissionStatus.state === "prompt") {
          console.log("Location permission prompt");
          return setOpen2(true);
          // You can show a UI element to request permission if needed
        } else if (permissionStatus.state === "denied") {
          console.log("Location permission denied");
          return setErrorUbication(true);
        }
      } catch (error) {
        console.error("Error checking location permission:", error);
      }
    };

    checkLocationPermission();
  }, []);

  useEffect(() => {
    const checkIsSmartphone = async () => {
      //alert(isSamsungBrowser);
      console.log(
        "isBrowser ->" + isBrowser,
        " isDesktop ->" + isDesktop,
        " isSmartphone ->" + isSmartphone,
        " isTablet ->" + isTablet,
        " isSmartTV ->" + isSmartTV,
        " isConsole ->" + isConsole,
        " isWearable ->" + isWearable,
        " isEmbedded ->" + isEmbedded,
        " isSmartphoneSafari ->" + isMobileSafari,
        " isChromium ->" + isChromium,
        " isSmartphoneOnly ->" + isMobileOnly,
        " isAndroid ->" + isAndroid,
        " isWinPhone ->" + isWinPhone,
        " isIOS ->" + isIOS,
        " isChrome ->" + isChrome,
        " isFirefox ->" + isFirefox,
        " isSafari ->" + isSafari,
        " isOpera ->" + isOpera,
        " isIE ->" + isIE,
        " isEdge ->" + isEdge,
        " isYandex ->" + isYandex,
        " isIOS13 ->" + isIOS13,
        " isIPad13 ->" + isIPad13,
        " isIPhone13 ->" + isIPhone13,
        " isIPod13 ->" + isIPod13,
        " isElectron ->" + isElectron,
        " isEdgeChromium ->" + isEdgeChromium,
        " isLegacyEdge ->" + isLegacyEdge,
        " isWindows ->" + isWindows,
        " isMacOs ->" + isMacOs,
        " isMIUI ->" + isMIUI,
        " isSamsungBrowser ->" + isSamsungBrowser
      );
      const screenWidth = window.innerWidth;
      setIsSmartphone(screenWidth <= 768); // Ajusta este valor según tus necesidades
      if (screenWidth <= 768) {
        const isSamsungBrowser2 = navigator.userAgent.match(/SamsungBrowser/i);
        if (isChrome) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileChrome);
          setMaxSteps(imagesMobileChrome.length);
        } else if (isSamsungBrowser2) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "samsumg" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSmg);
          setMaxSteps(imagesMobileSmg.length);
        } else if (isMobileSafari) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "safari" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSafari);
          setMaxSteps(imagesMobileSafari.length);
        } else {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSafari);
          setMaxSteps(imagesMobileSafari.length);
        }
      } else {
        if (isChrome) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebChrome);
          setMaxSteps(imagesWebChrome.length);
        } else if (isEdge) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "edge" && !obj.isMobile
          );
          console.log(index, principalImageByBrowser[index])
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebEdge);
          setMaxSteps(imagesWebEdge.length);
        } else if (isFirefox) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "firefox" && !obj.isMobile
          );
          console.log(index, principalImageByBrowser[index])
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebFire);
          setMaxSteps(imagesWebFire.length);
          return;
        } else if (isSafari) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "safari" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSafari);
          setMaxSteps(imagesMobileSafari.length);
          setMaxSteps(imagesWebChrome.length);
        } else {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebSafari);
          setMaxSteps(imagesWebSafari.length);
        }
      }
    };

    // Verificación inicial
    checkIsSmartphone();

    // Escucha de eventos para cambios en el tamaño de la ventana
    window.addEventListener("resize", checkIsSmartphone);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", checkIsSmartphone);
    };
  }, []);
  const processLocation = () => {
    console.log("clicked");
    setOpen2(false);
    /* se ejecuta si los permisos son concedidos y se encuentra una ubicación*/
    function onSucccess(position) {}

    /*se ejecuta si el permiso fue denegado o no se puede encontrar una ubicación*/
    function onError() {
      setErrorUbication(true);
      console.log("ocurrio un error o no hay permisos para ver la ubicación");
    }

    var config = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    };

    /*así llamamos la función getCurrentPosition*/
    navigator.geolocation.getCurrentPosition(onSucccess, onError, config);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      {/* <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose2}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-1">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <h4 style={{color:'white'}}>¡Hola! Para ofrecerte la mejor experiencia personalizada, Percapita necesita acceder a tu ubicación</h4>          
              <h4 style={{color:'white'}}>A continuación aparecerá una ventana solicitando tu permiso para obtener tu ubicacion, es necesario aceptarlo para poder continuar con tu proceso</h4>   
            </div>
          </div>
          
        </Grid>
      </Modal> */}
      <Modal
        open={open2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledPaper>
          <Typography variant="h5" sx={{ marginBottom: "1px" }}>
            <div className="d-flex justify-content-center mb-1">
              <img src={logo} alt="PRADO'S" width="100" className="img-fluid" />
            </div>
          </Typography>
          <Typography>
            ¡Hola! Para ofrecerte la mejor experiencia personalizada, Percapita
            necesita acceder a tu ubicación.
          </Typography>
          <Typography>
            A continuación aparecerá una ventana solicitando tu permiso para
            obtener tu ubicacion, es necesario aceptarlo para poder continuar
            con tu proceso.
          </Typography>
          {isSmartphone ? (
            <Typography variant="h5" sx={{ marginBottom: "12px" }}>
              <div className="d-flex justify-content-center mb-1">
                {imageInstructionOne && (
                  <img
                    src={imageInstructionOne}
                    alt="PRADO'S"
                    width="200"
                    className="img-fluid"
                  />
                )}
              </div>
            </Typography>
          ) : (
            <Typography variant="h5" sx={{ marginBottom: "16px" }}>
              <div className="d-flex justify-content-center mb-1">
              {imageInstructionOne && ( <img
                  src={imageInstructionOne}
                  alt="PRADO'S"
                  width="400"
                  className="img-fluid"
                />
                )}
              </div>
            </Typography>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => processLocation()}
            //onPress={() => processLocation()}

            tabIndex={1}
            sx={{
              marginTop: isSmartphone ? "5px" : "16px",
              left: isSmartphone ? "30%" : "45%",
            }}
          >
            Aceptar
          </Button>
          {/* <button 
            type="button" 
            className="btn btn-shadow"
            style={{
              marginTop: isSmartphone ? "5px" : "16px",
              left: isSmartphone ? "30%" : "45%",
            }}
            onClick={() => processLocation()}
            >
            Aceptar
          </button> */}
        </StyledPaper>
      </Modal>
      <Modal
        open={errorUbication}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledPaper2>
          <Typography variant="h5" sx={{ marginBottom: "1px" }}>
            <div className="d-flex justify-content-center mb-1">
              <img src={logo} alt="PRADO'S" width="100" className="img-fluid" />
            </div>
          </Typography>
          <Typography>
            ¡Hola! Para ofrecerte la mejor experiencia personalizada, Percapita
            necesita acceder a tu ubicación.
          </Typography>
          <Typography>
            A continuación te presentamos una serie de pasos que deberás seguir
            para habilitar la ubicación en tu navegador.
          </Typography>
          {images && (
            <Box
              sx={{
                maxWidth: !isSmartphone ? 700 : 400,
                flexGrow: 1,
                margin: "auto",
              }}
              justifyContent={"center"}
            >
              <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                  bgcolor: "background.default",
                }}
              >
                <Typography>{images[activeStep].label}</Typography>
              </Paper>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                //enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: !isSmartphone ? 420 : 200,
                          display: "block",
                          maxWidth: !isSmartphone ? 700 : 400,
                          overflow: "hidden",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </SwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Siguiente
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Anterior
                  </Button>
                }
              />
            </Box>
          )}
        </StyledPaper2>
      </Modal>
      <StyledModal2
        open={false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledPaper2>
          <Box sx={style3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
            {images && (
              <Box
                sx={{
                  maxWidth: !isSmartphone ? 700 : 400,
                  flexGrow: 1,
                  margin: "auto",
                }}
                justifyContent={"center"}
              >
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 50,
                    pl: 2,
                    bgcolor: "background.default",
                  }}
                >
                  <Typography>{images[activeStep].label}</Typography>
                </Paper>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  //enableMouseEvents
                >
                  {images.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          sx={{
                            height: !isSmartphone ? 420 : 200,
                            display: "block",
                            maxWidth: !isSmartphone ? 700 : 400,
                            overflow: "hidden",
                            width: "100%",
                          }}
                          src={step.imgPath}
                          alt={step.label}
                        />
                      ) : null}
                    </div>
                  ))}
                </SwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Siguiente
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Anterior
                    </Button>
                  }
                />
              </Box>
            )}
          </Box>
        </StyledPaper2>
      </StyledModal2>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <br />
      <br />
      <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Por favor, rellena los siguientes campos con tu información</p>
          </div>
        </div>
        <div className="col-3"></div>
      </div>

      {/* STEPS */}
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </div>
      {/* CARDS */}

      {!open2 && (
        <div className="d-none d-sm-none d-md-block d-flex justify-content-center mt-4 container">
          <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
            <div className="container">
              {fromLogin === false ? (
                <div className="row">
                  <div className="col-6 col-sm-6">
                    <h6 className="text-grey">
                      Antes de continuar, vamos a validar tu número de
                      teléfono00. <br />
                      <br /> <br />{" "}
                    </h6>
                  </div>
                  <div className="col-6 col-sm-6">
                    <label htmlFor="phone" className="form-label sol1-tittle">
                      Número de celular *
                    </label>
                    <input
                      type="number"
                      className={
                        formik.errors.phone
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="phone"
                      aria-describedby="emailHelp"
                      onChange={handleChangePhone}
                      value={formik.values.phone}
                      onBlur={handleChangePhoneBlur}
                      tabIndex={2}
                    />
                    {formik.errors.phone && (
                      <div className="invalid-feedback">
                        Ingresa un teléfono válido
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-sm-6">
                    <label
                      htmlFor="password"
                      className="form-label sol1-tittle"
                    >
                      Contraseña *
                    </label>
                    <input
                      type={shown ? "text" : "password"}
                      className={
                        formik.errors.password
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="password"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      autoComplete="false"
                      tabIndex={3}
                    />
                  </div>

                  <div className="col-6 col-sm-6">
                    <label
                      htmlFor="confirmPassword"
                      className="form-label sol1-tittle"
                    >
                      Confirma contraseña *
                    </label>
                    <input
                      type={shown ? "text" : "password"}
                      className={
                        formik.errors.confirmPassword
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="confirmPassword"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      onBlur={handleBlurPassword}
                      tabIndex={4}
                    />
                    {formik.errors.confirmPassword &&
                      formik.values.confirmPassword.length >=
                        formik.values.password.length &&
                      formik.values.password.length > 0 && (
                        <div className="invalid-feedback">
                          Las contraseñas no coinciden
                        </div>
                      )}
                    <a href="/login">
                      <span className="pt-0 rBlack">
                        ¿Ya tienes una cuenta?
                      </span>
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}

              <hr className="mt-4 mb-4" />
              <p className="txt-blue-strong">
                Rellena los campos vacíos con tus datos
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="firstName"
                        className="form-label sol1-tittle"
                      >
                        Primer Nombre *
                      </label>
                      <input
                        type="input"
                        id="firstName"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          const value = e.target.value || "";
                          formik.setFieldValue(
                            "firstName",
                            validateString(value.toUpperCase())
                          );
                        }}
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.firstName
                            ? " form-control is-invalid"
                            : "form-control"
                        }
                        disabled={phoneStatus}
                        tabIndex={5}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="lastFName"
                        className="form-label sol1-tittle"
                      >
                        Apellido paterno *
                      </label>
                      <input
                        type="input"
                        className={
                          formik.errors.lastFName
                            ? " form-control is-invalid"
                            : "form-control"
                        }
                        id="lastFName"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          const value = e.target.value || "";
                          formik.setFieldValue(
                            "lastFName",
                            validateString(value.toUpperCase())
                          );
                        }}
                        value={formik.values.lastFName}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                        tabIndex={7}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="secondName"
                        className="form-label sol1-tittle"
                      >
                        Segundo nombre *
                      </label>
                      <input
                        type="input"
                        className="form-control"
                        id="secondName"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          const value = e.target.value || "";
                          formik.setFieldValue(
                            "secondName",
                            validateString(value.toUpperCase())
                          );
                        }}
                        value={formik.values.secondName}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                        tabIndex={6}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="lastMName"
                        className="form-label sol1-tittle"
                      >
                        Apellido materno *
                      </label>
                      <input
                        type="input"
                        className={
                          formik.errors.lastMName
                            ? " form-control is-invalid"
                            : "form-control"
                        }
                        id="lastMName"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          const value = e.target.value || "";
                          formik.setFieldValue(
                            "lastMName",
                            validateString(value.toUpperCase())
                          );
                        }}
                        value={formik.values.lastMName}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                        tabIndex={8}
                      />
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="birthDate"
                        className="form-label sol1-tittle"
                      >
                        Fecha de nacimiento *
                      </label>
                      <input
                        type="date"
                        className={
                          formik.errors.dateOfBirth
                            ? " form-control is-invalid"
                            : "form-control"
                        }
                        id="dateOfBirth"
                        aria-describedby="emailHelp"
                        onChange={formik.handleChange}
                        value={formik.values.dateOfBirth}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                        tabIndex={9}
                      />
                      {formik.errors.dateOfBirth && (
                        <div className="invalid-feedback">
                          {formik.errors.dateOfBirth}
                        </div>
                      )}
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={formik.values.dateOfBirth}
                                                            onBlur={handleBlur}
                                                            disabled={phoneStatus}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider> */}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label sol1-tittle">
                        Correo electrónico *
                      </label>
                      <input
                        type="email"
                        className={
                          formik.errors.email
                            ? " form-control is-invalid"
                            : "form-control"
                        }
                        id="email"
                        aria-describedby="emailHelp"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="gender"
                        className="form-label sol1-tittle"
                      >
                        Género *
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className={
                            formik.errors.gender
                              ? " form-check-input is-invalid"
                              : "form-check-input"
                          }
                          type="radio"
                          name="gender"
                          id="gender"
                          value="femenino"
                          checked={formik.values.gender === "femenino"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={phoneStatus}
                        />
                        <label className="form-check-label" htmlFor="gender">
                          Mujer
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className={
                            formik.errors.gender
                              ? " form-check-input is-invalid"
                              : "form-check-input"
                          }
                          type="radio"
                          name="gender"
                          id="gender"
                          value="masculino"
                          checked={formik.values.gender === "masculino"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={phoneStatus}
                        />
                        <label className="form-check-label" htmlFor="gender">
                          Hombre
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="row">
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Número de celular *</label>
                                        <input 
                                            type="number" 
                                            className={formik.errors.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={ handleChangePhone}
                                            value={formik.values.phone}
                                            onBlur={handleChangePhoneBlur}
                                            />
                                            {formik.errors.phone && (
                                                <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                            )}
                                            
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.password ? "form-control  is-invalid" : "form-control"}
                                            id="password" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            autoComplete="false"
                                            />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                            id="confirmPassword" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            onBlur={handleBlurPassword}
                                            />
                                            {formik.errors.confirmPassword && formik.values.confirmPassword.length   >=  formik.values.password.length  && (formik.values.password.length > 0) && (
                                                <div className="invalid-feedback">
                                                Las contraseñas no coinciden
                                            </div>
                                            )}
                                    </div>
                                </div> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="container">
                      <h6 className="text-grey">
                        Antes de continuar, debemos validar tu número. <br />
                        Da click aquí una vez que hayas puesto tu celular.{" "}
                      </h6>
                      <div className="form-check">
                        <input
                          className={
                            formik.errors.attached1
                              ? "form-check-input checkbox-terms  is-invalid"
                              : "form-check-input checkbox-terms"
                          }
                          type="checkbox"
                          //value=""
                          id="attached1"
                          //checked={formik.values.attached1}
                          onChange={formik.handleChange}
                          disabled={
                            formik.errors.password ||
                            formik.errors.confirmPassword
                              ? true
                              : false
                          }
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className="form-check-label text-check"
                          htmlFor="attached1"
                        >
                          Acepto el Aviso de Privacidad
                        </label>
                        {formik.errors.attached1 &&
                          !formik.errors.confirmPassword && (
                            <div className="invalid-feedback">
                              Debes aceptar el aviso de privacidad
                            </div>
                          )}
                      </div>
                      <div className="form-check">
                        <input
                          className={
                            formik.errors.attached2
                              ? "form-check-input checkbox-terms  is-invalid"
                              : "form-check-input checkbox-terms"
                          }
                          type="checkbox"
                          //value=""
                          id="attached2"
                          //checked={formik.values.attached2}
                          onChange={formik.handleChange}
                          disabled={
                            formik.errors.password ||
                            formik.errors.confirmPassword
                              ? true
                              : false
                          }
                          onBlur={formik.handleBlur}
                        />

                        <label
                          className="form-check-label text-check"
                          htmlFor="attached2"
                        >
                          Acepto las obligaciones del Contrato de licencia
                        </label>
                        {formik.errors.attached2 &&
                          !formik.errors.confirmPassword && (
                            <div className="invalid-feedback">
                              Debes aceptar el contrato de licencia
                            </div>
                          )}
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input checkbox-terms"
                          type="checkbox"
                          value=""
                          id="attached3"
                          checked={formik.values.attached3}
                          onChange={formik.handleChange}
                          disabled={
                            formik.errors.password ||
                            formik.errors.confirmPassword
                              ? true
                              : false
                          }
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className="form-check-label text-check"
                          htmlFor="flexCheckDefault"
                        >
                          Acepto recibir noticias, promociones e información
                          acerca de mi cuenta
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <p>
                    Por favor, ingresa el código que te enviamos a tu celular:
                  </p>
                  <p className="sol1-tittle">Código</p>
                  {/* <p className="sol1-tittle">{Object.keys(formik.errors)}</p> */}
                </div>
                <div className="d-flex justify-content-center">
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number "
                      id="val-1"
                      aria-describedby="emailHelp"
                      name="val-1"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val1
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-2"
                      aria-describedby="emailHelp"
                      name="val-2"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val2
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-3"
                      aria-describedby="emailHelp"
                      name="val-3"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val3
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-4"
                      aria-describedby="emailHelp"
                      name="val-4"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val4
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-5"
                      aria-describedby="emailHelp"
                      name="val-5"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val5
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                  <div className="p-2">
                    <input
                      type="string"
                      className="form-control input-number"
                      id="val-6"
                      aria-describedby="emailHelp"
                      name="val-6"
                      onChange={handleChangeCode}
                      maxLength={1}
                      value={
                        Object.keys(formik.errors).length > 0
                          ? ""
                          : formik.values.val6
                      }
                      disabled={
                        Object.keys(formik.errors).length > 0 ? true : false
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <a href="#" className="link-secondary link-tittle">
                      Reenviar el código
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5"></div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="d-block d-sm-block d-md-none p-0 m-0 d-sm-block d-md-none p-0 m-0 ">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            {fromLogin === false ? (
              <div className="row">
                <div className="col-12 col-sm-12">
                  <h6 className="text-grey">
                    Antess de continuar, vamos a validar tu número de teléfono.{" "}
                    <br />
                    <br /> <br />{" "}
                  </h6>
                </div>
                <div className="col-12 col-sm-12">
                  <label htmlFor="phone" className="form-label sol1-tittle">
                    Número de celular *
                  </label>
                  <input
                    type="number"
                    className={
                      formik.errors.phone
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="phone"
                    aria-describedby="emailHelp"
                    onChange={handleChangePhone}
                    value={formik.values.phone}
                    onBlur={handleChangePhoneBlur}
                    tabIndex={1}
                  />
                  {formik.errors.phone && (
                    <div className="invalid-feedback">
                      Ingresa un teléfono válido
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-12">
                  <label htmlFor="password" className="form-label sol1-tittle">
                    Contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      formik.errors.password
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="password"
                    aria-describedby="emailHelp"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    autoComplete="false"
                    tabIndex={2}
                  />
                </div>

                <div className="col-12 col-sm-12">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label sol1-tittle"
                  >
                    Confirma contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      formik.errors.confirmPassword
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="confirmPassword"
                    aria-describedby="emailHelp"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    onBlur={handleBlurPassword}
                    tabIndex={3}
                  />
                  {formik.errors.confirmPassword &&
                    formik.values.confirmPassword.length >=
                      formik.values.password.length &&
                    formik.values.password.length > 0 && (
                      <div className="invalid-feedback">
                        Las contraseñas no coinciden
                      </div>
                    )}
                  <a href="/login">
                    <span className="pt-0 rBlack">¿Ya tienes una cuenta?</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="mt-4 mb-4" />
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Primer Nombre *
                    </label>
                    <input
                      type="input"
                      id="firstName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "firstName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.firstName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled={phoneStatus}
                      tabIndex={4}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="secondName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "secondName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.secondName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                      tabIndex={5}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno *
                    </label>
                    <input
                      type="input"
                      className={
                        formik.errors.lastFName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="lastFName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "lastFName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.lastFName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                      tabIndex={6}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno *
                    </label>
                    <input
                      type="input"
                      className={
                        formik.errors.lastMName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="lastMName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "lastMName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.lastMName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                      tabIndex={7}
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="birthDate"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    <input
                      type="date"
                      className={
                        formik.errors.dateOfBirth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="dateOfBirth"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.dateOfBirth}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                      tabIndex={8}
                    />
                    {formik.errors.dateOfBirth && (
                      <div className="invalid-feedback">
                        {formik.errors.dateOfBirth}
                      </div>
                    )}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={formik.values.dateOfBirth}
                                                            onBlur={handleBlur}
                                                            disabled={phoneStatus}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider> */}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico *
                    </label>
                    <input
                      type="email"
                      className={
                        formik.errors.email
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="email"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                      tabIndex={9}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label sol1-tittle">
                      Género *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          formik.errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="femenino"
                        checked={formik.values.gender === "femenino"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          formik.errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="masculino"
                        checked={formik.values.gender === "masculino"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Hombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Número de celular *</label>
                                        <input 
                                            type="number" 
                                            className={formik.errors.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={ handleChangePhone}
                                            value={formik.values.phone}
                                            onBlur={handleChangePhoneBlur}
                                            />
                                            {formik.errors.phone && (
                                                <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                            )}
                                            
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.password ? "form-control  is-invalid" : "form-control"}
                                            id="password" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            autoComplete="false"
                                            />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                            id="confirmPassword" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            onBlur={handleBlurPassword}
                                            />
                                            {formik.errors.confirmPassword && formik.values.confirmPassword.length   >=  formik.values.password.length  && (formik.values.password.length > 0) && (
                                                <div className="invalid-feedback">
                                                Las contraseñas no coinciden
                                            </div>
                                            )}
                                    </div>
                                </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="container">
                    <h6 className="text-grey">
                      Antes de continuar, debemos validar tu número. <br />
                      Da click aquí una vez que hayas puesto tu celular.{" "}
                    </h6>
                    <div className="form-check">
                      <input
                        className={
                          formik.errors.attached1
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached1"
                        //checked={formik.values.attached1}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="attached1"
                      >
                        Acepto el Aviso de Privacidad
                      </label>
                      {formik.errors.attached1 &&
                        !formik.errors.confirmPassword && (
                          <div className="invalid-feedback">
                            Debes aceptar el aviso de privacidad
                          </div>
                        )}
                    </div>
                    <div className="form-check">
                      <input
                        className={
                          formik.errors.attached2
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached2"
                        //checked={formik.values.attached2}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />

                      <label
                        className="form-check-label text-check"
                        htmlFor="attached2"
                      >
                        Acepto las obligaciones del Contrato de licencia
                      </label>
                      {formik.errors.attached2 &&
                        !formik.errors.confirmPassword && (
                          <div className="invalid-feedback">
                            Debes aceptar el contrato de licencia
                          </div>
                        )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input checkbox-terms"
                        type="checkbox"
                        value=""
                        id="attached3"
                        checked={formik.values.attached3}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="flexCheckDefault"
                      >
                        Acepto recibir noticias, promociones e información
                        acerca de mi cuenta
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <p>
                  Por favor, ingresa el código que te enviamos a tu celular:
                </p>
                <p className="sol1-tittle">Código</p>
                {/* <p className="sol1-tittle">{Object.keys(formik.errors)}</p> */}
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number "
                    id="val-11"
                    aria-describedby="emailHelp"
                    name="val-11"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val1
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-12"
                    aria-describedby="emailHelp"
                    name="val-12"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val2
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-13"
                    aria-describedby="emailHelp"
                    name="val-13"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val3
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-14"
                    aria-describedby="emailHelp"
                    name="val-14"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val4
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-15"
                    aria-describedby="emailHelp"
                    name="val-15"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val5
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-16"
                    aria-describedby="emailHelp"
                    name="val-16"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val6
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center">
                  <a
                    href="#"
                    onClick={sendSms}
                    className="link-secondary link-tittle"
                  >
                    Reenviar el código
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5"></div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        open={phoneChange}
        //onClose={handleClosePhone}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Seguro que deseas cambiar el número de teléfono?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Al cambiar el número de teléfono, te enviaremos un nuevo sms con un
            código para validar tu identidad
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhone}>Cancelar</Button>
          <Button onClick={changePhone} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </motion.div>
  );
}
