const setStepPersonalDiremovilCredit = (step) => {
  switch (step) {
    case "PERSONAL-INFORMATION":
      return "ADDRESS";

    case "ADDRESS":
      return "CONFIG-CREDIT";

    case "CONFIG-CREDIT":
      return "LABOR-DATA";

    case "LABOR-DATA":
      return "PERSONAL-REFERENCES";

    // case "PERSONAL-REFERENCES":
    //   return "QUESTION-PEPS"

    case "PERSONAL-REFERENCES":
      return "FINISHED";

    default:
      return "";
  }
};

export default setStepPersonalDiremovilCredit;
