import React, { useEffect } from "react";
import checked from "../../../../../assets/circle-checked.png";
import { Field, Formik, Form, useFormik, ErrorMessage } from "formik";
import ApiClient, { PabsPayment } from "../../../../services/apiclient";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import logo_balnco from '../../../../img/logo_blanco.png';
import fondo_1 from '../../../../img/fondo_1.png';
import fondo_2 from '../../../../img/fondo_2.png';
import paso_1 from '../../../../img/paso_1.png';
import paso_2 from '../../../../img/paso_2.png';
import logo_pabs from '../../../../img/logo_pabs.png';
import whats from '../../../../img/whats.png';
import cel from '../../../../img/cel.png';
import {motion} from "framer-motion";

function AceptadoMessage({ userData}) {

  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  const [options, setOptions] = useState<PabsPayment[]>([]);
  const [cantidades, setCantidades] = useState<PabsPayment[]>([]);
  const [amount,setAmount] =useState(0)
  const [upper, setUpper] = useState(0);
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    setAmount(credit.amount)
    console.log(credit.amount);
    return activeCredit;
  };
  useEffect(() => {
    checkActiveCredit();
  },[])

    // Redirect to /Veriff if user is not verified
    // useEffect(() => {
    //   const checkUserVeriffValidated = async () => {
      
    //     const isValidatedRes = await apiClient.checkUserVeriffValidated();
    //     if(isValidatedRes.veriffValidated !== true) {
    //         history.push('/veriff?from=PABS');
    //         return;
    //     }
    
    //   };
    //   const timeout = setTimeout(() => {
    //     checkUserVeriffValidated();
    //   }, 500);
  
    //   return () => {
    //     clearTimeout(timeout);
    //   }
    // }, []);


  //};



  return (
    <motion.div
    initial={{  opacity:0 }}
      animate={{opacity:1 }}
      exit={{opacity:0}}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    className="blue-background-mess">
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="row">
                    <div className="col-3">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top-sol8" width="250" />
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                            <h1 className="mt-1 text-tittle">¡Muchas gracias!</h1>
                            <h4 className="mt-2">Estamos analizando tu solicitud de crédito.</h4>
                            <h5 className="mt-5">Muy pronto recibirás un mensaje SMS a tu celular con la <br />
                                viabilidad de tu crédito por:</h5>
                            <h1 className="mt-2 text-blue-light">{amount.toLocaleString("en-US", {
                                                                                        style: "currency",
                                                                                        currency: "USD",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    })}</h1>
                            <h5 className="mt-5">Comunícate con nosotros si tienes alguna duda:</h5>
                        </div>
                        <div className="row mt-5">
                            <div className="col text-center text-white">
                                <button className="btn btn-sm text-white btn-border">
                                    <img src={cel} alt="PRADO'S" className="img-fluid mrirht2" width="15" />
                                    33 3616-8059
                                </button>
                            </div>
                            <div className="col text-center">
                                <button className="btn btn-sm text-white btn-border">
                                    <img src={whats} alt="PRADO'S" className="img-fluid mrirht2" width="20" />
                                    
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-4">
                            <a href='/dashboard' className="btn btn-continue-large mt-5">
                                Ir a mi cuenta
                            </a>
                        </div>

                    </div>
                    <div className="col-3">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2-sol8" width="260" />
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mb-1">
                    <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                </div>
                <div className="text-white text-center container">
                    <h1 className="mt-1 text-tittle">¡Muchas gracias!</h1>
                    <h4 className="mt-2">Estamos analizando tu solicitud de crédito.</h4>
                    <h5 className="mt-5">Muy pronto recibirás un mensaje SMS a tu celular con la <br />
                        viabilidad de tu crédito por:</h5>
                    <h1 className="mt-2 text-blue-light">$8,000</h1>
                    <h5 className="mt-5">Comunícate con nosotros si tienes alguna duda:</h5>
                    <div className="d-flex justify-content-center mt-2 mb-4">
                        <a href='/dashboard' className="btn btn-continue mt-5 mb-5">
                            Ir a mi cuenta
                        </a>
                    </div>
                </div>
            </div>
        </motion.div>
  );
}

export default AceptadoMessage;
